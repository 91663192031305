import React, { useEffect, useRef, useState } from 'react'
import styled                                 from '@emotion/native'
import { T, useTranslator }                   from '../translations/translate'
import { useProfile }                         from '../Auth/useProfile'
import { useCampaign }                        from '../GlobalContexts'

import { colors }              from '@civitime/library/storybook/configs/colors'
import { useScreenDimensions } from '@civitime/library/storybook/hooks/useScreenDimensions'
import { DefaultModal }        from '@civitime/library/storybook/stories/Modals'
import { BoldText }            from '@civitime/library/storybook/stories/Texts'
import { AvatarComponent }     from '@civitime/library/storybook/stories/Avatar'
import { ColorsSelection }     from '@civitime/library/storybook/stories/SelectionBar'
import { ActionButton }        from '@civitime/library/storybook/stories/Button'

export const AvatarHomeSelection = ({ openHelper }) => {
  const root = useRef(null)
  const { t } = useTranslator()
  const { campaignNavigate, campaignSettings } = useCampaign()
  const { emitCommand, publicProfile } = useProfile()
  const { mobile, orientation } = useScreenDimensions(root)
  const dateNow = new Date(Date.now()).toISOString()
  const [avatarSelection, setAvatarSelection] = useState({
    type: null,
    color: colors.white,
  })
  const defaultAvatar = ['man', 'woman']
  const canSubmit =
    avatarSelection.type !== null &&
    avatarSelection.color !== null &&
    avatarSelection.color !== colors.white
  
  const updateAvatar = () => {
    canSubmit &&
    emitCommand({
      type: 'UpdateAvatar',
      payload: { avatar: avatarSelection },
    }).then(
      (value) => {
        value?.[0]?.type === 'AvatarUpdated' && campaignNavigate('')
        if (campaignSettings?.endAt < dateNow) {
          openHelper()
        }
      }
    )
  }
  
  useEffect(() => {
    if (!publicProfile || !publicProfile.avatar) return
    setAvatarSelection({
      type: publicProfile.avatar.type,
      color: publicProfile.avatar.color,
    })
  }, [publicProfile])
  
  return publicProfile ? (
    <DefaultModal
      title={t('app.profile.modalTitle')}
      onPress={!publicProfile.avatar ? false : () => campaignNavigate('')}
    >
      <WrapperAvatarHomeSelectionContent ref={root}>
        <TextAvatar>
          <T path="app.profile.avatarChoice"/>
        </TextAvatar>
        <WrapperChooseYourDefaultAvatar>
          {defaultAvatar.map((avatar, index) => {
            const isActive = avatarSelection?.type === avatar
            return (
              <WrapperAvatar
                key={index + 'default avatars'}
                {...{ index, mobile, orientation }}
              >
                <AvatarComponent
                  avatar={{ color: avatarSelection?.color, type: avatar }}
                  circleAvatarConfig={{
                    size: 135,
                    withBorder: false,
                    backgroundColor: colors.avatarBackground,
                  }}
                  onPress={() => {
                    setAvatarSelection((selection) => ({
                      ...selection,
                      type: avatar,
                    }))
                  }}
                  isActiveOverride={isActive}
                />
                {isActive ? <ActiveBar/> : null}
              </WrapperAvatar>
            )
          })}
        </WrapperChooseYourDefaultAvatar>
        <WrapperColorPalette>
          <ColorsSelection
            size={40}
            ballsColors={[
              colors.skin.peachPuff,
              colors.skin.rose,
              colors.skin.cherokee,
              colors.skin.brownBramble,
            ]}
            activeColor={
              publicProfile && publicProfile.avatar
                ? publicProfile.avatar.color
                : null
            }
            onPress={(color) => {
              setAvatarSelection((selection) => ({
                ...selection,
                color: color,
              }))
            }}
          />
        </WrapperColorPalette>
        <WrapperValidateButton>
          <ActionButton disabled={!canSubmit} onPress={updateAvatar}>
            <T path="app.profile.validate"/>
          </ActionButton>
        </WrapperValidateButton>
      </WrapperAvatarHomeSelectionContent>
    </DefaultModal>
  ) : null
}

const WrapperAvatarHomeSelectionContent = styled.View({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  minHeight: 350,
})

const TextAvatar = styled(BoldText)({
  fontSize: 15,
  paddingLeft: 15,
  paddingRight: 15,
})

const WrapperChooseYourDefaultAvatar = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const WrapperColorPalette = styled.View({
  display: 'flex',
  alignItems: 'center',
})

const WrapperValidateButton = styled.View({
  alignItems: 'center',
})

const WrapperAvatar = styled.View(({ index, mobile, orientation }) =>
  index === 0 && {
    marginRight: mobile && orientation === 'portrait' ? 10 : 40,
  }
)

const ActiveBar = styled.View({
  position: 'absolute',
  bottom: -15,
  left: 0,
  width: '100%',
  height: 3,
  borderRadius: 3 / 2,
  backgroundColor: colors.secondary,
})
