import React, { useRef }        from 'react'
import { Text }                 from 'react-native'
import styled                   from '@emotion/native'
import { brand as isMobileApp } from 'expo-device'
import { T, useTranslator }     from '../../translations/translate'
import { useCampaign }          from '../../GlobalContexts'

import { useScreenDimensions }            from '@civitime/library/storybook/hooks/useScreenDimensions'
import { asRelative }                     from '@civitime/library/storybook/configs/sizes'
import { Title2 }                         from '@civitime/library/storybook/stories/Texts'
import { LangSelectorButton, TextButton } from '@civitime/library/storybook/stories/Button'
import { AuthCard, AuthFooter }           from '@civitime/library/storybook/stories/Cards'

export const Auth = ({ openLangSelector, campaignSettings }) => {
  const root = useRef(null)
  const { orientation, mobile, width } = useScreenDimensions(root)
  const { campaignNavigate } = useCampaign()
  const isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  const { lang } = useTranslator()
  return (
    <WrapperAuth>
      <AuthCard mobile={mobile}>
        <Logo
          source={require('../../../assets/logo.png')}
          resizeMode="contain"
          {...{ width, mobile, orientation }}
        />
        {campaignSettings?.internationalized && <AuthLangSelectorButton size={40}
                                 iconSize={30}
                                 onPress={openLangSelector}
                                 text={lang}/>}
        <ActionsWrapper ref={root} {...{ mobile, orientation }}>
          <TextButton onPress={() => campaignNavigate('signup')}>
            <T path={'app.auth.links.startSignup'}/>
          </TextButton>
          <TextButton onPress={() => campaignNavigate('login')}>
            <T path={'app.auth.links.startSignin'}/>
          </TextButton>
        </ActionsWrapper>
      </AuthCard>
      {!isMobileApp && !isChrome && !mobile && (
        <WrapperWarningNav>
          <TitleWarn>
            <T path={'app.auth.warningNav'}/>
          </TitleWarn>
          <Text
            accessibilityRole="link"
            href="https://www.google.com/chrome/"
            target="_blank"
          >
            <GoogleChromeImage
              source={require('../../../assets/Auth/chrome.png')}
              resizeMode="contain"
            />
          </Text>
          <Text
            accessibilityRole="link"
            href="https://www.mozilla.org/fr/firefox/"
            target="_blank"
          >
            <FirefoxImage
              source={require('../../../assets/Auth/firefox-logo.png')}
              resizeMode="contain"
            />
          </Text>
        </WrapperWarningNav>
      )}
    </WrapperAuth>
  )
}

export const TitleWarn = styled(Title2)({
  fontSize: 'unset'
})

export const FirefoxImage = styled.Image({
  width: 80,
  height: 22,
  marginTop: 10,
  marginLeft: 5
})

export const GoogleChromeImage = styled.Image({
  width: 80,
  height: 22,
  marginTop: 10,
  marginLeft: 5
})

const WrapperAuth = styled.View({
  flex: 1,
  alignItems: 'center',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const Logo = styled.Image(({ mobile, width, orientation }) => ({
  width: mobile && orientation === 'portrait' ? width * 0.5 : asRelative(235),
  height: mobile && orientation === 'portrait' ? width * 0.5 : asRelative(235),
  resizeMode: 'contain',
  alignSelf: 'center',
}))

const ActionsWrapper = styled(AuthFooter)(({ orientation, mobile }) => ({
  flexDirection: mobile && orientation === 'portrait' ? 'column' : 'row',
  justifyContent: 'space-around',
}))

export const WrapperWarningNav = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  left: '50%',
  transform: [{ translateX: '-50%' }],
  bottom: 20
})

export const AuthLangSelectorButton = styled(LangSelectorButton)({
  width: 'auto',
  margin: 'auto',
  position: 'fixed',
  right: 10,
  top: 10,
  zIndex: 100
})