import {firebaseAccessor} from '../../../firebase'
import {clientId} from '../../../../clientId'
import React, {useEffect, useState} from 'react'
import {App as Memory} from '@civitime/memory/App'
import {MasterMind} from '@civitime/mastermind'
import styled from '@emotion/native'
import {useCampaign} from '../../../GlobalContexts'
import {useProfile} from '../../../Auth/useProfile'
import {DuelQuiz} from '@civitime/duel-quiz/App'
import {useTranslator} from '../../../translations/translate'

export const Modules = ({
                          moduleClicked, playerPosition, enterModule, setModuleClicked, quitModule, paths,
                          opponentProfile, setResult, lostModule
                        }) => {
  const [moduleResult, setModuleResult] = useState()
  const [mastermindTranslations, setMastermindTranslations] = useState(null)
  const [gameContent, setGameContent] = useState(null)
  const {campaignId, campaignSettings, campaignNavigate} = useCampaign()
  const {emitCommand, publicProfile} = useProfile()
  const {userSession} = useProfile()
  const [moduleMounted, setModuleMounted] = useState(true)
  const {content, lang} = useTranslator()
  useEffect(() => {
    if (!moduleClicked && !paths[5]) {
        setModuleClicked(null)
        return campaignNavigate('')
    }
    getModuleInfo(moduleClicked)
  }, [moduleClicked])
  
  useEffect(() => {
    if (!paths[5] || opponentProfile || !userSession) return
    const currentChallenge = userSession?.challenges?.[paths[5]]
    const currentChallengeStatus = currentChallenge?.status
    const moduleLevel = paths[4]
    const moduleName = paths[3]
    const moduleInfo = {
      name: moduleName,
      level: moduleLevel,
      module: moduleName,
      opponentResult: currentChallenge?.opponentResult
    }
    if (moduleLevel && moduleName && currentChallengeStatus === 'started') {
      setModuleClicked(moduleInfo)
      enterModule()
    }
  }, [paths.join(''), userSession])
  
  const getModuleInfo = async (moduleInfo) => {
    if (!moduleInfo) return
    if (moduleInfo.module === 'mastermind') {
      setMastermindTranslations(campaignSettings.internationalizedContent ? await getMastermindTranslations(lang)
        : await getMastermindTranslations('fr'))
    }
    const moduleContent = await firebaseAccessor
      .get(`clients/${clientId}/campaigns/${campaignId}/content/${moduleInfo?.module}/levels/${moduleInfo.level
        ? moduleInfo.level
        : 'lv-1'}`)
    if (!moduleContent) return quitModule()
    if (moduleInfo.module === 'memory') {
      const memory = moduleContent.memory ? moduleContent.memory : moduleContent
      await Promise.all(memory.map(async item => {
        return ({
          ...item,
          image: await fetch(
            `https://ct-campaigns.civitimeapp.com/${campaignId}/content/memory/${moduleInfo?.level}/${item.image}
            `)
            .then(res => res.blob())
            .then(blob => (URL.createObjectURL(blob)))
            .catch(err => console.error(err))
        })
      }))
        .then(v => {
          setGameContent(v)
        })

    } else {
      if (moduleInfo.module.includes('quiz')) {
        const random = Math.floor(Math.random() * Math.floor(moduleContent.length - 2))
        const quizData = moduleInfo?.module?.includes('quiz') ?
          moduleContent?.slice(random, random + 3, moduleContent.length)
          : null
        setGameContent(quizData)
      } else {
        setGameContent(moduleContent)
      }
    }
    if (paths?.length > 0) {
      return enterModule()
    }
  }

  const endModule = async () => {
    if (!moduleResult.win) {
      await emitCommand({
        type: 'UpdateModuleStateUserSession',
        payload: {
          module: moduleClicked?.module,
          level: moduleResult?.level || 'lv-1',
          win: !!moduleResult?.win,
        }
      })
      if (paths?.[5]?.length < 30){
        lostModule()
      }
      campaignNavigate('')
      return quitModule()
    }
    const destination = campaignSettings?.map?.trips?.[moduleClicked.name]?.[`${playerPosition.line}-${playerPosition.tile}`].end
      || null
    let response
    if (moduleClicked && opponentProfile) {
      const challengedPlayer = paths[5]
      const r = await emitCommand({
        type: 'ChallengePlayer',
        payload: {
          playerChallenged: challengedPlayer,
          level: paths[4],
          moduleType: paths[3]
        },
      })
      const challengeId = r[0].payload.challengeId
      response = await emitCommand({
        type: 'UpdateModuleStateUserSession',
        payload: {
          module: moduleClicked?.module,
          level: moduleResult?.level || 'lv-1',
          tilePosition: playerPosition,
          win: !!moduleResult?.win,
          attemptsLeft: moduleResult?.heartsLeft,
          destination: destination,
          challengeId: challengeId,
          result: moduleResult?.goodAnswers || moduleResult?.heartsLeft || 0,
          challenged: false,
          answers: moduleResult?.answers,
          timeStampStart: moduleResult?.timeStampStart,
          timeStampEnd: moduleResult?.timeStampEnd,
          picturesTimeStamps: moduleResult?.picturesTimeStamps,
          infosModalsTimeStamps: moduleResult?.infosModalsTimeStamps
        }
      })
    } else {
      response = await emitCommand({
        type: 'UpdateModuleStateUserSession',
        payload: {
          module: moduleClicked?.module,
          level: moduleResult?.level || 'lv-1',
          tilePosition: playerPosition,
          win: !!moduleResult?.win,
          attemptsLeft: moduleResult?.heartsLeft,
          destination: destination,
          challengeId: paths[5],
          challenged: paths[5],
          answers: moduleResult?.answers,
          result: moduleResult?.goodAnswers || moduleResult?.heartsLeft || 0,
          timeStampStart: moduleResult?.timeStampStart,
          timeStampEnd: moduleResult?.timeStampEnd,
          picturesTimeStamps: moduleResult?.picturesTimeStamps,
          infosModalsTimeStamps: moduleResult?.infosModalsTimeStamps
        }
      })
    }
    const updatedPoints = response.filter(r => r.type === 'UserPointsUpdated')
    if (updatedPoints) {
      setResult((result) => ({
        ...result,
        result: updatedPoints,
        moduleClicked: moduleClicked,
        won: moduleResult,
        displayPnj: (moduleClicked && opponentProfile) ? true : !paths[5]
      }))
    }
    setModuleResult(null)
    campaignNavigate('')
    quitModule()
  }

  useEffect(() => {
    if (!moduleResult) return
    endModule()
  }, [moduleResult])

  useEffect(() => {
    if(!moduleMounted) return
    emitCommand({
      type: "LaunchModule",
      payload: {
        module: {...moduleClicked},
      }
    })
  }, [moduleMounted])

  return <ModuleContainer>
    {moduleMounted && getModule({...moduleClicked},
      (data) => setModuleResult(data),
      () => quitModule(),
      gameContent,
      publicProfile,
      opponentProfile,
      () => setModuleMounted(false),
      moduleClicked,
      content,
      mastermindTranslations,
      lang)}
  </ModuleContainer>
}

const getModule = (moduleInfo,
                   setResult,
                   closeModule,
                   gameContent,
                   publicProfile,
                   opponentProfile,
                   unmountModule,
                   moduleClicked,
                   appTranslations, mastermindTranslations,
                   lang) => {
  if (!gameContent) return null
  const level = moduleInfo?.level?.split('-')[1] || 1
  const random = Math.floor(Math.random() * Math.floor(gameContent.length - 2))
  const quizData = moduleInfo?.module?.includes('quiz') ? gameContent?.slice(random, random + 3, gameContent.length) : null
  const contentTranslations = appTranslations?.[moduleInfo?.module]?.[moduleInfo?.level || 'lv-1'] || null
  switch (moduleInfo?.module) {
    case 'memory':
      return <Memory level={level}
                     data={gameContent}
                     contentTranslations={contentTranslations?.memory ?? null}
                     onClose={(result) => {
                       unmountModule()
                       setResult(() => ({...result, level: moduleInfo?.level || 1}))

                     }}
                     lang={lang}/>
    case 'mastermind':
      return <MasterMind data={gameContent}
                         translations={mastermindTranslations}
                         onEnd={(result) => {
                           setResult({...result, level: moduleInfo?.level || 1})
                           closeModule()
                         }}
                         onQuit={() => closeModule()}
                         width={'100%'}
                         onClose={(result) => {
                           setResult({...result, level: moduleInfo?.level || 1})
                         }}
                         contentTranslations={contentTranslations ?? null}
      />
    case 'quizHarbor':
      return <DuelQuiz data={quizData}
                       onClose={(result) => {
                         setResult({
                           ...result,
                           level: moduleInfo?.level || 1
                         })
                       }}
                       contentTranslations={contentTranslations ?? null}
                       opponentProfile={opponentProfile}
                       playerProfile={publicProfile}
                       opponentResult={moduleClicked?.opponentResult}
                       lang={lang}
      />
    case 'quizTunnel':
      return <DuelQuiz data={quizData}
                       onClose={(result) => {
                         setResult({
                           ...result,
                           level: moduleInfo?.level || 1
                         })
                       }}
                       contentTranslations={contentTranslations ?? null}
                       opponentProfile={opponentProfile}
                       playerProfile={publicProfile}
                       opponentResult={moduleClicked?.opponentResult}
                       lang={lang}
      />
  }
}

const ModuleContainer = styled.View({
  zIndex: 5,
  position: 'absolute',
  top: 0, left: 0,
  width: '100%',
  height: '100%',
})

const getMastermindTranslations = async (lang) => {
  return await fetch(`https://ct-campaigns.civitimeapp.com/texts/modules/mastermind/${lang}.json?date=${new Date}`)
    .then(r => {
      if (r.status === 404) {
        return null
      }
      return r.json()
    })
}
