export default {
  "v":
    "5.5.8", "fr":
    25, "ip":
    0, "op":
    80, "w":
    600, "h":
    450, "nm":
    "passage-anim", "ddd":
    0, "assets":
    [{"id": "image_0", "w": 13, "h": 18, "u": "", "p": require('./images/img_0.png'), "e": 0}, {
      "id": "image_1",
      "w": 14,
      "h": 18,
      "u": "",
      "p": require('./images/img_1.png'),
      "e": 0
    }, {"id": "image_2", "w": 13, "h": 18, "u": "", "p": require('./images/img_2.png'), "e": 0}, {
      "id": "image_3",
      "w": 14,
      "h": 18,
      "u": "",
      "p": require('./images/img_3.png'),
      "e": 0
    }, {"id": "image_4", "w": 21, "h": 152, "u": "", "p": require('./images/img_4.png'), "e": 0}, {
      "id": "image_5",
      "w": 21,
      "h": 152,
      "u": "",
      "p": require('./images/img_5.png'),
      "e": 0
    }, {"id": "image_6", "w": 21, "h": 152, "u": "", "p": require('./images/img_6.png'), "e": 0}, {
      "id": "image_7",
      "w": 21,
      "h": 152,
      "u": "",
      "p": require('./images/img_7.png'),
      "e": 0
    }, {"id": "image_8", "w": 80, "h": 80, "u": "", "p": require('./images/img_8.png'), "e": 0}, {
      "id": "image_9",
      "w": 28,
      "h": 45,
      "u": "",
      "p": require('./images/img_9.png'),
      "e": 0
    }, {"id": "image_10", "w": 32, "h": 18, "u": "", "p": require('./images/img_10.png'), "e": 0}, {
      "id": "image_11",
      "w": 80,
      "h": 80,
      "u": "",
      "p": require('./images/img_11.png'),
      "e": 0
    }, {"id": "image_12", "w": 28, "h": 45, "u": "", "p": require('./images/img_12.png'), "e": 0}, {
      "id": "image_13",
      "w": 32,
      "h": 19,
      "u": "",
      "p": require('./images/img_13.png'),
      "e": 0
    }, {"id": "image_14", "w": 80, "h": 80, "u": "", "p": require('./images/img_14.png'), "e": 0}, {
      "id": "image_15",
      "w": 28,
      "h": 45,
      "u": "",
      "p": require('./images/img_15.png'),
      "e": 0
    }, {"id": "image_16", "w": 32, "h": 19, "u": "", "p": require('./images/img_16.png'), "e": 0}, {
      "id": "comp_0",
      "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 0,
        "nm": "Groupe 1 copie 2",
        "refId": "comp_1",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 100, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [300, 225, 0], "ix": 2},
          "a": {"a": 0, "k": [300, 225, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "w": 600,
        "h": 450,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 0
      }, {
        "ddd": 0,
        "ind": 2,
        "ty": 0,
        "nm": "Groupe 1 copie",
        "refId": "comp_2",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 100, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [300, 225, 0], "ix": 2},
          "a": {"a": 0, "k": [300, 225, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "w": 600,
        "h": 450,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 0
      }, {
        "ddd": 0,
        "ind": 3,
        "ty": 0,
        "nm": "Groupe 1",
        "refId": "comp_3",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 100, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [300, 225, 0], "ix": 2},
          "a": {"a": 0, "k": [300, 225, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "w": 600,
        "h": 450,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 0
      }]
    }, {
      "id": "comp_1", "layers": [{
        "ddd": 0, "ind": 1, "ty": 2, "nm": "Calque 527", "refId": "image_8", "sr": 1, "ks": {
          "o": {
            "a": 1,
            "k": [{
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 0,
              "s": [50]
            }, {
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 20,
              "s": [85]
            }, {
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 40,
              "s": [50]
            }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 60, "s": [85]}, {
              "t": 80,
              "s": [50]
            }],
            "ix": 11
          },
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [281, 312, 0], "ix": 2},
          "a": {"a": 0, "k": [40, 40, 0], "ix": 1},
          "s": {
            "a": 1,
            "k": [{
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 0,
              "s": [66, 66, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 20,
              "s": [100, 100, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 40,
              "s": [66, 66, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 60,
              "s": [100, 100, 100]
            }, {"t": 80, "s": [66, 66, 100]}],
            "ix": 6
          }
        }, "ao": 0, "ip": 0, "op": 80, "st": 0, "bm": 8
      }, {
        "ddd": 0,
        "ind": 2,
        "ty": 2,
        "nm": "Calque 528",
        "refId": "image_9",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 100, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [279, 305.5, 0], "ix": 2},
          "a": {"a": 0, "k": [14, 22.5, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 0
      }, {
        "ddd": 0,
        "ind": 3,
        "ty": 2,
        "nm": "Calque 528 copie",
        "refId": "image_10",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 52.157, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [279, 329, 0], "ix": 2},
          "a": {"a": 0, "k": [16, 9, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 1
      }]
    }, {
      "id": "comp_2", "layers": [{
        "ddd": 0, "ind": 1, "ty": 2, "nm": "Calque 527", "refId": "image_11", "sr": 1, "ks": {
          "o": {
            "a": 1,
            "k": [{
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 10,
              "s": [50]
            }, {
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 25,
              "s": [100]
            }, {
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 40,
              "s": [50]
            }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 55, "s": [100]}, {
              "t": 70,
              "s": [50]
            }],
            "ix": 11
          },
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [418, 215, 0], "ix": 2},
          "a": {"a": 0, "k": [40, 40, 0], "ix": 1},
          "s": {
            "a": 1,
            "k": [{
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 10,
              "s": [66, 66, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 25,
              "s": [100, 100, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 40,
              "s": [66, 66, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 55,
              "s": [100, 100, 100]
            }, {"t": 70, "s": [66, 66, 100]}],
            "ix": 6
          }
        }, "ao": 0, "ip": 0, "op": 80, "st": 0, "bm": 8
      }, {
        "ddd": 0,
        "ind": 2,
        "ty": 2,
        "nm": "Calque 528",
        "refId": "image_12",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 100, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [416, 208.5, 0], "ix": 2},
          "a": {"a": 0, "k": [14, 22.5, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 0
      }, {
        "ddd": 0,
        "ind": 3,
        "ty": 2,
        "nm": "Calque 528 copie",
        "refId": "image_13",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 52.157, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [416, 232.5, 0], "ix": 2},
          "a": {"a": 0, "k": [16, 9.5, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 1
      }]
    }, {
      "id": "comp_3", "layers": [{
        "ddd": 0, "ind": 1, "ty": 2, "nm": "Calque 527", "refId": "image_14", "sr": 1, "ks": {
          "o": {
            "a": 1,
            "k": [{
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 0,
              "s": [58]
            }, {
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 20,
              "s": [100]
            }, {
              "i": {"x": [0.833], "y": [0.833]},
              "o": {"x": [0.167], "y": [0.167]},
              "t": 40,
              "s": [58]
            }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 60, "s": [100]}, {
              "t": 79,
              "s": [58]
            }],
            "ix": 11
          },
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [188, 215, 0], "ix": 2},
          "a": {"a": 0, "k": [40, 40, 0], "ix": 1},
          "s": {
            "a": 1,
            "k": [{
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 0,
              "s": [66, 66, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 20,
              "s": [100, 100, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 40,
              "s": [66, 66, 100]
            }, {
              "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
              "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
              "t": 60,
              "s": [100, 100, 100]
            }, {"t": 79, "s": [66, 66, 100]}],
            "ix": 6
          }
        }, "ao": 0, "ip": 0, "op": 80, "st": 0, "bm": 8
      }, {
        "ddd": 0,
        "ind": 2,
        "ty": 2,
        "nm": "Calque 528",
        "refId": "image_15",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 100, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [186, 208.5, 0], "ix": 2},
          "a": {"a": 0, "k": [14, 22.5, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 0
      }, {
        "ddd": 0,
        "ind": 3,
        "ty": 2,
        "nm": "Calque 528 copie",
        "refId": "image_16",
        "sr": 1,
        "ks": {
          "o": {"a": 0, "k": 52.157, "ix": 11},
          "r": {"a": 0, "k": 0, "ix": 10},
          "p": {"a": 0, "k": [186, 232.5, 0], "ix": 2},
          "a": {"a": 0, "k": [16, 9.5, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
        },
        "ao": 0,
        "ip": 0,
        "op": 80,
        "st": 0,
        "bm": 1
      }]
    }], "layers":
    [{
      "ddd": 0, "ind": 1, "ty": 2, "nm": "Calque 548 copie 3", "refId": "image_0", "sr": 1, "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 5,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 15,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 25,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 35,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 40,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 50,
            "s": [100]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 60, "s": [100]}, {
            "t": 70,
            "s": [0]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 5,
            "s": [333.5, 249.16, 0],
            "to": [0, -26.86, 0],
            "ti": [0, 38.304, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 25,
            "s": [333.5, 88, 0],
            "to": [0, -38.304, 0],
            "ti": [0, -26.86, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 35,
            "s": [333.5, 19.337, 0],
            "to": [0, 26.86, 0],
            "ti": [0, -11.444, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 40,
            "s": [333.5, 249.16, 0],
            "to": [0, 11.444, 0],
            "ti": [0, 38.304, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 60,
            "s": [333.5, 88, 0],
            "to": [0, -38.304, 0],
            "ti": [0, 11.444, 0]
          }, {"t": 70, "s": [333.5, 19.337, 0]}],
          "ix": 2
        },
        "a": {"a": 0, "k": [6.5, 9, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      }, "ao": 0, "ip": 0, "op": 80, "st": 0, "bm": 0
    }, {
      "ddd": 0, "ind": 2, "ty": 2, "nm": "Calque 548 copie 2", "refId": "image_1", "sr": 1, "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 15,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 25,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 35,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 45,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 50,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 60,
            "s": [100]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 70, "s": [100]}, {
            "t": 80,
            "s": [0]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 15,
            "s": [364, 277, 0],
            "to": [0, -31.5, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 45,
            "s": [364, 88, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 50,
            "s": [364, 277, 0],
            "to": [0, 0, 0],
            "ti": [0, 31.5, 0]
          }, {"t": 80, "s": [364, 88, 0]}],
          "ix": 2
        },
        "a": {"a": 0, "k": [7, 9, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      }, "ao": 0, "ip": 0, "op": 80, "st": 0, "bm": 0
    }, {
      "ddd": 0, "ind": 3, "ty": 2, "nm": "Calque 548 copie", "refId": "image_2", "sr": 1, "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 0,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 15,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 25,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 35,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 45,
            "s": [0]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 71, "s": [100]}, {
            "t": 79,
            "s": [0]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 0,
            "s": [229.5, 281, 0],
            "to": [0, -41, 0],
            "ti": [0, 41, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 30,
            "s": [229.5, 35, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 35,
            "s": [229.5, 35, 0],
            "to": [0, 41, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 45,
            "s": [229.5, 281, 0],
            "to": [0, 0, 0],
            "ti": [0, 41, 0]
          }, {"t": 75, "s": [229.5, 35, 0]}],
          "ix": 2
        },
        "a": {"a": 0, "k": [6.5, 9, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      }, "ao": 0, "ip": 0, "op": 80, "st": 0, "bm": 0
    }, {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "Calque 548",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 35,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 40,
            "s": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 45,
            "s": [0]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 70, "s": [100]}, {
            "t": 79,
            "s": [0]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 10,
            "s": [269, 249, 0],
            "to": [0, -34.667, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 40,
            "s": [269, 41, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 45,
            "s": [269, 249, 0],
            "to": [0, 0, 0],
            "ti": [0, 34.667, 0]
          }, {"t": 75, "s": [269, 41, 0]}],
          "ix": 2
        },
        "a": {"a": 0, "k": [0, 9, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 80,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "Calque 542 copie",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 0,
            "s": [40]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 20,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 40,
            "s": [40]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 60, "s": [100]}, {
            "t": 79,
            "s": [0]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [381.5, 178, 0], "ix": 2},
        "a": {"a": 0, "k": [10.5, 76, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 80,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "Calque 542 copie 3",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 0,
            "s": [100]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 40, "s": [40]}, {
            "t": 80,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [279.5, 155, 0], "ix": 2},
        "a": {"a": 0, "k": [10.5, 76, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 80,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "Calque 542 copie 2",
      "refId": "image_6",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 0,
            "s": [100]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 15,
            "s": [40]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 30,
            "s": [100]
          }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 45, "s": [0]}, {
            "t": 60,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [223.5, 200, 0], "ix": 2},
        "a": {"a": 0, "k": [10.5, 76, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 80,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 8,
      "ty": 2,
      "nm": "Calque 542",
      "refId": "image_7",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [339.5, 241, 0], "ix": 2},
        "a": {"a": 0, "k": [10.5, 76, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 80,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 11,
      "ty": 0,
      "nm": "feu",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [300, 225, 0], "ix": 2},
        "a": {"a": 0, "k": [300, 225, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "w": 600,
      "h": 450,
      "ip": 0,
      "op": 80,
      "st": 0,
      "bm": 0
    }], "markers":
    []
}
