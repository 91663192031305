import React, {useEffect, useRef, useState} from 'react'
import styled from '@emotion/native'
import {colors} from '@civitime/library/storybook/configs/colors'
import {DefaultModal} from '@civitime/library/storybook/stories/Modals'
import {MOBILE_HEADER_SIZE} from '@civitime/library/storybook/configs/sizes'
import {MediumText, Title1, Title2} from '@civitime/library/storybook/stories/Texts'
import {AvatarComponent} from '@civitime/library/storybook/stories/Avatar'
import {ChallengeIcon, StarIcon, StarRoundIcon} from '@civitime/library/storybook/stories/Icons'
import {useProfile} from '../../../../../Auth/useProfile'
import {useDataLoading} from '../../../../../Utils/dataClient'
import {clientId} from '../../../../../../clientId'
import {PlayerWrapper, ScoreWrapper, WrapperMembersList} from '../../Challenges/components/ChallengesDisplay'
import LottieView from 'lottie-react-native'
import {T, useTranslator} from '../../../../../translations/translate'
import {canEndChallenge} from "@civitime/game-server/lib/Domain/Phases/phase1/Challenges/Challenges.helpers"
import {useCampaign} from "../../../../../GlobalContexts"

const ResultAnim = require('../Animations/victoryAnimation.js')

export const ModuleResults = ({displayModulesResult, close, mobile, orientation, height, ending}) => {
  const {t} = useTranslator()
  return <ModuleResultModal
    crossSize={18}
    onPress={() => close(true)}
    backgroundColor={colors.lightBlue}
    color={colors.text}
    closeButtonColor={colors.secondary}
    title={t('modules.challenges.endModal.modalTitle')}
    maxHeight={
      mobile && orientation === 'landscape'
        ? height * 0.9
        : (height - MOBILE_HEADER_SIZE) * 0.99
    }
    withShadow>
    <ModuleResultsContent displayModulesResult={displayModulesResult} ending={ending}/>
  </ModuleResultModal>
}

export const ModuleResultsContent = ({displayModulesResult, ending, mobile, orientation}) => {
  const [won, setWon] = useState('')
  const {userSession} = useProfile()
  const {campaignSettings} = useCampaign()
  const challengeEvent = displayModulesResult?.result?.filter(v => v?.payload?.origin === 'ChallengePoints')?.[0] || null
  const moduleEvent = displayModulesResult?.result?.filter(v => v?.payload?.origin === 'ModulesPoints')?.[0] || null
  const challengeId = moduleEvent?.payload?.challengeId || challengeEvent?.payload?.challengeId || null
  const modulePoints = moduleEvent?.payload?.points
  const moduleType = moduleEvent?.payload?.moduleType || challengeEvent?.payload?.moduleType
  const challenge = userSession?.challenges?.[challengeId]
  const setWonResult = () => {
    const opponentResult = challenge?.opponentUid.includes('bot-') ? 2 : challenge?.opponentResult
    const forfait = canEndChallenge(challenge?.timeStamp, campaignSettings?.challengeEndDate)
    const result = challenge?.result
    if (opponentResult === null || opponentResult === undefined) {
      return setWon(forfait ? "win" : 'awaiting')
    }else if (result > opponentResult) {
      return setWon('win')
    }else if (result === opponentResult) {
      return setWon('equality')
    }else if (result < opponentResult) {
      return setWon('loose')
    }
  }
  const resultAnimRef = useRef(null)
  const challengePoints = challengeEvent?.payload?.points || modulePoints || 0

  useEffect(() => {
    if (!Object.entries(challenge || {}).length) return
    setWonResult()
  }, [challenge])

  return <>
    <Wrapper mobile={mobile}>
      {challengeId && <>
        {won === 'win' && <Title2><T path="modules.challenges.endModal.title.victory"/></Title2>}
        {won === 'equality' && <Title2><T path="modules.challenges.endModal.title.equality"/></Title2>}
        {won === 'awaiting' && <Title2><T path="modules.challenges.endModal.title.awaiting"/></Title2>}
        {won === 'loose' && <Title2><T path="modules.challenges.endModal.title.lost"/></Title2>}
        <ChallengeResultDisplay moduleEvent={moduleEvent}
                                challenge={challenge}
                                mobile={mobile}
                                orientation={orientation}/>
      </>}
      {modulePoints > 0 && !ending && <ModulePoints modulePoints={modulePoints} module={moduleType} mobile={mobile}/>}
      {(challengeId || ending) && <VictoryPoints won={won} win={challengePoints} mobile={mobile}/>}
    </Wrapper>
    <ResultAnimView source={ResultAnim?.default} autoPlay={true} ref={resultAnimRef} autoSize loop/>
  </>
}

const ChallengeResultDisplay = ({challenge, mobile}) => {
  const {publicProfile} = useProfile()
  const moduleResult = challenge?.result || 0
  const opponentProfile = useDataLoading(`clients/${clientId}/profiles/${challenge?.opponentUid}`)
  const opponentResult = challenge?.opponentUid.includes('bot-') ? 2 : challenge?.opponentResult
  return <WrapperMembersList>
    <PlayerWrapper mobile={mobile}>
      <AvatarComponent avatar={publicProfile?.avatar} circleAvatarConfig={{
        size: 60,
        withBorder: true,
        backgroundColor: colors.extraLightBackground,
      }}/>
      <PlayerName>{publicProfile?.name}</PlayerName>
    </PlayerWrapper>
    <ScoreWrapper>
      <Score>{(moduleResult && moduleResult !== 0) ? moduleResult : 0}</Score>
      <ChallengeIcon height={33} width={15} color={colors.violet}/>
      <Score>{(opponentResult || opponentResult === 0) ? opponentResult : '0'}</Score>
    </ScoreWrapper>
    <PlayerWrapper mobile={mobile} reverse>
      <PlayerName>{opponentProfile?.data?.name}</PlayerName>
      <AvatarComponent avatar={opponentProfile?.data?.avatar} circleAvatarConfig={{
        size: 60,
        withBorder: true,
        backgroundColor: colors.extraLightBackground,
      }}/>
    </PlayerWrapper>
  </WrapperMembersList>
}

const ModulePoints = ({modulePoints, module}) => {
  const text = (module, modulePoints) => {
    switch (module) {
      case 'quizHarbor':
        return <T data={{0: modulePoints}} path='modules.challenges.endModal.gain.quiz'/>
      case 'quizTunnel':
        return <T data={{0: modulePoints}} path='modules.challenges.endModal.gain.quiz'/>
      case 'mastermind':
        return <T data={{0: modulePoints}} path='modules.challenges.endModal.gain.mastermind'/>
      case 'memory':
        return <T data={{0: modulePoints}} path='modules.challenges.endModal.gain.memory'/>
      default:
        return <T data={{0: modulePoints}} path='modules.challenges.endModal.gain.default'/>
    }
  }
  return <ModulePointsWrapper>
    <ModulePointsText>{text(module, modulePoints)} <StarIcon color={colors.white} width={25} height={25}/>
    </ModulePointsText>
  </ModulePointsWrapper>
}

const getVictoryPointsTitle = (won, win) => {
  switch (won) {
    case 'win':
      return <T data={{0: win ? win : 100}} path='modules.challenges.endModal.victoryPoints.victory'/>
    case 'equality':
      return <T data={{0: win ? win : 50}} path='modules.challenges.endModal.victoryPoints.equality'/>
    case 'loose':
      return <T path='modules.challenges.endModal.victoryPoints.lost'/>
    default:
      return null
  }
}

const VictoryPoints = ({won, win}) => {
  if (!won.length) return null
  const victoryTitle = getVictoryPointsTitle(won, win)
  return <VictoryPointsWrapper>
    <VictoryPointsText>
      {victoryTitle} {victoryTitle && (win !== 0 && won !== 'awaiting') &&
    <StarRoundIcon height={30} width={30} color={colors.white} wrapperColor={colors.text}/>}
    </VictoryPointsText>
  </VictoryPointsWrapper>
}

const ModuleResultModal = styled(DefaultModal)({
  zIndex: 15,
  top: '20%',
  width: 'fit-content',
  alignSelf: 'center'
})

const Wrapper = styled.View(({mobile}) => ({
  padding: 20,
  paddingLeft: mobile && 0,
  paddingRight: mobile && 0,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}))

const PlayerName = styled(MediumText)({
  textTransform: 'uppercase',
  margin: 10
})

const Score = styled(Title1)({
  margin: 10
})

const ModulePointsText = styled(Title2)({
  color: colors.white,
  display: 'flex',
  alignItems: 'center'
})

const VictoryPointsText = styled(Title2)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center'
})

const ModulePointsWrapper = styled.View(({mobile}) => ({
  backgroundColor: colors.text,
  paddingLeft: 35,
  paddingRight: 35,
  paddingTop: 5,
  paddingBottom: 5,
  borderRadius: 17.5,
  marginBottom: 20,
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: mobile && '80%'
}))

const ResultAnimView = styled(LottieView)({
  position: 'absolute',
  zIndex: 10,
  width: '100%',
  height: '100%',
  left: 0,
})

const VictoryPointsWrapper = styled.View(({mobile}) => ({
  maxWidth: mobile && '80%',
}))
