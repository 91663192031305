import React, { useRef }              from 'react'
import styled                         from '@emotion/native'
import { colors }                     from '@civitime/library/storybook/configs/colors'
import { MediumText, Title1, Title2 } from '@civitime/library/storybook/stories/Texts'
import { Dimensions }                 from 'react-native'
import { useScreenDimensions }        from '@civitime/library/storybook/hooks/useScreenDimensions'
import { ModulesModal }               from '@civitime/library/storybook/stories/Modals'
import { FragmentButton }             from '@civitime/library/storybook/stories/Button'
import { FragmentCounter }            from '@civitime/library/storybook/stories/Blazon'
import { T, useTranslator }           from '../../../../../translations/translate'
import format                         from 'date-fns/format'
import parseISO                       from 'date-fns/parseISO'

const pnj = {
  stele: require('@civitime/library/assets/modulesAssets/pnj-stele.png'),
  portal: require('@civitime/library/assets/modulesAssets/pnj-portal.png'),
  hoot: require('@civitime/library/assets/modulesAssets/pnj-hoot.png'),
  harbor: require('@civitime/library/assets/modulesAssets/pnj-harbor.png'),
}

export const ModalModules = ({module, closeModal, actionButton, challenge, children, title, enterModule, result = false, ...props}) => {
  const root = useRef(null)
  const screenWidth = Math.round(Dimensions.get('window').width)
  const screenHeight = Math.round(Dimensions.get('window').height)
  const {mobileTablet, orientation, mobile} = useScreenDimensions(root)

  return <WrapperModalModules ref={root}>
    <PNJ source={pnj[module?.name]} alt='pnj' height={mobileTablet ? orientation === 'portrait' ? screenHeight * .4
      : screenWidth * .4
      : screenHeight * .6}/>
    <ModalModule
      padding={mobileTablet ? 20 : 50}
      paddingTop={screenHeight / 5}
      pnj={true}
      right={mobileTablet ?
        (screenHeight - ((screenWidth * .8) * .7)) / 4
        : screenWidth > 2000 ? (screenWidth - ((screenHeight * .8) * .7)) / 2 : (screenWidth - ((screenHeight * .8) * .7)) / 3}
      width={(screenWidth / 3) * 2}
      mobileTablet={mobileTablet}
      mobile={mobile}
      onPress={closeModal}
      result={result}
      actionButton={actionButton}
      {...props}>
      <ModalTitle>{title}</ModalTitle>
      {children}
    </ModalModule>
  </WrapperModalModules>
}

export const FragmentModal = ({close, fragments, onClick}) => {
  const root = useRef(null)
  const {mobileTablet} = useScreenDimensions(root)
  const {t} = useTranslator()
  return <WrapperFragmentModules ref={root}>
    <ModalFragment noButton color={colors.secondary} padding={mobileTablet ? 20 : 50}>
      <FragmentTitle>
        <T path="phase_1.modules.fragmentsModal.title"/>
      </FragmentTitle>
      {
        fragments < 6 ?
          <>
            <FragmentText>
              {
                fragments === 1 ? <T path="phase_1.modules.fragmentsModal.text" data={{0: fragments}}/>
                : <T path="phase_1.modules.fragmentsModal.texts" data={{0: fragments}}/>
              }

            </FragmentText>
            <CounterWrapper>
              <FragmentCounter fragments={fragments}/>
            </CounterWrapper>
            <FragmentButton text={t('phase_1.modules.fragmentsModal.close')} style={{margin: 'auto', marginTop: 20}}
                            onPress={close}/>
          </>
          : <>
            <FragmentText>
              <T path="phase_1.modules.fragmentsModal.fragmentsCompleted"/>
            </FragmentText>
            <CounterWrapper>
              <FragmentCounter fragments={fragments}/>
            </CounterWrapper>
            <FragmentButton text={t('phase_1.modules.fragmentsModal.discoverFinalDestination')}
                            style={{margin: 'auto', marginTop: 20}}
                            onPress={() => {
                              onClick()
                              close()
                            }}/>
          </>
      }
    </ModalFragment>
  </WrapperFragmentModules>
}

export const ModalFinalDestinationDiscovered = ({close, actionButton, campaignEndedDate, campaignSettings}) => {
  const root = useRef(null)
  const {mobileTablet} = useScreenDimensions(root)
  const {t} = useTranslator()
  return <WrapperFragmentModules ref={root}>
    <ModalFragment noButton onPress={close} color={colors.secondary} padding={mobileTablet ? 20 : 50}>
      <FragmentTitle>
        <T path="phase_1.modules.fragmentsModal.finalDestinationDiscoveredTitle"/>
      </FragmentTitle>
      <FragmentText>
        <T path="phase_1.modules.fragmentsModal.finalDestinationDiscoveredText"
           data={{
             date: campaignEndedDate ? format(parseISO(campaignEndedDate), 'dd/MM') : null,
             0: campaignSettings?.endPoints

           }}/>
      </FragmentText>
      <FragmentButton text={t('phase_1.modules.fragmentsModal.finalDestinationDiscoveredTitleClose')}
                      style={{margin: 'auto', marginTop: 20}}
                      onPress={actionButton}/>
    </ModalFragment>
  </WrapperFragmentModules>
}

export const InfoFinalDestination = ({close}) => {
  const root = useRef(null)
  const {mobileTablet} = useScreenDimensions(root)

  return <WrapperFragmentModules ref={root}>
    <ModalFragment noButton onPress={close} color={colors.secondary} padding={mobileTablet ? 20 : 50}>
      <InfoFinalDestinationTitle>
        <T path="phase_1.modules.fragmentsModal.infoFinalDestination"/>
      </InfoFinalDestinationTitle>
    </ModalFragment>
  </WrapperFragmentModules>
}

export const FragmentTitle = styled(Title1)({
  textTransform: 'uppercase',
  marginBottom: 10
})

const InfoFinalDestinationTitle = styled(FragmentTitle)({
  marginTop: 40
})

const FragmentText = styled(MediumText)({
  marginBottom: 10
})

const ModalFragment = styled(ModulesModal)({
  top: '20%',
})

const ModalModule = styled(ModulesModal)({},
  ({paddingTop, right, mobileTablet}) => ({
    position: 'absolute',
    right: mobileTablet ? 10 : right,
    left: mobileTablet ? 10 : 'unset',
    paddingTop: mobileTablet ? 10 : paddingTop,
    alignItems: 'center',
    maxWidth: mobileTablet && '100%'
  }))

export const WrapperModalModules = styled.View({
  zIndex: 10,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const WrapperFragmentModules = styled(WrapperModalModules)({
  backgroundColor: colors.test
})

const PNJ = styled.Image({
  position: 'absolute',
  width: '70%',
  height: '100%',
  bottom: 0,
  left: 0,
  zIndex: 1,
}, ({height}) => ({
  width: height * .7,
  height: height
}))

export const ModalTitle = styled(Title1)({
  textTransform: 'uppercase',
  marginBottom: 15,
})

export const LostModuleTitle = styled(Title2)({
  marginBottom: 10
})

export const WrapperModalContent = styled.View(({mobile}) => ({
  marginTop: mobile ? 15 : 30,
}))

const CounterWrapper = styled.View({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10,
  height: 100
})

