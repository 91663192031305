export default {
  "v": "5.5.8",
  "fr": 25,
  "ip": 0,
  "op": 50,
  "w": 800,
  "h": 800,
  "nm": "pa 2",
  "ddd": 0,
  "assets": [{"id": "image_0", "w": 120, "h": 120, "u": "", "p": require('./images/img_0.png'), "e": 0}, {
    "id": "image_1",
    "w": 120,
    "h": 120,
    "u": "",
    "p": require('./images/img_1.png'),
    "e": 0
  }],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 2,
    "nm": "-2pa.png",
    "cl": "png",
    "refId": "image_0",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [{"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 27, "s": [100]}, {
          "t": 31,
          "s": [0]
        }],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0.667, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 20,
          "s": [400, 525.726, 0],
          "to": [-0.042, -5.393, 0],
          "ti": [0.014, 1.318, 0]
        }, {
          "i": {"x": 0.667, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 23,
          "s": [399.75, 493.367, 0],
          "to": [-2.454, -237.653, 0],
          "ti": [-90.855, 49.41, 0]
        }, {"t": 31, "s": [658.407, 69.973, 0]}],
        "ix": 2
      },
      "a": {"a": 0, "k": [60, 60, 0], "ix": 1},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 20,
          "s": [90, 90, 100]
        }, {
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 23,
          "s": [100, 100, 100]
        }, {"t": 31, "s": [96, 96, 100]}],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 15,
    "op": 50,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 2,
    "ty": 2,
    "nm": "-1pa.png",
    "cl": "png",
    "refId": "image_1",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 5,
          "s": [0]
        }, {
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 10,
          "s": [100]
        }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 14, "s": [100]}, {
          "t": 15,
          "s": [0]
        }],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0.667, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 5,
          "s": [400, 739, 0],
          "to": [0, -38.791, 0],
          "ti": [0, 35.546, 0]
        }, {"t": 15, "s": [400, 506.257, 0]}],
        "ix": 2
      },
      "a": {"a": 0, "k": [60, 60, 0], "ix": 1},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 5,
          "s": [1, 1, 100]
        }, {"t": 15, "s": [100, 100, 100]}],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 5,
    "op": 55,
    "st": 5,
    "bm": 0
  }, {
    "ddd": 0, "ind": 3, "ty": 2, "nm": "-1pa.png", "cl": "png", "refId": "image_1", "sr": 1, "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 0,
          "s": [0]
        }, {
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 5,
          "s": [100]
        }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 27, "s": [100]}, {
          "t": 31,
          "s": [0]
        }],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {
        "a": 1,
        "k": [{
          "i": {"x": 0.667, "y": 1},
          "o": {"x": 0.333, "y": 0},
          "t": 0,
          "s": [400, 739, 0],
          "to": [0, -38.791, 0],
          "ti": [0, 35.546, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.333, "y": 0},
          "t": 10,
          "s": [400, 506.257, 0],
          "to": [0.25, 0.204, 0],
          "ti": [0.042, 2.148, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 20,
          "s": [400, 525.726, 0],
          "to": [-0.042, -2.148, 0],
          "ti": [0.014, 1.318, 0]
        }, {
          "i": {"x": 0.833, "y": 0.833},
          "o": {"x": 0.167, "y": 0.167},
          "t": 23,
          "s": [399.75, 493.367, 0],
          "to": [-2.454, -237.653, 0],
          "ti": [-90.855, 49.41, 0]
        }, {"t": 31, "s": [658.407, 69.973, 0]}],
        "ix": 2
      },
      "a": {"a": 0, "k": [60, 60, 0], "ix": 1},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 0,
          "s": [1, 1, 100]
        }, {
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 10,
          "s": [100, 100, 100]
        }, {
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 20,
          "s": [90, 90, 100]
        }, {
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 23,
          "s": [100, 100, 100]
        }, {"t": 31, "s": [96, 96, 100]}],
        "ix": 6
      }
    }, "ao": 0, "ip": 0, "op": 20, "st": 0, "bm": 0
  }],
  "markers": []
}
