import React from "react"
import {T} from "../../../../translations/translate"
import styled from "@emotion/native"
import {FragmentsCounter} from "@civitime/library/storybook/stories/Counters"
import {MapButtonsAppSkeleton} from "../MapButtonsAppSkeleton"
import {colors} from "@civitime/library/storybook/configs/colors"
import {useDataLoading} from "../../../../Utils/dataClient"
import {clientId} from "../../../../../clientId"
import {Blazon} from "@civitime/library/storybook/stories/Blazon"
import {ChallengeButton, HelperButton} from "@civitime/library/storybook/stories/Button"
import {Notification} from "../Challenges/pages/ChallengesHistory"
import {useProfile} from "../../../../Auth/useProfile"
import {useCampaign} from "../../../../GlobalContexts"
import {Focus} from "./FocusButton"

export const Phase1WrapperLeftContent = ({showToolTip, updateShowTooTips, mobile}) => {
  const {technicalProfile, userState} = useProfile()

  React.useEffect(() => {
    if(technicalProfile.lmsData) {
      window.parent.postMessage(JSON.stringify({
        type: "fragmentUpdated",
        currentFragments: userState?.fragments,
        totalFragments: 6,
        success: userState?.fragments === 6
      }), "*");
    }
  }, [userState?.fragments])

  return <HomeFragments fragments={userState?.fragments || 0} maxFragments={6}
                        hovered={showToolTip?.fragments}
                        mobile={mobile}
                        toolTip={<T path="app.toolTips.fragments"/>}
                        onMouseEnter={() => updateShowTooTips({fragments: true})}
                        onMouseLeave={() => updateShowTooTips(null)}/>
}

export const Phase1WrapperRightContent = ({notifications}) => {
  const {campaignNavigate} = useCampaign()

  return <Challenge
    notification={notifications}
    campaignNavigate={campaignNavigate}
  />
}

export const Phase1HomeButtons = ({mobile, returnToMap, width, height, openHelper, ...props}) => {
  const {userState} = useProfile()
  const {campaignNavigate, campaignId} = useCampaign()

  return <>
    <MapButtonsAppSkeleton mobile={mobile} onMap={props?.onMap} playerFocus={props?.playerFocus} returnToMap={returnToMap}
                           updateZoom={props?.updateZoom} zoomLevel={props?.zoomLevel} panValue={props?.panValue} screenWidth={width}
                           screenHeight={height} screenScale={props?.screenScalePropagation}/>
    {
      props?.onMap && !returnToMap && (
        <HomeHelper
          size={30}
          iconSize={20}
          color={colors.lightBackground}
          iconColor={colors.dropShadow}
          onPress={openHelper}
        />
      )}
    {userState?.teamId && (
      <TeamBlazon
        teamId={userState?.teamId}
        {...{campaignId, campaignNavigate, mobile}}
      />
    )}
  </>
}

export const Phase1MobileHeader = ({returnToMap, openHelper, ...props}) => {

  return <>
    {
      props?.onMap && !returnToMap &&
      <Focus playerFocus={props?.playerFocus}/>
    }
    {
      props?.onMap && !returnToMap && (
        <HomeHelper
          size={30}
          iconSize={20}
          color={colors.lightBackground}
          iconColor={colors.dropShadow}
          onPress={openHelper}
        />
      )
    }
  </>
}

const TeamBlazon = ({teamId, campaignId, campaignNavigate, mobile}) => {
  if (!teamId) return null

  const {data: team, loaded} = useDataLoading(
    `clients/${clientId}/campaigns/${campaignId}/teams/${teamId}`,
    [],
    false
  )

  return loaded && team ? (
    <HomeTeamButton
      blazon={team.logo.shape}
      color={team.logo.color}
      text={team.logo.text}
      size={mobile ? 50 : 60}
      name={team.name}
      onPress={() => {
        campaignNavigate("team")
      }}
    />
  ) : null
}

const Challenge = ({notification, campaignNavigate}) => {
  return (
    <ChallengeButtonWrapper>
      <HomeChallengesButton
        onPress={() => campaignNavigate('history')}
        size={30}
        iconSize={20}
      />
      {notification > 0 && <HomeNotification>{notification}</HomeNotification>}
    </ChallengeButtonWrapper>
  )
}

const HomeFragments = styled(FragmentsCounter)({
  marginTop: 15,
})

const HomeTeamButton = styled(Blazon)({
  position: 'fixed',
  top: '2.5%',
})

const HomeHelper = styled(HelperButton)({
  position: 'fixed',
  bottom: '5%',
  right: '5%',
})

const HomeNotification = styled(Notification)({
  position: 'absolute',
  bottom: '70%',
  left: 15,
  zIndex: 5,
})

const ChallengeButtonWrapper = styled.View({position: 'relative'})

const HomeChallengesButton = styled(ChallengeButton)({})


