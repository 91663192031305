import React, {useEffect, useMemo, useRef, useState} from "react"
import {useCampaign} from "../GlobalContexts"
import {T, useTranslator} from "../translations/translate"
import {calculateActualActionPoints} from "@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers"
import {colors} from "@civitime/library/storybook/configs/colors"
import {ActionPointsCounter, PointsCounter} from "@civitime/library/storybook/stories/Counters"
import styled from "@emotion/native"
import {
  LangSelectorButton,
  LeaderboardButton,
  ReturnToMapButton,
  SettingsButton
} from "@civitime/library/storybook/stories/Button"
import {AvatarComponent} from "@civitime/library/storybook/stories/Avatar"
import {
  Phase1HomeButtons,
  Phase1WrapperLeftContent,
  Phase1WrapperRightContent
} from "../Phases/Phase1/Components/AppSkeleton/Phase1Header"

const HeaderComponentsElements = {
  1: {
    HeaderLeftContent: Phase1WrapperLeftContent,
    HeaderRightContent: Phase1WrapperRightContent,
    HeaderButtons: Phase1HomeButtons
  }
}

export const Header = ({
                         publicProfile,
                         returnToMap,
                         userState,
                         mobile,
                         openHelper,
                         notifications,
                         screenWidth,
                         screenHeight,
                         openLangSelector,
                         phase,
                         ...props
                       }) => {
  if (!publicProfile?.avatar || (!props?.onMap && phase === 1)) return null

  const {campaignNavigate, campaignSettings} = useCampaign()
  const [showToolTip, setShowToolTip] = useState(null)
  const cancelTimeout = useRef(null)
  const {t, lang} = useTranslator()

  const HeaderRender = useMemo(() => {
    if (!phase) return null
    return HeaderComponentsElements[phase]
  }, [phase])

  useEffect(() => {
    clearTimeout(cancelTimeout.current)
    if (mobile && showToolTip) {
      cancelTimeout.current = setTimeout(() => setShowToolTip(null), 6000)
    }
  }, [showToolTip])

  const actionPoints = calculateActualActionPoints(
    campaignSettings?.actionPointsPerDay,
    campaignSettings?.maxActionPoints,
    userState?.actionPoints,
    new Date().toISOString(),
    campaignSettings?.startAt
  )

  return (
    <HeaderWrapper>
      <WrapperLeftContent>
        <HomeAvatarButton
          playerName={!mobile && publicProfile.name}
          circleAvatarConfig={{
            size: mobile ? 50 : 60,
            withBorder: true,
            backgroundColor: colors.extraLightBackground,
          }}
          cartridgeConfig={{
            width: 128,
            height: 20,
            backgroundColor: colors.text,
            color: colors.lightText,
          }}
          onPress={() => {
            campaignNavigate("avatar")
          }}
          avatar={publicProfile?.avatar}
        />
        <ActionPointsCounter
          actionsPoints={actionPoints}
          startAt={campaignSettings?.startAt}
          hovered={showToolTip?.pa}
          mobile={mobile}
          toolTip={<T path="app.toolTips.pa"/>}
          onMouseEnter={() => setShowToolTip({pa: true})}
          onMouseLeave={() => setShowToolTip(null)}
        />
        <HomePoints points={userState.points || 0}
                    hovered={showToolTip?.points}
                    toolTip={<T path="app.toolTips.points"/>}
                    mobile={mobile}
                    onMouseEnter={() => setShowToolTip({points: true})}
                    onMouseLeave={() => setShowToolTip(null)}/>
        {
          HeaderRender &&
          <HeaderRender.HeaderLeftContent showToolTip={showToolTip}
                                          updateShowTooTips={setShowToolTip}
                                          mobile={mobile}/>
        }
      </WrapperLeftContent>
      {
        HeaderRender &&
        <HeaderRender.HeaderButtons returnToMap={returnToMap} width={screenWidth} height={screenHeight}
                                    mobile={mobile} openHelper={openHelper} {...props}/>
      }
      <IconsWrapper>
        {campaignSettings?.internationalizedContent && <HomeLangSelectorButton
          size={30}
          iconSize={20}
          onPress={openLangSelector}
          text={lang}
        />}
        <HomeSettingsButton
          size={30}
          iconSize={20}
          onPress={() => campaignNavigate("settings")}
        />
        <HomeLeaderboardButton
          size={30}
          iconSize={20}
          onPress={() => campaignNavigate("leaderboard/team")}
        />
        {
          HeaderRender &&
          <HeaderRender.HeaderRightContent notifications={notifications}/>
        }
      </IconsWrapper>
      {returnToMap && (
        <AppReturnToMap
          text={t("app.header.returnToMap")}
          onPress={() => campaignNavigate("")}
        />
      )}
    </HeaderWrapper>
  )
}

const HomeSettingsButton = styled(SettingsButton)({
  position: 'relative',
})

const HomeLeaderboardButton = styled(LeaderboardButton)({
  position: 'relative',
})

const HomeAvatarButton = styled(AvatarComponent)({
  marginBottom: 20,
})

const WrapperLeftContent = styled.View({
  alignItems: 'center',
  position: 'fixed',
  top: '2.5%',
  left: '2.5%',
})
const HeaderWrapper = styled.View({
  alignItems: 'center',
  zIndex: 4,
})

const AppReturnToMap = styled(ReturnToMapButton)({
  position: 'fixed',
  top: '6%',
  zIndex: 2,
  right: '17%',
})
const HomePoints = styled(PointsCounter)({
  marginVertical: 10,
})

const IconsWrapper = styled.View({
  position: 'fixed',
  top: '2%',
  right: '5%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: 140,
})

const HomeLangSelectorButton = styled(LangSelectorButton)({
  position: 'relative'
})
