import {useCampaign} from "../GlobalContexts"
import {useTranslator} from "../translations/translate"
import {calculateActualActionPoints} from "@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers"
import React, {useMemo} from "react"
import {AvatarComponent} from "@civitime/library/storybook/stories/Avatar"
import {colors} from "@civitime/library/storybook/configs/colors"
import {ActionPointsCounter, PointsCounter} from "@civitime/library/storybook/stories/Counters"
import {Phase1MobileHeader} from "../Phases/Phase1/Components/AppSkeleton/Phase1Header"
import styled from "@emotion/native"
import {LangSelectorButton, ReturnToMapButton, SettingsButton} from "@civitime/library/storybook/stories/Button"
import {MOBILE_HEADER_SIZE} from "@civitime/library/storybook/configs/sizes"

const MobileHeaderComponentsElements = {
  1: {
    MobileHeader: Phase1MobileHeader,
  }
}

export const MobileHeader = ({
                        userState,
                        avatar,
                        returnToMap,
                        publicProfile,
                        openHelper,
                        phase,
                        openLangSelector,
                        ...props
                      }) => {
  const {campaignNavigate, campaignSettings} = useCampaign()
  if (!publicProfile?.avatar) return null

  const {t, lang} = useTranslator()
  const actionPoints = calculateActualActionPoints(
    campaignSettings?.actionPointsPerDay,
    campaignSettings?.maxActionPoints,
    userState?.actionPoints,
    new Date().toISOString(),
    campaignSettings?.startAt
  )

  const MobileHeaderRender = useMemo(() => {
    if (!phase) return null
    return MobileHeaderComponentsElements[phase]
  }, [phase])

  return (
    <HeaderWrapperMobile>
      <AvatarComponent
        avatar={avatar}
        circleAvatarConfig={{
          size: 50,
          withBorder: true,
          backgroundColor: colors.extraLightBackground,
        }}
        onPress={() => {
          campaignNavigate('avatar')
        }}
      />
      <ActionPointsCounter
        lastPASpent={new Date()}
        actionsPoints={actionPoints}
        mobile
        header
      />
      {campaignSettings?.internationalizedContent && <HomeLangSelectorButton
        size={30}
        iconSize={20}
        onPress={openLangSelector}
        text={lang}
      />}
      {
        MobileHeaderRender &&
        <MobileHeaderRender returnToMap={returnToMap} openHelper={openHelper} {...props}/>
      }
      <PointsCounter points={userState.points || 0} mobile/>
      {returnToMap ? (
        <AppReturnToMapMobile
          text={t("app.header.returnToMap")}
          onPress={() => campaignNavigate('')}
          mobile
        />
      ) : (
        <HomeSettingsButtonMobile
          size={30}
          iconColor={colors.lightText}
          color={colors.vividBlue}
          iconSize={20}
          onPress={() => campaignNavigate('settings')}
        />
      )}
    </HeaderWrapperMobile>
  )
}

const HomeLangSelectorButton = styled(LangSelectorButton)({
  position: 'relative'
})

const HeaderWrapperMobile = styled.View({
  zIndex: 5,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  paddingHorizontal: 5,
  paddingVertical: 7,
  height: MOBILE_HEADER_SIZE + 20,
  top: 0,
  marginBottom: 30,
  width: '100%',
})

const AppReturnToMapMobile = styled(ReturnToMapButton)({
  zIndex: 2,
})

const HomeSettingsButtonMobile = styled(SettingsButton)({
  zIndex: 2,
})
