import React, {useRef, useState} from 'react'
import styled                    from "@emotion/native"
import {DefaultModal}            from "@civitime/library/storybook/stories/Modals"
import {colors}                  from "@civitime/library/storybook/configs/colors"
import {ArrowDownButton}         from '@civitime/library/storybook/stories/Button'
import {ScrollView}              from 'react-native'
import { T, useTranslator }      from '../../../translations/translate'
import { useCampaign }           from '../../../GlobalContexts'

export const HelperModal = ({closeHelper, mobile, orientation}) => {
  const scrollRef = useRef(null)
  const [scroll, setScroll] = useState(0)
  const onPress = () => {
    scrollRef?.current.scrollTo(scroll + 100, 0, {behaviour: 'smooth'})
  }
  const {t} = useTranslator()
  const {campaignSettings} = useCampaign()
  return (
    <WrapperHelperModal>
      <DefaultModal
        title={t('phase_1.helperModal.title')}
        onPress={closeHelper}
        closeButtonColor={colors.secondary}
        backgroundColor={colors.darkerBackground}
        color={colors.text}
        crossSize={25}
        noBorder
        contentBackgroundColor={colors.defaultBackground}
        borderRadius={5}
      >
        <ScrollView mobile={mobile}
                    orientation={orientation}
                    ref={scrollRef}
                    onScroll={(e) => setScroll(e?.nativeEvent?.contentOffset?.y ? e.nativeEvent.contentOffset.y : 0)}
                    scrollEventThrottle={16}
                    contentContainerStyle={{
                      alignItems: 'center',
                      maxHeight: mobile && orientation === 'landscape' ? 200 : 350
                    }}>

          <WrapperFirstExplanation mobile={mobile}>
            <Goal mobile={mobile}>
              <TutoTitle mobile={mobile}>
                <T path='phase_1.helperModal.goal.title'/>
              </TutoTitle>
              <TutoText>
                <T path='phase_1.helperModal.goal.text'/>
              </TutoText>
              <FinalDestinationImage
                source={require('../Tuto/Assets/destination.png')}
                alt="Fd"
              />
            </Goal>
            <HowTo mobile={mobile}>
              <TutoTitle mobile={mobile}>
                <T path='phase_1.helperModal.howTo.title'/>
              </TutoTitle>
              <TutoText>
                <T path='phase_1.helperModal.howTo.text'/>
              </TutoText>
              <Hoot source={require('../Tuto/Assets/camp.png')} alt="Hoot"/>
            </HowTo>
          </WrapperFirstExplanation>
          <TutoTitle mobile={mobile}>
            <T path="phase_1.helperModal.actionPoints.title"/>
          </TutoTitle>
          <TutoText customPadding mobile={mobile}>
            <T path="phase_1.helperModal.actionPoints.firstExplanation" data={{0: campaignSettings?.actionPointsPerDay || 10}}/>
            <Thunder
              source={require('../Tuto/Assets/thunder.png')}
              alt="thunder"
            />
            <T path="phase_1.helperModal.actionPoints.secondExplanation"/>
          </TutoText>
          <TutoTitle mobile={mobile}>
            <T path="phase_1.helperModal.movement.title"/>
          </TutoTitle>
          <TutoText customPadding mobile={mobile}>
            <T path="phase_1.helperModal.movement.text"/>
          </TutoText>
          <WrapperImage>
            <MovesImage
              source={require('../Tuto/Assets/moves.png')}
              alt="modules"
            />
          </WrapperImage>
          <TutoTitle mobile={mobile}>
            <T path='phase_1.helperModal.modules.title'/>
          </TutoTitle>
          <TutoText customPadding mobile={mobile}>
            <T path='phase_1.helperModal.modules.firstExplanation'/>
            <Star source={require('../Tuto/Assets/star.png')} alt="star"/>
            <T path='phase_1.helperModal.modules.secondExplanation'/>
          </TutoText>
          <WrapperImage>
            <ModulesImage
              source={require('../Tuto/Assets/modules.png')}
              alt="modules"
            />
          </WrapperImage>
          <TutoTitle mobile={mobile}>
            <T path='phase_1.helperModal.ranking.title'/>
            <Rank source={require("../Tuto/Assets/rank.png")} alt="rank"/>
          </TutoTitle>
          <TutoText customPadding mobile={mobile}>
            <T path='phase_1.helperModal.ranking.text'/>
          </TutoText>
          <TutoTitle mobile={mobile}>
            <T path='phase_1.helperModal.notifications.title'/>
            <Quiz source={require("../Tuto/Assets/duel.png")} alt="quiz"/>
          </TutoTitle>
          <TutoText customPadding mobile={mobile}>
            <T path='phase_1.helperModal.notifications.text'/>
          </TutoText>
        </ScrollView>
        <DownButton color={'#1B39E2'} iconColor={'#F2F2F2'} iconSize={20} size={30} onPress={onPress}
                    mobile={mobile}/>
        <FooterHelperModal>
          <FooterText mobile={mobile}>
            <T path='phase_1.helperModal.footer'/>
            <a href="mailto:technique@civitime.com">
              <FooterTextEmail>technique@civitime.com</FooterTextEmail>
            </a>
          </FooterText>
        </FooterHelperModal>
      </DefaultModal>
    </WrapperHelperModal>
  )
}

const DownButton = styled(ArrowDownButton)(({mobile}) => ({
  position: 'absolute',
  right: mobile ? 10 : 20,
  bottom: mobile ? 56 : 40,
}))

const Rank = styled.Image({
  width: 25,
  height: 25,
  transform: [{translateY: 5}],
  display: 'inline-block',
})

const Quiz = styled.Image({
  width: 25,
  height: 25,
  transform: [{translateY: 5}],
  display: 'inline-block',
})

const Thunder = styled.Image({
  width: 22,
  height: 22,
  transform: [{translateY: 5}],
  display: 'inline-block',
})

const Star = styled.Image({
  width: 22,
  height: 22,
  transform: [{translateY: 5}],
  display: 'inline-block',
})

const WrapperImage = styled.View({})

const FinalDestinationImage = styled.Image({
  width: 200,
  height: 150,
})

const ModulesImage = styled.Image({
  width: 295,
  height: 153,
})

const MovesImage = styled.Image({
  width: 289,
  height: 174,
})

const Hoot = styled.Image({
  width: 231,
  height: 150,
})

const TutoTitle = styled.Text(({mobile}) => ({
  textAlign: 'center',
  marginTop: mobile ? 15 : 30,
  marginBottom: 15,
  color: colors.dropShadow,
  fontSize: 18,
  fontWeight: 'bold'
}))

const TutoText = styled.Text(({mobile, customPadding}) => ({
  textAlign: 'center',
  paddingRight: customPadding && !mobile ? 150 : 20,
  paddingLeft: customPadding && !mobile ? 150 : 20,
  marginBottom: mobile ? 15 : 50,
  color: colors.dropShadow,
  fontSize: 15,
  width: '100%'
}))

const WrapperFirstExplanation = styled.View(({mobile}) => ({
  flexDirection: mobile ? 'column' : 'row',
  justifyContent: mobile ? 'unset' : 'space-around',
}))

const Goal = styled.View(({mobile}) => ({
  maxWidth: mobile ? 'unset' : 400,
  alignItems: 'center',
  justifyContent: 'space-around'
}))

const HowTo = styled.View(({mobile}) => ({
  maxWidth: mobile ? 'unset' : 400,
  alignItems: 'center',
  justifyContent: 'space-around'
}))

const WrapperHelperModal = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const FooterHelperModal = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.cadetBlueL,
  paddingTop: 8,
  paddingBottom: 8,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
})

const FooterText = styled.Text(({mobile}) => ({
  color: 'white',
  fontSize: mobile ? 12 : 15,
  textAlign: 'center',
}))

const FooterTextEmail = styled(FooterText)({
  textDecoration: 'inherit',
  marginLeft: 5
})
