import React, {useEffect, useRef, useState} from 'react'
import styled from '@emotion/native'
import {findAvailableMoves} from './Tools'
import {colors} from '@civitime/library/storybook/configs/colors'
import {useProfile} from '../../../Auth/useProfile'
import {AvatarComponent} from '@civitime/library/storybook/stories/Avatar'
import {Platform} from 'react-native'
import {FinalDestinationAnimation} from './MapUi'
import {calculateActualActionPoints} from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers'
import {useCampaign} from '../../../GlobalContexts'

const shiftX = -123
const shiftY = 50


export const MapOverlay = ({
                             mapData,
                             tileWidth,
                             tilesNumbers,
                             tileHeight,
                             rawMapData,
                             playerPosition,
                             updatePlayerPosition,
                             moduleLauncher,
                             modulesEnded,
                             tripLauncher,
                             previewPlayerAsset,
                             mapMoved,
                             hidePlayer,
                             updateMapMoved
                           }) => {
  const [tileHovered, setTileHovered] = useState({})
  const {publicProfile, emitCommand, userState} = useProfile()
  const {campaignSettings} = useCampaign()
  const actionPoints = calculateActualActionPoints(campaignSettings?.actionPointsPerDay, campaignSettings?.maxActionPoints,
    userState?.actionPoints,
    new Date().toISOString(),
    campaignSettings?.startAt
  )
  const mapWidth = tileWidth * tilesNumbers
  const mapHeight = tileWidth * tilesNumbers

  const [lockMovement, setLockMovement] = useState(false)

  const movementLocker = () => {
    setLockMovement(true)
    return setTimeout(() => setLockMovement(false), 300)
  }

  const onPressMovementBox = (tile, tileProperties) => {
    if (lockMovement) return
    const moduleEnded = modulesEnded.some((position) => tile.line === position.line && tile.position === position.tile)
    movementLocker()
    updateMapMoved(false)
    if (playerPosition.line === tile.line && playerPosition.tile === tile.position) {
      const moduleName = tileProperties.map((p) => p?.name === 'module' && p?.value)[0]
      tileProperties.forEach((p) => {
        if (p?.name === 'module' && !moduleEnded) {
          moduleLauncher(p?.value)
        }
        if (p?.name === 'trip' && moduleEnded) {
          tripLauncher(tile.line, tile.position, moduleName)
        }
      })
    } else {
      const moduleName = tileProperties.map((p) => p?.name === 'module' && p?.value)[0]
      const typeTile = tileProperties.some((p) => p?.name === 'practicable') &&
      tileProperties.some((p) => p?.name === 'type') ?
        tileProperties.filter((p) => p?.name === 'type')?.[0]?.value
        : 'basic'
      updatePlayerPosition(
        {line: tile.line, tile: tile.position},
        typeTile
      )
      tileProperties.forEach((p) => {
        if (p?.name === 'trip' && moduleEnded && findAvailableMoves(tile.line, tile.position, userState.map.playerPosition, tilesNumbers)) {
          tripLauncher(tile.line, tile.position, moduleName)
        }
        if (p?.name === 'finalDestination' && userState?.fragments === 6 && !userState?.finalDestinationDiscovered) {
          emitCommand({
            type: 'DiscoverFinalDestination',
            payload: {}
          })
          hidePlayer()
        }
      })
      if (!moduleEnded && findAvailableMoves(tile.line, tile.position, userState.map.playerPosition, tilesNumbers)) {
        tileProperties.forEach((p) => {
          if (p?.name === 'module') {
            moduleLauncher(p?.value)
          }
        })
      }
      setTileHovered({})
    }
  }

  const isHoveredBox = (tile, tileProperties, indexTile) => {
    !mapMoved &&
    tileProperties.some((p) => p?.name === 'practicable') &&
    findAvailableMoves(tile.line, tile.position, playerPosition, tilesNumbers) &&
    setTileHovered({line: tile.line, tile: indexTile})
  }

  const isDisabled = (tile, tileProperties) => {
    const isRiver = tileProperties.filter(v => v?.value === 'river').length
    return !findAvailableMoves(
      tile.line, tile.position, playerPosition, tilesNumbers,
      tileProperties.some((p) => p?.name === 'module')
      )
      || !tileProperties.some((p) => p?.name === 'practicable')
      || (playerPosition.line === tile.line && playerPosition.tile === tile.position &&
        !tileProperties.some((p) => p?.name === 'trip') &&
        modulesEnded.some((position) => tile.line === position.line && tile.position === position.tile))
      || (!(actionPoints >= (isRiver ? 2 : 1)) && !(playerPosition.line === tile.line && playerPosition.tile === tile.position))
  }

  const finalDestinationPulseAnim = require('../Animations/Pulse/data.js')
  const finalDestinationPulseRef = useRef(null)

  useEffect(() => {
    if (!finalDestinationPulseRef?.current) return
    finalDestinationPulseRef.current.play()
  }, [finalDestinationPulseRef?.current])

  return <>
    <WrapperOverlay width={mapWidth}
                    height={mapHeight}>
      {
        userState?.fragments === 6 && Platform.OS === 'web' && !userState?.finalDestinationDiscovered ?
          <>
            <FinalDestinationAnimationPulse source={finalDestinationPulseAnim?.default}
                                            tileHeight={tileHeight / 3}
                                            width={tileWidth}
                                            ref={finalDestinationPulseRef}
                                            loop
                                            autoplay
                                            speed={0.5}
            />
          </>
          : null
      }
      {
        mapData?.flatMap((line, indexLine) => line
          .map((tile, i) => ({
            tile: tile,
            line: indexLine,
            lineLength: line.length,
            position: i
          }))
          .flat())
          .map((tile, indexTile) => {
            const tileRef = rawMapData?.tilesets?.filter((asset) => asset?.firstgid === tile?.tile)?.[0]
            const tileProperties = tileRef?.properties || []
            const positionX = shiftX + (tile.position * tileWidth) + (-(tileWidth / 2) * (tile.lineLength - 1))
            const positionY = shiftY + (tile.line * (tileHeight / 2))
            const isRiver = tileProperties.filter(v => v?.value === 'river').length
            return findAvailableMoves(tile.line, tile.position, playerPosition, tilesNumbers, tileProperties.some((p) => p?.name === 'module')) &&
              (actionPoints >= (isRiver ? 2 : 1) ? true : (playerPosition.tile === tile.position && playerPosition.line === tile.line)) &&
              <OverlayClickable key={indexTile + 'tileOverlay'}
                                positionX={positionX}
                                positionY={positionY}
                                width={mapWidth}
              >
                {
                  tileHovered && (tileHovered?.line === tile.line && tileHovered?.tile === indexTile) && <WrapperPawn>
                    <PlayerPawn source={previewPlayerAsset || ''} alt={'tile'}/>
                    <PawnAvatar circleAvatarConfig={{
                      size: 145,
                      height: 140,
                      width: 143,
                      withBorder: true,
                      backgroundColor: colors.extraLightBackground,
                    }}
                                avatar={publicProfile?.avatar}
                    />

                    <PawnFilter/>
                  </WrapperPawn>
                }
                <MovementBoxClick
                  onMouseEnter={() => {
                    if (isDisabled(tile, tileProperties)) return
                    isHoveredBox(tile, tileProperties, indexTile)
                  }}
                  disabled={isDisabled(tile, tileProperties)}
                  onPress={() => {
                    if (isDisabled(tile, tileProperties)) return
                    onPressMovementBox(tile, tileProperties)
                  }}
                  tileHeight={tileHeight}
                />
              </OverlayClickable>
          })
      }
    </WrapperOverlay>
  </>
}

const FinalDestinationAnimationPulse = styled(FinalDestinationAnimation)({
  width: 11000,
  height: '100%',
  transform: [{translateX: 500}, {translateY: -2750}],
})

const MovementBoxClick = styled.TouchableOpacity({
  backgroundColor: 'transparent',
  transform: [{rotate: '45deg'}],
  position: 'relative',
  zIndex: 20,
}, ({tileHeight}) => ({
  width: Math.sqrt(tileHeight * tileHeight / 2),
  height: Math.sqrt(tileHeight * tileHeight / 2),
}))

const OverlayClickable = styled.View({
  position: 'absolute',
}, ({width, positionX, positionY}) => ({
  left: width / 2,
  transform: [
    {
      translateX: positionX
    },
    {
      translateY: positionY
    }
  ],
}))

const WrapperOverlay = styled.View({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  zIndex: 1,
}, ({width, height}) => ({
  width: width,
  height: height
}))

const WrapperPawn = styled.View({
  position: 'absolute',
  alignItems: 'center',
  top: -100,
  left: -28,
  zIndex: 1,
})

const PawnAvatar = styled(AvatarComponent)({
  position: 'absolute',
  transform: [{rotateY: '-26deg'}, {rotateX: '-26deg'}, {skew: '-9deg, 16deg'}],
  top: 30,
  left: 73,
  opacity: 0.6,
})

const PlayerPawn = styled.Image({
  width: 300,
  height: 300,
  opacity: 0.6,
})

const PawnFilter = styled.View({
  position: 'absolute',
  top: 18,
  left: 75,
  width: 150,
  height: 150,
  backgroundColor: '#FFF94F',
  opacity: 0.3,
  borderRadius: 100,
  transform: [{rotateY: '-26deg'}, {rotateX: '-26deg'}, {skew: '-17deg, 18deg'}]
})
