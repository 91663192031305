import React, {useEffect, useState} from "react"
import {Map} from "../../Map/Map"
import {useCampaign} from "../../../../GlobalContexts"
import {useProfile} from "../../../../Auth/useProfile"

export const Phase1AppSkeleton = ({returnToMap, updateHeaderDisplayed, setPhaseProps, openHelper, ...props}) => {
  const {campaignId, campaignSettings} = useCampaign()
  const {publicProfile, userState} = useProfile()
  const [onMap, setOnMap] = useState(null)
  const [playerFocus, setPlayerFocus] = useState(false)
  const [panValue, setPanValue] = useState({})
  const [screenScalePropagation, setScreenScalePropagation] = useState(1)
  const [zoomLevel, setZoomLevel] = useState(2)
  const dateNow = new Date(Date.now()).toISOString()

  useEffect(() => {
    setPhaseProps({
      playerFocus: () => makingPlayerFocus(),
      updateZoom: (e) => updateZoom(e),
      onMap: onMap,
      zoomLevel: zoomLevel,
      panValue: panValue,
      screenScalePropagation: screenScalePropagation
    })
  }, [onMap, zoomLevel, panValue, screenScalePropagation])

  const updateZoom = (direction) => {
    if (direction === 'upScale') {
      return zoomLevel < 3 ? setZoomLevel((e) => e + 1) : null
    } else {
      return zoomLevel > 1 ? setZoomLevel((e) => e - 1) : null
    }
  }

  const makingPlayerFocus = () => setPlayerFocus((e) => !e)

  useEffect(() => {
    if (!campaignId) return
    setOnMap(
      location.pathname.endsWith(`${campaignId}/`) ||
      location.pathname.endsWith(`/${campaignId}/`) ||
      location.pathname.endsWith(`${campaignId}`) ||
      location.pathname.endsWith(`leaderboard/team`) ||
      location.pathname.endsWith(`leaderboard/player`)
    )
  }, [campaignId, location.pathname])
  return <>
    {!returnToMap && publicProfile.avatar && (!campaignSettings?.endAt < dateNow ? userState?.teamId : true) && (
      <Map
        playerFocus={playerFocus}
        onMap={onMap}
        makingPlayerFocus={makingPlayerFocus}
        openHelper={openHelper}
        openedHelper={props?.openedHelper}
        closeHelper={props?.closeHelper}
        displayModule={props?.displayModule}
        appEnterModule={props?.enterModule}
        quitModule={props?.quitModule}
        panValue={panValue}
        updatePanValue={(e) => setPanValue(e)}
        updateHeaderDisplayed={(e) => updateHeaderDisplayed(e)}
        zoomLevel={zoomLevel}
        screenScalePropagation={(e) => setScreenScalePropagation(e)}
      />
    )}
  </>
}
