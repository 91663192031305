import React from "react"
import styled from "@emotion/native"
import { colors } from "@civitime/library/storybook/configs/colors"
import { Title1 } from "@civitime/library/storybook/stories/Texts"
import { AvatarComponent } from "@civitime/library/storybook/stories/Avatar"


export const PlayerInfos = ({ player, mobile, orientation, top, right, left }) => (
  <WrapperPlayerInfos {...{ mobile, orientation, top, right, left }}>
    <AvatarComponent
      circleAvatarConfig={{
        size: mobile ? (orientation === "portrait" ? 50 : 75) : 100,
        withBorder: true,
        backgroundColor: colors.extraLightBackground,
      }}
      avatar={player.avatar}
    />
    <PlayerName {...{ mobile, orientation }}>{player.name}</PlayerName>
  </WrapperPlayerInfos>
)

const WrapperPlayerInfos = styled.View(
  {
    position: "absolute",
  },
  ({ mobile, orientation, top, right, left }) => ({
    top: top ? top : 'unset',
    right: right ? right : right === 0 ? 0 : 'unset',
    left: left ? left : left === 0 ? 0 : 'unset',
    paddingHorizontal: mobile ? (orientation === "portrait" ? 30 : 50) : 100,
  })
)

const PlayerName = styled(Title1)(
  {
    textTransform: "uppercase",
    marginTop: 22,
  },
  ({ mobile, orientation }) =>
    mobile &&
    orientation === "portrait" && {
      marginTop: 15,
      fontSize: 12,
      lineHeight: 14,
    }
)
