export default {
  "v":
    "5.5.8", "fr":
    59.9254455566406, "ip":
    0, "op":
    280.002081866656, "w":
    1100, "h":
    1500, "nm":
    "drapeau-anim1", "ddd":
    0, "assets":
    [{"id": "image_0", "w": 123, "h": 93, "u": "", "p": require("./images/img_0.png"), "e": 0}, {
      "id": "image_1",
      "w": 433,
      "h": 413,
      "u": "",
      "p": require("./images/img_1.png"),
      "e": 0
    }, {"id": "image_2", "w": 69, "h": 714, "u": "", "p": require("./images/img_2.png"), "e": 0}], "layers":
    [{
      "ddd": 0, "ind": 1, "ty": 2, "nm": "Calque 8/drapeau-anim1.psd", "cl": "psd", "refId": "image_0", "sr": 1, "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 80.001,
            "s": [0],
            "e": [90]
          }, {"t": 100.000743523806}],
          "ix": 11
        },
        "r": {"a": 0, "k": 9, "ix": 10},
        "p": {"a": 0, "k": [166, 1120, 0], "ix": 2},
        "a": {"a": 0, "k": [3.843, 91.125, 0], "ix": 1},
        "s": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 80.001,
            "s": [14, 14, 100],
            "e": [169, 169, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 90.001,
            "s": [169, 169, 100],
            "e": [96, 106, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 110.001,
            "s": [96, 106, 100],
            "e": [106, 106, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 140.001,
            "s": [106, 106, 100],
            "e": [96, 106, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 180.001,
            "s": [96, 106, 100],
            "e": [106, 106, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 220.002,
            "s": [106, 106, 100],
            "e": [96, 106, 100]
          }, {"t": 260.001933161895}],
          "ix": 6
        }
      }, "ao": 0, "ip": 80.0005948190446, "op": 280.002081866656, "st": 0, "bm": 3
    }, {
      "ddd": 0, "ind": 2, "ty": 2, "nm": "Calque 5 copie 3", "refId": "image_1", "sr": 1, "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.333], "y": [0]},
            "t": 0,
            "s": [0],
            "e": [100]
          }, {
            "i": {"x": [0.667], "y": [1]},
            "o": {"x": [0.167], "y": [0]},
            "t": 9,
            "s": [100],
            "e": [100]
          }, {"t": 35.000260233332}],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {"x": [0.667], "y": [1]},
            "o": {"x": [0.333], "y": [0]},
            "t": 0,
            "s": [45],
            "e": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 35,
            "s": [0],
            "e": [-2]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 40,
            "s": [-2],
            "e": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 50,
            "s": [0],
            "e": [1]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 60,
            "s": [1],
            "e": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 70.001,
            "s": [0],
            "e": [-1]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 80.001,
            "s": [-1],
            "e": [0]
          }, {"t": 90.0006691714252}],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "t": 0,
            "s": [796, 340, 0],
            "e": [134, 1500, 0],
            "to": [-262.333, 249.333, 0],
            "ti": [-9.497, -539.255, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 35,
            "s": [134, 1500, 0],
            "e": [134, 1500, 0],
            "to": [0.037, 2.099, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 40,
            "s": [134, 1500, 0],
            "e": [134, 1469, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 50,
            "s": [134, 1469, 0],
            "e": [134, 1500, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {"t": 60.0004461142835}],
          "ix": 2
        },
        "a": {"a": 0, "k": [18, 665, 0], "ix": 1},
        "s": {
          "a": 1,
          "k": [{
            "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
            "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
            "t": 0,
            "s": [52, 52, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 35,
            "s": [100, 100, 100],
            "e": [100, 90, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 40,
            "s": [100, 90, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 50,
            "s": [100, 100, 100],
            "e": [100, 95, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 60,
            "s": [100, 95, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 70.001,
            "s": [100, 100, 100],
            "e": [100, 98, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 80.001,
            "s": [100, 98, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 90.001,
            "s": [100, 100, 100],
            "e": [90, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 110.001,
            "s": [90, 100, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 140.001,
            "s": [100, 100, 100],
            "e": [90, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 180.001,
            "s": [90, 100, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 220.002,
            "s": [100, 100, 100],
            "e": [90, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 260.002,
            "s": [90, 100, 100],
            "e": [100, 100, 100]
          }, {"t": 300.002230571417}],
          "ix": 6
        }
      }, "ao": 0, "ip": 0, "op": 280.002081866656, "st": 0, "bm": 0
    }, {
      "ddd": 0, "ind": 3, "ty": 2, "nm": "Calque 6", "refId": "image_2", "sr": 1, "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.333], "y": [0]},
            "t": 0,
            "s": [0],
            "e": [100]
          }, {
            "i": {"x": [0.667], "y": [1]},
            "o": {"x": [0.167], "y": [0]},
            "t": 9,
            "s": [100],
            "e": [100]
          }, {"t": 35.000260233332}],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [{
            "i": {"x": [0.667], "y": [1]},
            "o": {"x": [0.333], "y": [0]},
            "t": 0,
            "s": [45],
            "e": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 35,
            "s": [0],
            "e": [-2]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 40,
            "s": [-2],
            "e": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 50,
            "s": [0],
            "e": [1]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 60,
            "s": [1],
            "e": [0]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 70.001,
            "s": [0],
            "e": [-1]
          }, {
            "i": {"x": [0.833], "y": [0.833]},
            "o": {"x": [0.167], "y": [0.167]},
            "t": 80.001,
            "s": [-1],
            "e": [0]
          }, {"t": 90.0006691714252}],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "t": 0,
            "s": [795.5, 339, 0],
            "e": [133.5, 1499, 0],
            "to": [-260.333, 255.333, 0],
            "ti": [-8.439, -541.273, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 35,
            "s": [133.5, 1499, 0],
            "e": [133.5, 1499, 0],
            "to": [0.041, 2.599, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 40,
            "s": [133.5, 1499, 0],
            "e": [133.5, 1468, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {
            "i": {"x": 0.833, "y": 0.833},
            "o": {"x": 0.167, "y": 0.167},
            "t": 50,
            "s": [133.5, 1468, 0],
            "e": [133.5, 1499, 0],
            "to": [0, 0, 0],
            "ti": [0, 0, 0]
          }, {"t": 60.0004461142835}],
          "ix": 2
        },
        "a": {"a": 0, "k": [33.5, 713, 0], "ix": 1},
        "s": {
          "a": 1,
          "k": [{
            "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
            "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
            "t": 0,
            "s": [52, 52, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 35,
            "s": [100, 100, 100],
            "e": [100, 90, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 40,
            "s": [100, 90, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 50,
            "s": [100, 100, 100],
            "e": [100, 95, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 60,
            "s": [100, 95, 100],
            "e": [100, 100, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 70.001,
            "s": [100, 100, 100],
            "e": [100, 98, 100]
          }, {
            "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
            "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
            "t": 80.001,
            "s": [100, 98, 100],
            "e": [100, 100, 100]
          }, {"t": 90.0006691714252}],
          "ix": 6
        }
      }, "ao": 0, "ip": 0, "op": 280.002081866656, "st": 0, "bm": 0
    }], "markers":
    []
}
