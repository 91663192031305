import React, {useEffect, useRef, useState} from 'react'
import {findAvailableMoves, formatTiledMap, updateMapFog} from './Tools'
import styled from '@emotion/native'
import {Dimensions} from 'react-native'
import {useCampaign} from '../../../GlobalContexts'
import {WrapperComponent} from './MapMovements'
import {MapUi} from './MapUi'
import {MapOverlay} from './MapOverlay'
import {useProfile} from '../../../Auth/useProfile'
import {
  FragmentModal,
  InfoFinalDestination,
  LostModuleTitle,
  ModalFinalDestinationDiscovered,
  ModalModules, ModalTitle, WrapperModalContent,
} from '../Components/Modules/Components/ModulesModal'
import {MediumText, SmallText} from '@civitime/library/storybook/stories/Texts'
import {colors} from '@civitime/library/storybook/configs/colors'
import {useScreenDimensions} from '@civitime/library/storybook/hooks/useScreenDimensions'
import {HelperModal} from '../Components/HelperModal'
import {Modules} from './Modules'
import {useLocation} from 'react-router'
import {ModulePNJResults} from '../Components/Modules/Components/ModulePNJResults'
import {
  calculateActualActionPoints,
  canConsumeActionPoint
} from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers'
import {firebaseAccessor} from '../../../firebase'
import {clientId} from '../../../../clientId'
import {ModuleResults} from '../Components/Modules/Components/ModulesResults'
import {ModalNoMorePAModal} from '../Components/NoMorePAModal'
import {CampaignEndedModal} from '../Components/campaignEndedModal'
import {AppLoader} from '../Components/AppLoader'
import {T, useTranslator} from '../../../translations/translate'

export const Map = ({
                      playerFocus, makingPlayerFocus, closeHelper, openedHelper, appEnterModule, quitModule, displayModule, onMap, updateHeaderDisplayed, zoomLevel, panValue,
                      updatePanValue, screenScalePropagation
                    }) => {
  const {campaignId, campaignSettings, campaignNavigate, campaignEnded} = useCampaign()
  const {userState, userSession, emitCommand} = useProfile()
  const root = useRef(null)
  const {mobileTablet, mobile, orientation} = useScreenDimensions(root)
  const screenWidth = Math.round(Dimensions.get('window').width)
  const screenHeight = Math.round(Dimensions.get('window').height)
  const {t, content} = useTranslator()
  const calculateMoveRight = (cost) => canConsumeActionPoint(campaignSettings.actionPointsPerDay, campaignSettings.maxActionPoints, userState.actionPoints, new Date(), campaignSettings.startAt, cost)
  const [rawMapData, setRawMapData] = useState([])
  const [mapData, setMapData] = useState([])
  const [mapAssets, setMapAssets] = useState({})
  const [playerAsset, setPlayerAsset] = useState({})
  const [previewPlayerAsset, setPreviewPlayerAsset] = useState({})
  const [playerPosition, setPlayerPosition] = useState({})
  const [mapState, setMapState] = useState([])
  const [launchTrip, setLaunchTrip] = useState(null)
  const [moduleTilesEnded, setModuleTilesEnded] = useState([])
  const [fragmentModal, setFragmentModal] = useState(false)
  const [infoFinalDestination, setInfoFinalDestination] = useState(false)
  const [modalFinalDestinationDiscovered, setModalFinalDestinationDiscovered] = useState(false)
  const [mapLoading, setMapLoading] = useState(true)
  const [mapMoved, setMapMoved] = useState(false)
  const [normalMove, setNormalMove] = useState(false)
  const [finalDestinationFocus, setFinalDestinationFocus] = useState(false)
  const [hidePlayer, setHidePlayer] = useState(false)
  const [displayModulesResult, setDisplayModulesResult] = useState(null)
  const [lostModule, setLostModule] = useState(false)
  const [noMorePA, setNoMorePA] = useState(false)
  const [displayedPAModal, setDisplayedPAModal] = useState(false)
  const moveIsATrip = useRef(false)
  const actionPoints = calculateActualActionPoints(campaignSettings?.actionPointsPerDay, campaignSettings?.maxActionPoints,
    userState?.actionPoints,
    new Date().toISOString(),
    campaignSettings?.startAt
  )
  const [paths, setPaths] = useState([])
  const location = useLocation()
  const challenge = paths.includes('challenges')
  const enterModule = () => {
    if (location.pathname.split('/')[5]) {
      return appEnterModule && appEnterModule()
    }
    if (actionPoints <= 0 && (userState?.map?.playerPosition?.tile === playerPosition?.tile
      && userState?.map?.playerPosition?.line === playerPosition?.line)) {
      return appEnterModule()
    }
    if (actionPoints <= 0 && !(userState?.map?.playerPosition?.tile === playerPosition?.tile
      && userState?.map?.playerPosition?.line === playerPosition?.line)) {
      return setPlayerPosition(userState?.map?.playerPosition)
    }
    if (userState?.map?.playerPosition?.tile === playerPosition?.tile
      && userState?.map?.playerPosition?.line === playerPosition?.line) {
      return appEnterModule()
    }
  }

  useEffect(() => {
    if (actionPoints === 0) {
      setNoMorePA(true)
    }
  }, [actionPoints])
  useEffect(() => {
    if (actionPoints <= 0) {
      if (moveIsATrip.current) {
        moveIsATrip.current = false
        return
      }
      if (!(userState?.map?.playerPosition?.tile === playerPosition?.tile
        && userState?.map?.playerPosition?.line === playerPosition?.line)) {
        return setPlayerPosition(userState?.map?.playerPosition)
      }
    }
  }, [playerPosition, actionPoints])

  const setResult = (result) => setTimeout(() => setDisplayModulesResult(result), 2000)
  const closeModulesResult = () => setDisplayModulesResult(null)
  const tilesMode = true

  const [moduleClicked, setModuleClicked] = useState()
  const [displayModuleModal, setDisplayModuleModal] = useState(false)
  const [moduleLevelData, setModuleLevelData] = useState()
  const [moduleTheme, setModuleTheme] = useState(null)
  const currentLevel = userSession?.[moduleClicked?.module]?.nextLevel || 'lv-1'
  const tileHeight = rawMapData?.tileheight
  const tileWidth = rawMapData?.tilewidth
  const tilesNumbers = rawMapData?.width

  const [showLoader, setShowLoader] = useState(true)

  const disableLoader = () => {
    updateHeaderDisplayed(true)
    setShowLoader(false)
  }

  useEffect(() => {
    if (mapData && mapAssets && rawMapData) {
      setMapLoading(false)
    }
  }, [mapData, mapAssets, rawMapData])
  useEffect(() => {
    setPaths(location.pathname.split('/'))
    if (location.pathname.split('/')[5]) {
      enterModule()
    }
  }, [location, appEnterModule])

  useEffect(() => {
    if (!userSession || !campaignSettings) return
    const modulesName = Object.values(campaignSettings?.modules)
    const tilesEndedPositions = modulesName.reduce((acc, module) => {
      userSession?.[module]?.levelsDone &&
      Object.entries(userSession?.[module]?.levelsDone).forEach(([_level, content]) => {
        if (content.destination) {
          acc.push(content.destination)
        }
        acc.push(content.position)
      })
      return acc
    }, [])
    setModuleTilesEnded(tilesEndedPositions)
  }, [userSession, campaignSettings])

  useEffect(() => {
    const mapData = JSON.parse(userState?.map?.fogMap)
    setMapState(mapData)
  }, [])

  useEffect(() => {
    const playerPositionData = userState?.map?.playerPosition
    setPlayerPosition(playerPositionData)
  }, [])

  useEffect(() => {
    (async () => {
      await fetch(`https://ct-campaigns.civitimeapp.com/${campaignId}/map/data/data.json`)
        .then(res => res.json())
        .then(async (out) => {
          if (tilesMode) {
            const mapTilesAssets = {}
            const assetFd = `https://ct-campaigns.civitimeapp.com/${campaignId}/map/images/tile-arrive-fd.png`
            await fetch(assetFd)
              .then(async res => await res.blob())
              .then(async (blob) => {
                return mapTilesAssets['fd-tile'] = URL.createObjectURL(blob)
              }).catch(err => console.error(err))
            await Promise.all(
              out?.tilesets?.map(async (tile, index) => {
                const assetUrl = `https://ct-campaigns.civitimeapp.com/${campaignId}/map/images/${tile.image?.split('/').pop()}`
                await fetch(assetUrl)
                  .then(async res => await res.blob())
                  .then(async (blob) => {
                    return mapTilesAssets[index + 1] = URL.createObjectURL(blob)
                  }).catch(err => console.error(err))
              }))
              .then(_v => {
                setMapAssets(mapTilesAssets)
                setRawMapData(out)
              })
          } else {
            setRawMapData(out)
            const mapUrl = `https://ct-campaigns.civitimeapp.com/${campaignId}/map/images/map.png`
            fetch(mapUrl)
              .then(res => res.blob())
              .then((blob) => {
                setMapAssets({0: URL.createObjectURL(blob)})
              }).catch(err => console.error(err))
          }
          const formatMap = formatTiledMap(out)
          setMapData(formatMap)
          const playerAssetUrl = `https://ct-campaigns.civitimeapp.com/${campaignId}/map/images/pion.png`
          const playerPreviewAssetUrl = `https://ct-campaigns.civitimeapp.com/${campaignId}/map/images/pion-preview.png`
          fetch(playerAssetUrl)
            .then(res => res.blob())
            .then((out) => {
              setPlayerAsset(URL.createObjectURL(out))
            }).catch(err => console.error(err))
          fetch(playerPreviewAssetUrl)
            .then(res => res.blob())
            .then((out) => {
              setPreviewPlayerAsset(URL.createObjectURL(out))
              disableLoader()
            }).catch(err => console.error(err))
        }).catch(err => console.error(err))
    })()
  }, [])

  const updatePlayerPosition = (tile, typeTile) => {
    if (!findAvailableMoves(tile.line, tile.tile, userState.map.playerPosition, tilesNumbers) && typeTile !== 'trip') return
    if (calculateMoveRight(typeTile === 'river' ? 2 : 1) ? true : typeTile === 'trip') {
      moveIsATrip.current = typeTile === 'trip'
      setNormalMove(typeTile !== 'trip')
      setPlayerPosition({line: tile.line, tile: tile.tile})
      emitCommand({
        type: 'UpdatePlayerPosition',
        payload: {
          playerPosition: {line: tile.line, tile: tile.tile},
          typeTile: typeTile
        }
      })
      updateMapFog(mapState,
        tilesNumbers,
        {line: tile.line, tile: tile.tile},
        (newFogMap) => {
          setMapState(newFogMap)
          emitCommand({
            type: 'UpdateFogMap',
            payload: {
              fogMap: newFogMap
            }
          })
        }, typeTile === 'trip')
      if (typeTile === 'trip') return
      makingPlayerFocus()
    }
  }
  const [screenScale, setScreenScale] = useState(1)

  useEffect(() => {
    if (!screenWidth || !screenHeight || !tileHeight) return
    if (mobile) {
      const tilesOnScreen = zoomLevel === 1 ? 6 : zoomLevel === 2 ? 4 : zoomLevel === 3 ? 2 : 4
      const scale = parseFloat((screenWidth / (tileHeight * tilesOnScreen)).toFixed(2))
      screenScalePropagation(scale)
      return setScreenScale(scale)
    } else {
      const tilesOnScreen = zoomLevel === 1 ? 18 : zoomLevel === 2 ? 9 : zoomLevel === 3 ? 6 : 9
      const scale = parseFloat((screenWidth / (tileHeight * tilesOnScreen)).toFixed(2))
      screenScalePropagation(scale)
      setScreenScale(scale)
    }
  }, [screenWidth, screenHeight, tileHeight, zoomLevel])

  const closeModule = () => {
    quitModule()
    campaignNavigate('')
  }
  const moduleLauncher = (module) => {
    const moduleName = campaignSettings?.modules?.[module]
    const moduleLevel = userSession?.[moduleName]?.nextLevel || 'lv-1'
    const destination = campaignSettings?.map?.trips?.[module.name]?.[parseInt(`${playerPosition.line}${playerPosition.tile}`)].end || null
    const moduleState = {
      name: module,
      level: moduleLevel,
      module: moduleName,
      destination: destination
    }
    setModuleClicked(moduleState)
    setDisplayModuleModal(true)
    emitCommand({
      type: "SeePNJModuleModal",
      payload: {
        module: moduleName,
        name: module,
        level: moduleLevel
      }
    })
  }

  const tripLauncher = (line, tile, type) => {
    if (!type) return
    const destination = campaignSettings?.map?.trips?.[type]?.[`${line}-${tile}`].end
    const moduleName = campaignSettings?.modules?.[type]
    setLaunchTrip({module: moduleName, destination: destination, name: type})
  }

  const getModuleTheme = async () => {
    const translatedModuleTheme = await firebaseAccessor
      .get(`clients/${clientId}/campaigns/${campaignId}/content/${moduleClicked?.module}/themes/${currentLevel}`)
    setModuleTheme(translatedModuleTheme ? translatedModuleTheme : content?.[moduleClicked?.module]?.[currentLevel]?.theme)
  }

  useEffect(() => {
    getModuleTheme()
  }, [moduleClicked])

  const finishModule = (win) => {
    const moduleName = displayModulesResult?.moduleClicked?.name
    const destination = campaignSettings?.map?.trips?.[moduleName]?.[`${playerPosition.line}-${playerPosition.tile}`]?.end || null
    if (!win) return
    if (destination) {
      updatePlayerPosition(destination, 'trip')
      makingPlayerFocus()
      setLaunchTrip(null)
    } else {
      if (moduleName === 'hoot') {
        emitCommand({
          type: 'UpdatePlayerFragments',
          payload: {}
        })
        setFragmentModal(true)
      }
    }
    setDisplayModulesResult(null)
    setModuleClicked(null)
  }

  const quitFinishModule = (win) => {
    const moduleName = displayModulesResult?.moduleClicked?.name
    if (!win) return
    if (moduleName === 'hoot') {
      emitCommand({
        type: 'UpdatePlayerFragments',
        payload: {}
      })
      setFragmentModal(true)
    }
    setDisplayModulesResult(null)
    setModuleClicked(null)
  }

  const discoverFinalDestination = () => {
    setFinalDestinationFocus(true)
  }

  useEffect(() => {
    if (mapLoading) return
    if (displayModulesResult || fragmentModal || infoFinalDestination || openedHelper || modalFinalDestinationDiscovered || launchTrip || displayModuleModal || lostModule) {
      updateHeaderDisplayed(false)
    } else {
      updateHeaderDisplayed(true)
    }
  }, [displayModulesResult, fragmentModal, infoFinalDestination, openedHelper, modalFinalDestinationDiscovered, launchTrip, displayModuleModal, lostModule])

  useEffect(() => {
    if (mapLoading) return
    if (campaignEnded && !userState?.campaignEndedModalSeen && userState?.teamId) {
      updateHeaderDisplayed(false)
    } else {
      updateHeaderDisplayed(true)
    }
  }, [campaignEnded])

  const updateMapMove = (e) => {
    setMapMoved(e)
  }

  return <Container ref={root} zIndex={displayModule && mobileTablet || launchTrip && mobileTablet ? 5 : 4}
                    moduleDisplayed={!!displayModule}>
    {
      showLoader &&
      <AppLoader/>
    }
    {noMorePA && !displayedPAModal &&
    <ModalNoMorePAModal startAt={campaignSettings?.startAt} close={() => setDisplayedPAModal(true)}/>}
    {displayModule &&
    <Modules setData={setModuleLevelData} campaignId={campaignId} enterModule={enterModule} quitModule={closeModule}
             paths={paths} opponentProfile={location?.state} location={location}
             moduleClicked={moduleClicked} playerPosition={playerPosition} setModuleClicked={(v) => setModuleClicked(v)}
             data={moduleLevelData} setResult={setResult} lostModule={() => setLostModule(true)}/>
    }
    {
      displayModulesResult && !displayModulesResult.displayPnj &&
      <ModuleResults displayModulesResult={displayModulesResult || []}
                     mobile={mobile}
                     orientation={orientation}
                     moduleClicked={moduleClicked}
                     close={closeModulesResult}/>
    }
    {
      displayModulesResult && displayModulesResult.displayPnj &&
      <ModulePNJResults displayModulesResult={displayModulesResult || []}
                        mobile={mobile}
                        orientation={orientation}
                        moduleClicked={moduleClicked}
                        moduleResult={true}
                        close={closeModulesResult}
                        actionButton={finishModule}
                        finishModule={quitFinishModule}/>
    }
    {
      fragmentModal && <FragmentModal close={() => setFragmentModal(false)} fragments={userState?.fragments || 0}
                                      onClick={discoverFinalDestination}/>
    }
    {
      infoFinalDestination && <InfoFinalDestination close={() => {
        setInfoFinalDestination(false)
        setTimeout(() => {
          makingPlayerFocus()
        }, 3000)
      }}/>
    }
    {
      openedHelper && <HelperModal closeHelper={closeHelper} mobile={mobile} orientation={orientation}/>
    }
    {
      modalFinalDestinationDiscovered &&
      <ModalFinalDestinationDiscovered close={() => setModalFinalDestinationDiscovered(false)}
                                       campaignEndedDate={campaignSettings?.endAt}
                                       campaignSettings={campaignSettings}
                                       actionButton={() => {
                                         updateHeaderDisplayed(true)
                                         return campaignNavigate('leaderboard/player')
                                       }}/>
    }
    {
      campaignEnded &&
      userState?.teamId &&
      !userState?.campaignEndedModalSeen &&
      <CampaignEndedModal visible
                          onClose={() => {
                            updateHeaderDisplayed(true)
                            emitCommand({
                              type: 'SeeCampaignEndedModal',
                              payload: {}
                            })
                          }}
                          replay={async () => {
                            updateHeaderDisplayed(true)
                            await emitCommand({
                              type: 'ReInitializeGameAccount',
                              payload: {}
                            })
                            await emitCommand({
                              type: 'SeeCampaignEndedModal',
                              payload: {}
                            })
                            return window.location.reload()
                          }}
                          mobile={mobile}
                          navRanking={() => {
                            updateHeaderDisplayed(true)
                            emitCommand({
                              type: 'SeeCampaignEndedModal',
                              payload: {}
                            })
                            return campaignNavigate('leaderboard/player')
                          }}
      />
    }
    {
      launchTrip ? <ModalModules onPress={() => setLaunchTrip(null)}
                                 launchModule={launchTrip}
                                 enterModule={enterModule}
                                 actionButton={() => {
                                   updatePlayerPosition(launchTrip.destination, 'trip')
                                   makingPlayerFocus()
                                   setLaunchTrip(null)
                                 }}
                                 color={colors.secondary}
                                 buttonText={<T path={`phase_1.modules.${launchTrip.name}.button`}/>}
                                 module={{name: launchTrip.module}}
                                 title={<T path={`phase_1.modules.${launchTrip.name}.modalTitle`}/>}
        >
          <SmallText style={{fontSize: 15, lineHeight: 20}}>
            <T path={`phase_1.modules.trip.reward.${launchTrip.module}`}/>
          </SmallText>
        </ModalModules>
        : null
    }
    {
      displayModuleModal && <ModalModules closeModal={() => setDisplayModuleModal(false)}
                                          enterModule={enterModule}
                                          challenge={challenge}
                                          buttonText={t('phase_1.modules.play')}
                                          actionButton={() => {
                                            const challenge = campaignSettings?.challenges.includes(moduleClicked.module)
                                            if (challenge) {
                                              campaignNavigate(`challenges/${moduleClicked.module}/${moduleClicked.level}`)
                                            } else {
                                              enterModule()
                                            }
                                            setDisplayModuleModal(false)
                                          }}
                                          playerPosition={playerPosition} module={moduleClicked}
      >
        <ModalTitle>{moduleClicked.module.includes('quiz') ? t('phase_1.modules.quizTitle') : moduleClicked.module}</ModalTitle>
        <WrapperModalContent mobile={mobile}>
          <ModalModuleText mobile={mobile}>
            <T path={`phase_1.modules.${moduleClicked.name}.title`}/>
          </ModalModuleText>
          <ModalModuleText mobile={mobile}>
            <T path={`phase_1.modules.${moduleClicked.name}.explanation`}/>
          </ModalModuleText>
          <ModalModuleText mobile={mobile}>
            {
              moduleTheme ? <T path={`phase_1.modules.${moduleClicked.name}.theme`} data={{0: moduleTheme}}/>
                : <T path={`phase_1.modules.${moduleClicked.name}.themeExplanation`}/>
            }
          </ModalModuleText>
        </WrapperModalContent>
      </ModalModules>
    }
    {
      lostModule && <ModalModules closeModal={() => setLostModule(false)}
                                  enterModule={enterModule}
                                  challenge={challenge}
                                  buttonText={t('phase_1.modules.playAgain')}
                                  color={colors.secondary}
                                  actionButton={() => {
                                    const challenge = campaignSettings?.challenges.includes(moduleClicked.module)
                                    if (challenge) {
                                      setDisplayModuleModal(false)
                                      campaignNavigate(`challenges/${moduleClicked.module}/${moduleClicked.level}`)
                                    } else {
                                      enterModule()
                                    }
                                    setLostModule(false)
                                  }}
                                  playerPosition={playerPosition} module={moduleClicked}
      >
        <LostModuleTitle><T path={`phase_1.lostModules.title`}/></LostModuleTitle>
        <WrapperModalContent>
          <SmallText style={{fontSize: 15, lineHeight: 20}}>
            <T path={`phase_1.lostModules.explanation`}/>
          </SmallText>
        </WrapperModalContent>
      </ModalModules>
    }
    {
      !mapLoading && <WrapperComponent
        mapHeight={screenHeight}
        mapWidth={screenWidth}
        tileWidth={tileWidth}
        tileHeight={tileHeight}
        tilesNumbers={tilesNumbers}
        playerPosition={playerPosition}
        mapArray={mapData}
        playerFocus={playerFocus}
        updateMapMoved={updateMapMove}
        finalDestinationFocus={finalDestinationFocus}
        openFinalDestinationInfo={() => setInfoFinalDestination(true)}
        updatePan={(e) => updatePanValue(e)}
        screenScale={screenScale}
        screenWidth={screenWidth}
        screenHeight={screenHeight}
        mobile={mobile}
        mobileTablet={mobileTablet}
      >
        <MapUi campaignId={campaignId} mapData={mapData}
               playerPosition={playerPosition} rawMapData={rawMapData}
               tileHeight={tileHeight} tilesNumbers={tilesNumbers}
               tileWidth={tileWidth} mapAssets={mapAssets}
               mapState={mapState} playerAsset={playerAsset}
               modulesEnded={moduleTilesEnded}
               tilesMode={tilesMode} userState={userState}
               normalMove={normalMove}
               showPlayer={() => setHidePlayer(false)}
               pawnStatus={hidePlayer}
               openFinalDestinationDiscovered={() => setModalFinalDestinationDiscovered(true)}
               panValue={panValue}
               screenWidth={screenWidth}
               screenHeight={screenHeight}
               mobile={mobile}
               screenScale={screenScale}
               zoomLevel={zoomLevel}
        />
        {
          onMap && <MapOverlay tileWidth={tileWidth} tilesNumbers={tilesNumbers}
                               tileHeight={tileHeight} rawMapData={rawMapData}
                               playerPosition={playerPosition}
                               updatePlayerPosition={(tile, typeTile) => updatePlayerPosition(tile, typeTile)}
                               mapData={mapData}
                               moduleLauncher={(module) => moduleLauncher(module)}
                               tripLauncher={(line, tile, type) => tripLauncher(line, tile, type)}
                               modulesEnded={moduleTilesEnded}
                               previewPlayerAsset={previewPlayerAsset}
                               mapMoved={mapMoved}
                               userState={userState}
                               hidePlayer={() => setHidePlayer(true)}
                               updateMapMoved={updateMapMove}
          />
        }
      </WrapperComponent>
    }
  </Container>
}

const Container = styled.View(({moduleDisplayed}) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  backgroundColor: colors.mapBackground,
  zIndex: moduleDisplayed ? 5 : 1
}))

const ModalModuleText = styled(MediumText)(({mobile}) => ({
  marginBottom: mobile ? 5 : 25
}))
