import {T} from "../../../../translations/translate"
import styled from "@emotion/native"
import {FocusButton} from "@civitime/library/storybook/stories/Button"
import {SmallBoldText} from "@civitime/library/storybook/stories/Texts"
import {colors} from "@civitime/library/storybook/configs/colors"
import React from "react"

export const Focus = ({playerFocus, mobile}) => {
  return (
    <WrapperFocusButton>
      <HomeFocusButton size={30} iconSize={30} onPress={playerFocus}/>
      <FocusText mobile={mobile}><T path='phase_1.locateMe'/></FocusText>
    </WrapperFocusButton>
  )
}

const WrapperFocusButton = styled.View({
  alignItems: 'center',
})

const HomeFocusButton = styled(FocusButton)({
  marginBottom: 5,
  width: 30
})

const FocusText = styled(SmallBoldText)(({mobile}) => ({
  color: colors.lightText,
  fontSize: mobile ? 10 : 12
}))
