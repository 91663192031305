import React, { createContext, useContext, useEffect, useState } from 'react'
import { authClient, jsonFetch }                                 from './authClient'
import { env }                                                   from '../configs/env'
import { useCampaign }                                           from '../GlobalContexts'
import { clientId }                                              from '../../clientId'
import { useRealTimeData }                                       from '../Utils/dataClient'
import { canEndChallenge }                                       from '@civitime/game-server/lib/Domain/Phases/phase1/Challenges/Challenges.helpers'
import CryptoJS from "react-native-crypto-js";

const defaultValue = {
  loggedIn: false,
  loading: true,
}
const ProfileContext = createContext(defaultValue)

export const Profile = ({ user, children }) => {
  const [profile, setProfile] = useState(defaultValue)
  const { campaignId, campaignSettings } = useCampaign()
  const userState = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/states/${user?.userId}`
  )
  const technicalProfile = useRealTimeData(`users/${user?.userId}`)
  const publicProfile = useRealTimeData(
    `clients/${clientId}/profiles/${user?.userId}`
  )
  const userSession = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/sessions/${user?.userId}`
  )
  const notifications = filterChallenges(userSession?.challenges, campaignSettings?.challengeEndDate)
  

  useEffect(() => {
    let lms, token;
    if(window.location.search.match(/\?lms=(\w+)&token=(\w+)/gmi)) {
      [lms, token] = window.location.search.split('?lms=')[1].split("&token=");
      let bytes  = CryptoJS.AES.decrypt(decodeURIComponent(token), 'A779E051797812095A9255ADE46850002D15CE235BA3C30C');
      token = bytes.toString(CryptoJS.enc.Utf8);
    }
    if(!user.userId) return setProfile({ ...defaultValue, lmsData: { lms , token }});
    setProfile({
      ...user,
      userState: userState,
      technicalProfile: technicalProfile,
      publicProfile: publicProfile,
      notifications: notifications,
      userSession: userSession,
      lmsData: { lms , token },
      loading:
        !userState?.loaded &&
        !technicalProfile?.loaded &&
        !publicProfile?.loaded,
      emitCommand: (rawCommand) =>
        emitCommand(rawCommand, user?.userId, campaignId),
    })
  }, [user, user?.userId, user.token, technicalProfile, publicProfile, userState, userSession, notifications])

  return (
    <ProfileContext.Provider value={profile}>
      {children}
    </ProfileContext.Provider>
  )
}

export const Auth = ({ children }) => {
  const [user, setUser] = useState({})
  useEffect(() => {
    const sub = authClient.authState$.subscribe((authState) => {
      setUser({
        loggedIn: authState !== null,
        token: authState?.token,
        userId: authState?.id,
        technicalProfile: null,
        publicProfile: null,
        userState: null,
        loading: false,
      })
    })
    return () => {
      sub.unsubscribe()
    }
  }, [])

  return <Profile user={user}>{children}</Profile>
}

export const useProfile = () => {
  return useContext(ProfileContext)
}

export const pushCommand = async (command) => {
  return jsonFetch((env.GAME_SERVER_URI) + '/commands', {
    body: JSON.stringify(command),
    method: 'POST',
  })
}

const emitCommand = async (rawCommand, userId, campaignId) => {
  if (!rawCommand || !userId || !campaignId) return
  const command = {
    ...rawCommand,
    payload: {
      ...rawCommand?.payload,
      emitter: userId,
      campaignId: campaignId,
      clientId: clientId,
    },
  }
  return await pushCommand(command)
}

const filterChallenges = (challenges, challengeEndDate) => {
  const challengesArray = Object.entries(challenges || []).map(([key, value]) => ({ ...value, challengeId: key }))
  const seeAbleChallenges = challengesArray.filter(
    (challenge) => (challenge.status === 'ended' &&
      (challenge?.opponentResult || challenge?.opponentResult === 0)
      || canEndChallenge(challenge.timeStamp, challengeEndDate) && challenge.status === 'ended')
  ).length
  const playableChallenges = challengesArray.filter(challenge => challenge.status === 'started').length
  return playableChallenges + seeAbleChallenges
}
