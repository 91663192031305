import React, {useEffect, useState} from 'react'
import MiniMap from "@civitime/library/assets/phase1/mini-map.svg"
import styled from "@emotion/native"
import {colors} from "@civitime/library/storybook/configs/colors"

import {useProfile} from "../../../Auth/useProfile"
import {Animated} from "react-native"
import {Focus} from "./AppSkeleton/FocusButton"

const maxMapWidth = 12000
const maxMapHeight = 6960

export const MapButtonsAppSkeleton = ({onMap, returnToMap, playerFocus, mobile, updateZoom, zoomLevel, panValue, screenWidth, screenHeight, screenScale}) => {
  const {userState} = useProfile()
  const [zoomButtonHoveredLeft, setZoomButtonHoveredLeft] = useState(null)
  const [zoomButtonHoveredRight, setZoomButtonHoveredRight] = useState(null)
  const [pulseAnimation1] = useState(new Animated.Value(1))
  const [pulseAnimation2] = useState(new Animated.Value(1))
  const [pulseAnimation3] = useState(new Animated.Value(1))

  const launchPulse1 = () => {
    Animated.sequence([
      Animated.timing(pulseAnimation1, {toValue: 2.5, duration: 1000, useNativeDriver: true}),
      Animated.timing(pulseAnimation1, {toValue: 0, duration: 300, useNativeDriver: true}),
    ]).start(() => launchPulse1())
  }

  const launchPulse2 = () => {
    Animated.sequence([
      Animated.timing(pulseAnimation2, {toValue: 2, duration: 1000, useNativeDriver: true}),
      Animated.timing(pulseAnimation2, {toValue: 0, duration: 300, useNativeDriver: true}),
    ]).start(() => launchPulse2())
  }

  const launchPulse3 = () => {
    Animated.sequence([
      Animated.timing(pulseAnimation3, {toValue: 1.5, duration: 1000, useNativeDriver: true}),
      Animated.timing(pulseAnimation3, {toValue: 0, duration: 300, useNativeDriver: true}),
    ]).start(() => launchPulse3())
  }

  useEffect(() => {
    launchPulse1()
    setTimeout(() => {
      launchPulse2()
      setTimeout(() => launchPulse3(), 500)
    }, 500)
  }, [])

  return <WrapperMapButtons mobile={mobile}>
    <WrapperButtonMap>
      {onMap && !returnToMap && <Focus {...{playerFocus}} mobile={mobile}/>}
      {
        onMap &&
        !returnToMap && <WrapperZoomButtons mobile={mobile}>
          <ButtonZoom leftButton
                      zoomButtonHovered={zoomButtonHoveredLeft}
                      onMouseEnter={() => setZoomButtonHoveredLeft(true)}
                      onMouseLeave={() => setZoomButtonHoveredLeft(null)}
                      onPress={() => updateZoom('downScale')}
                      disabled={zoomLevel <= 1}
                      mobile={mobile}>
            <ZoomText mobile={mobile} disabled={zoomLevel <= 1} hover={zoomButtonHoveredLeft}>-</ZoomText>
          </ButtonZoom>
          <TextZoom mobile={mobile}>Zoom</TextZoom>
          <ButtonZoom onPress={() => updateZoom('upScale')}
                      zoomButtonHovered={zoomButtonHoveredRight}
                      onMouseEnter={() => setZoomButtonHoveredRight(true)}
                      onMouseLeave={() => setZoomButtonHoveredRight(null)}
                      disabled={zoomLevel >= 3}
                      mobile={mobile}>
            <ZoomText mobile={mobile} disabled={zoomLevel >= 3} hover={zoomButtonHoveredRight}>+</ZoomText>
          </ButtonZoom>
        </WrapperZoomButtons>
      }
    </WrapperButtonMap>
    {
      onMap && !returnToMap && !mobile &&
      <WrapperMiniMap>
        <WrapperPlayerPointer playerPosition={userState?.map?.playerPosition}
                              mapState={JSON.parse(userState?.map?.fogMap)}>
          <PlayerPointer/>
          <PlayerPulsePointer pulseAnimation1={pulseAnimation1}/>
          <PlayerPulsePointer pulseAnimation1={pulseAnimation2}/>
          <PlayerPulsePointer pulseAnimation1={pulseAnimation3}/>
        </WrapperPlayerPointer>
        <ScreenRectangleView width={(((screenWidth) * 100)) / (maxMapWidth * screenScale)}
                             height={(((screenHeight) * 100)) / (maxMapHeight * screenScale)}
                             position={{
                               top: (((Math.abs(panValue.y > 0 ? 0 : panValue.y)) * 100)) / (maxMapHeight * screenScale),
                               left: ((Math.abs(panValue.x > 0 ? 0 : panValue.x) * 100)) / (maxMapWidth * screenScale),
                             }}/>
        <MiniMap height={'100%'} width={'100%'}/>
      </WrapperMiniMap>
    }
  </WrapperMapButtons>
}

const ScreenRectangleView = styled.View(({width, height, position}) => ({
  position: 'absolute',
  top: `${position.top}%`,
  left: `${position.left}%`,
  width: `${width}%`,
  height: `${height}%`,
  borderWidth: 2,
  borderColor: colors.pulseOrange
}))

const WrapperPlayerPointer = styled.View(({playerPosition, mapState}) => ({
  position: 'absolute',
  top: `${(90 * ((playerPosition?.line * 100) / 40) / 100) + 7}%`,
  left: `${(75 * (((playerPosition?.tile + (20 - mapState?.[playerPosition?.line].length) / 2) * 100) / 20) / 100) + 10}%`,
  transform: [
    {translateX: `${(75 * (((playerPosition?.tile + (20 - mapState?.[playerPosition?.line].length) / 2) * 100) / 20) / 100) + 10}%`},
    {translateY: `-${(90 * ((playerPosition?.line * 100) / 40) / 100) + 7}%`},
  ]
}))

const PlayerPulsePointer = styled(Animated.View)(({pulseAnimation1}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 10,
  height: 10,
  borderRadius: 10,
  backgroundColor: colors.pulseOrange,
  opacity: .4,
  transform: [{scale: pulseAnimation1}]
}))

const PlayerPointer = styled.View({
  width: 10,
  height: 10,
  borderRadius: 10,
  backgroundColor: colors.pulseOrange,
})

const WrapperMapButtons = styled.View(({mobile}) => ({
  position: 'fixed',
  bottom: '5%',
  left: '3%',
  flexDirection: 'col',
  alignItems: 'center',
  width: mobile ? 'unset' : 270,
  height: mobile ? 'unset' : 176
}))

const WrapperButtonMap = styled.View(({}) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-around',
  width: '100%'
}))

const WrapperMiniMap = styled.View({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  alignItems: 'center',
  borderRadius: 9,
  paddingRight: 10,
  paddingLeft: 10,
  paddingTop: 2,
  paddingBottom: 2,
  backgroundColor: colors.mapBackground,
  boxShadow: "inset 1px 1px 3px rgba(11, 67, 94, 0.692), inset -1px -1px 3px rgba(255, 255, 255, 0.4)"
})

const WrapperZoomButtons = styled.View(({mobile}) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: mobile ? 0 : 30,
  marginBottom: mobile ? 30 : 0,
  position: mobile ? 'fixed' : 'relative',
  bottom: mobile ? '2%' : 'unset',
  left: mobile ? '50%' : 'unset',
  transform: mobile ? [{translateX: '-50%'}] : 'none'
}))

const TextZoom = styled.Text(({mobile}) => ({
  textTransform: 'uppercase',
  padding: mobile ? 5 : 6,
  fontSize: mobile ? 10 : 13,
  alignSelf: 'center',
  color: colors.dropShadow,
  fontWeight: 'bold',
  backgroundColor: colors.defaultBackground
}))

const ButtonZoom = styled.TouchableOpacity(({leftButton, mobile, zoomButtonHovered, disabled}) => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: mobile ? 25 : 31,
  height: mobile ? 25 : 31,
  borderTopLeftRadius: leftButton ? 15 : 0,
  borderBottomLeftRadius: leftButton ? 15 : 0,
  borderTopRightRadius: !leftButton ? 15 : 0,
  borderBottomRightRadius: !leftButton ? 15 : 0,
  borderColor: disabled ? colors.defaultBackground : colors.module,
  borderWidth: 2,
  backgroundColor: disabled ? colors.defaultBackground : zoomButtonHovered ? colors.module : colors.white,
}))

const ZoomText = styled.Text(({mobile, hover, disabled}) => ({
  color: disabled ? colors.white : hover ? colors.white : colors.module,
  fontSize: mobile ? 20 : 30,
  fontWeight: 'bold',
  transform: [{translateY: -3}],
  lineHeight: 0,
}))
