import React, { useEffect, useState }      from 'react'
import { Route, Switch }                   from 'react-router-native'
import { brand as isMobileApp }            from 'expo-device'
import styled                              from '@emotion/native'
import { Login }                           from './screens/Login'
import { Auth }                            from './screens/Auth'
import { Signup }                          from './screens/Signup'
import { ResetPassword }                   from './screens/ResetPassword'
import { useProfile }                      from './useProfile'
import { getCampaignId, useCampaign }      from '../GlobalContexts'
import { colors }                          from '@civitime/library/storybook/configs/colors'
import { ChangePassword }                  from './screens/ChangePassword'
import { useLocation }                     from 'react-router'
import { LangSelectorModal }               from '../translations/LangSelectorModal'
import { firebaseAccessor }                from '../firebase'
import { clientId }                        from '../../clientId'
import storage                             from 'react-native-modest-storage'
import { authClient, getCampaignIdStored } from './authClient'
import { history }                         from '../Router/history.web'

export const AuthFilter = ({ children, screenWidth, screenHeight, mobile }) => {
  const { loggedIn } = useProfile()
  const { basePath, campaignNavigate } = useCampaign()
  const location = useLocation()
  const [langSelectorOpened, setLangSelectorOpened] = useState(false)
  const [campaignSettings, setCampaignSettings] = useState(null)
  const openLangSelector = () => setLangSelectorOpened(true)
  const backgroundImg =
    isMobileApp && orientation === 'portrait'
      ? require('../../assets/Auth/fd-mobil-ok.png')
      : require('../../assets/Auth/fd-desk-ok.png')
  
  const getClientSettings = async (clientId) => {
    return setCampaignSettings(await firebaseAccessor.get(`clients/${clientId}/settings`))
  }
  const campaignId = getCampaignId()
  const setCampaignId = async () => {
    if (!campaignId) return
    return await storage.set('campaignId', campaignId)
  }
  useEffect(() => {
    setCampaignId()
  }, [campaignId])
  
  const storageCampaignId = getCampaignIdStored()
  
  useEffect(() => {
    ;(async () => {
      const campaignIdStorage = await getCampaignIdStored()
      if (campaignId !== campaignIdStorage) {
        history.push(`/${campaignIdStorage}`)
        window.location.reload()
      }
    })()
  }, [])
  
  useEffect(() => {
    if (!loggedIn) return
    loggedIn && !location.pathname.includes('challenges') && campaignNavigate('')
  }, [loggedIn])
  
  useEffect(() => {
    getClientSettings(clientId)
  }, [clientId])
  
  return loggedIn ? (
    <>{children}</>
  ) : (
    <Background source={backgroundImg} resizeMode="center" screenWidth={screenWidth} screenHeight={screenHeight}>
      {langSelectorOpened && <LangSelectorModal closeSelector={() => setLangSelectorOpened(false)}/>}
      <Switch>
        <Route path={`${basePath}/login`} exact>
          <Login mobile={mobile} openLangSelector={openLangSelector} campaignSettings={campaignSettings}/>
        </Route>
        <Route path={`${basePath}/signup`}>
          <Signup mobile={mobile} openLangSelector={openLangSelector} campaignSettings={campaignSettings}/>
        </Route>
        <Route path={`${basePath}/forgot-password`} exact>
          <ResetPassword openLangSelector={openLangSelector} campaignSettings={campaignSettings}/>
        </Route>
        <Route path={`${basePath}/reset-password`}>
          <ChangePassword openLangSelector={openLangSelector} campaignSettings={campaignSettings}/>
        </Route>
        <Route path={basePath}>
          <Auth openLangSelector={openLangSelector} campaignSettings={campaignSettings}/>
        </Route>
      </Switch>
    </Background>
  )
}

const Background = styled.ImageBackground(({ screenWidth, screenHeight, mobile }) => ({
  flex: 1,
  flexDirection: 'row',
  width: screenWidth,
  height: screenHeight,
  alignItems: 'center',
  justifyContent: mobile ? 'center' : 'unset',
  backgroundColor: colors.lightBackground,
  overflow: 'hidden'
}))


