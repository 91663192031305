import React, {useEffect, useState} from "react"
import {View} from "react-native"
import {Route, Switch} from "react-router-native"
import {useCampaign} from "../GlobalContexts"
import {useProfile} from "../Auth/useProfile"
import {AppSkeleton} from "./AppSkeleton"
import {Settings} from "../Settings/Settings"
import {Avatar} from "../Avatar/Avatar"
import {AvatarHomeSelection} from "../Avatar/AvatarHomeSelection"
import {RankingTabs} from "../Leaderboards/pages/RankingTabs"
import styled from "@emotion/native"
import {LangSelectorModal} from '../translations/LangSelectorModal'
import {useTranslator} from '../translations/translate'
import {Home} from "./Home"
import {Phase1Router} from "../Phases/Phase1/Components/Router/Phase1Router"
import {authClient, getCampaignIdStored} from "../Auth/authClient"
import {history} from "../Router/history.web"

export const AppRouter = () => {
  const {basePath, campaignSettings, campaignId} = useCampaign()
  const {lang} = useTranslator()
  const {emitCommand, userState, loading, publicProfile} = useProfile()
  const [serveDimensions, setServeDimensions] = useState({})
  const [langSelectorOpened, setLangSelectorOpened] = useState(false)
  const [phaseProps, setPhaseProps] = useState({})

  useEffect(() => {
    if (!loading && !userState) {
      emitCommand({
        type: "JoinCampaign",
        payload: {
          lang: lang,
        },
      })
    }
  }, [userState, loading])

  useEffect(() => {
    emitCommand({type: "OpenApp"})
  }, [])

  useEffect(() => {
    ;(async () => {
      const campaignIdStorage = await getCampaignIdStored()
      if(!campaignIdStorage) return authClient.logout()
      if (campaignId !== campaignIdStorage) {
        history.push(`/${campaignIdStorage}`)
        window.location.reload()
      }
    })()
  }, [campaignId])

  return publicProfile ? (
    <AppSkeleton
      phase={campaignSettings?.phase}
      openLangSelector={() => setLangSelectorOpened(true)}
      serveDimensions={(d) => {
        setServeDimensions((e) => ({...e, ...d}))
      }}
      {...phaseProps}
    >
      {
        langSelectorOpened &&
        <LangSelectorModal closeSelector={() => setLangSelectorOpened(false)}/>
      }
      <View style={{zIndex: 3, alignItems: "center"}}>
        <Switch>
          <Route path={`${basePath}/settings`}>
            <Settings/>
          </Route>
          <Route path={`${basePath}/avatar/update`}>
            <AvatarHomeSelection/>
          </Route>
          <Route path={`${basePath}/avatar`}>
            <Avatar {...phaseProps}/>
          </Route>
          <Route path={`${basePath}/leaderboard`}>
            <RankingTabs/>
          </Route>
          <Route path={`${basePath}`}>
            <Home phase={campaignSettings?.phase}/>
          </Route>
          <Route
            path={`${basePath}/wait`}
            component={() => (
              <WaitingScreen
                screenHeight={serveDimensions.width}
                screenWidth={serveDimensions.height}
              />
            )}
          />
        </Switch>
        {
          campaignSettings?.phase === 1 &&
          <Phase1Router basePath={basePath}
                        phase={campaignSettings?.phase}
                        serveDimensions={serveDimensions}
                        setPhaseProps={setPhaseProps}/>
        }
      </View>
    </AppSkeleton>
  ) : null
}

const WaitingScreen = styled.View(({screenHeight, screenWidth}) => ({
  width: screenWidth,
  height: screenHeight,
  zIndex: 300,
}))
