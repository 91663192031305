export default {
  "v": "5.5.8",
  "fr": 25,
  "ip": 0,
  "op": 100,
  "w": 1200,
  "h": 970,
  "nm": "tile-arrive",
  "ddd": 0,
  "assets": [],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 4,
    "nm": "Calque de forme 4",
    "sr": 1.5,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 45,
          "s": [0]
        }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 60, "s": [20]}, {
          "t": 75,
          "s": [0]
        }],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [600, 502.057, 0], "ix": 2},
      "a": {"a": 0, "k": [0, 52.057, 0], "ix": 1},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 45,
          "s": [15, 15, 100]
        }, {
          "i": {"x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833]},
          "o": {"x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167]},
          "t": 67.5,
          "s": [1253.684, 1253.684, 100]
        }, {"t": 73.5, "s": [1389.684, 1389.684, 100]}],
        "ix": 6
      }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "d": 1,
        "ty": "el",
        "s": {"a": 0, "k": [50.819, 50.819], "ix": 2},
        "p": {"a": 0, "k": [0, 0], "ix": 3},
        "nm": "Tracé d'ellipse 1",
        "mn": "ADBE Vector Shape - Ellipse",
        "hd": false
      }, {
        "ty": "fl",
        "c": {"a": 0, "k": [1, 0.564705882353, 0, 1], "ix": 4},
        "o": {"a": 0, "k": 100, "ix": 5},
        "r": 1,
        "bm": 0,
        "nm": "Fond 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": {"a": 0, "k": [0.109, 51.156], "ix": 2},
        "a": {"a": 0, "k": [0, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100], "ix": 3},
        "r": {"a": 0, "k": 0, "ix": 6},
        "o": {"a": 0, "k": 100, "ix": 7},
        "sk": {"a": 0, "k": 0, "ix": 4},
        "sa": {"a": 0, "k": 0, "ix": 5},
        "nm": "Transformer "
      }],
      "nm": "Ellipse 1",
      "np": 3,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }],
    "ip": 0,
    "op": 75,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 2,
    "ty": 4,
    "nm": "Calque de forme 3",
    "sr": 1.5,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 22.5,
          "s": [0]
        }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 45, "s": [40]}, {
          "t": 75,
          "s": [0]
        }],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [600, 502.057, 0], "ix": 2},
      "a": {"a": 0, "k": [0, 52.057, 0], "ix": 1},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 22.5,
          "s": [15, 15, 100]
        }, {"t": 75, "s": [1584, 1584, 100]}],
        "ix": 6
      }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "d": 1,
        "ty": "el",
        "s": {"a": 0, "k": [50.819, 50.819], "ix": 2},
        "p": {"a": 0, "k": [0, 0], "ix": 3},
        "nm": "Tracé d'ellipse 1",
        "mn": "ADBE Vector Shape - Ellipse",
        "hd": false
      }, {
        "ty": "fl",
        "c": {"a": 0, "k": [1, 0.564705882353, 0, 1], "ix": 4},
        "o": {"a": 0, "k": 100, "ix": 5},
        "r": 1,
        "bm": 0,
        "nm": "Fond 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": {"a": 0, "k": [0.109, 51.156], "ix": 2},
        "a": {"a": 0, "k": [0, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100], "ix": 3},
        "r": {"a": 0, "k": 0, "ix": 6},
        "o": {"a": 0, "k": 100, "ix": 7},
        "sk": {"a": 0, "k": 0, "ix": 4},
        "sa": {"a": 0, "k": 0, "ix": 5},
        "nm": "Transformer "
      }],
      "nm": "Ellipse 1",
      "np": 3,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }],
    "ip": 0,
    "op": 75,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 3,
    "ty": 4,
    "nm": "Calque de forme 2",
    "sr": 1.5,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {"x": [0.833], "y": [0.833]},
          "o": {"x": [0.167], "y": [0.167]},
          "t": 0,
          "s": [0]
        }, {"i": {"x": [0.833], "y": [0.833]}, "o": {"x": [0.167], "y": [0.167]}, "t": 22.5, "s": [40]}, {
          "t": 45,
          "s": [0]
        }],
        "ix": 11
      },
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [600, 502.057, 0], "ix": 2},
      "a": {"a": 0, "k": [0, 52.057, 0], "ix": 1},
      "s": {
        "a": 1,
        "k": [{
          "i": {"x": [0.667, 0.667, 0.667], "y": [1, 1, 1]},
          "o": {"x": [0.333, 0.333, 0.333], "y": [0, 0, 0]},
          "t": 0,
          "s": [15, 15, 100]
        }, {"t": 45, "s": [1838, 1838, 100]}],
        "ix": 6
      }
    },
    "ao": 0,
    "shapes": [{
      "ty": "gr",
      "it": [{
        "d": 1,
        "ty": "el",
        "s": {"a": 0, "k": [50.819, 50.819], "ix": 2},
        "p": {"a": 0, "k": [0, 0], "ix": 3},
        "nm": "Tracé d'ellipse 1",
        "mn": "ADBE Vector Shape - Ellipse",
        "hd": false
      }, {
        "ty": "fl",
        "c": {"a": 0, "k": [1, 0.564705882353, 0, 1], "ix": 4},
        "o": {"a": 0, "k": 100, "ix": 5},
        "r": 1,
        "bm": 0,
        "nm": "Fond 1",
        "mn": "ADBE Vector Graphic - Fill",
        "hd": false
      }, {
        "ty": "tr",
        "p": {"a": 0, "k": [0.109, 51.156], "ix": 2},
        "a": {"a": 0, "k": [0, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100], "ix": 3},
        "r": {"a": 0, "k": 0, "ix": 6},
        "o": {"a": 0, "k": 100, "ix": 7},
        "sk": {"a": 0, "k": 0, "ix": 4},
        "sa": {"a": 0, "k": 0, "ix": 5},
        "nm": "Transformer "
      }],
      "nm": "Ellipse 1",
      "np": 3,
      "cix": 2,
      "bm": 0,
      "ix": 1,
      "mn": "ADBE Vector Group",
      "hd": false
    }],
    "ip": 0,
    "op": 75,
    "st": 0,
    "bm": 0
  }],
  "markers": []
}
