import React, {useState} from 'react'
import { ModalModules }         from './ModulesModal'
import { ModuleResultsContent } from './ModulesResults'
import styled                   from '@emotion/native'
import { useCampaign }          from '../../../../../GlobalContexts'
import { MediumText }           from '@civitime/library/storybook/stories/Texts'
import { useTranslator }        from '../../../../../translations/translate'
import { UseLinkInText }        from '@civitime/duel-quiz/src/useLinkInText'
import {useProfile} from "../../../../../Auth/useProfile"

export const ModulePNJResults = ({
  displayModulesResult, moduleClicked, closeModulesResult, finishModule, actionButton,
  mobile, orientation, moduleResult
}) => {
  const { campaignId, campaignSettings } = useCampaign()
  const { emitCommand } = useProfile()
  const [timeStampStart] = useState(new Date())
  const { content } = useTranslator()
  const { t } = useTranslator()
  const modulesTranslations = content?.[moduleClicked?.module]?.[moduleClicked?.level]?.[`${moduleClicked.level}.outro`]
  const trads = campaignSettings?.internationalizedContent ? null : require(`../../../../../translations/${campaignId}/translations.json`)
  const moduleName = moduleClicked?.name
  return <ModalModules module={displayModulesResult?.moduleClicked}
                       moduleResult={moduleResult}
                       closeModal={() => {
                         if(!(moduleName === 'hoot')){
                           finishModule(displayModulesResult?.won?.win)
                           emitCommand({
                             type: 'ModalEndedPNJ',
                             payload: {
                               timeStampStart: timeStampStart,
                               timeStampEnd: new Date(),
                               module: moduleClicked
                             }
                           })
                         }
                       }}
                       mobile={mobile}
                       orientation={orientation}
                       title={t(`phase_1.modulesResult.${moduleName}.title`)}
                       result={moduleResult}
                       actionButton={() => {
                         actionButton(displayModulesResult?.won?.win)
                         emitCommand({
                           type: 'ModalEndedPNJ',
                           payload: {
                             timeStampStart: timeStampStart,
                             timeStampEnd: new Date(),
                             module: moduleClicked
                           }
                         })
                       }}
                       buttonText={t(`phase_1.modulesResult.${moduleName}.actionButton`)}>
    <ModuleExplanation>
      <Explanation>
        {
          campaignSettings.internationalizedContent ? <UseLinkInText text={modulesTranslations}/>
            : <UseLinkInText text={trads?.fr?.app?.modules?.[moduleClicked?.module]?.endedScreen?.[moduleClicked?.level]}/>
        }
      </Explanation>
    </ModuleExplanation>
    <ModuleResultsContent displayModulesResult={displayModulesResult}
                          moduleClicked={moduleClicked}
                          close={closeModulesResult}
                          finishModule={finishModule}
                          mobile={mobile}
                          orientation={orientation}
                          pnj={true}
    />
  </ModalModules>
}

const ModuleExplanation = styled(MediumText)({
  zIndex: 1000
})

const Explanation = styled.Text({})
