import React from "react"
import { useRealTimeData } from "../../Utils/dataClient"
import { clientId } from "../../../clientId"
import { useProfile } from "../../Auth/useProfile"

import { TopRanking } from "./TopRanking"
import { TopRankingWrapper } from "./Containers"
import { useCampaign } from "../../GlobalContexts"

export const TopPlayerRanking = ({
  data,
  mobile,
  orientation,
  pressTeam,
  ...props
}) => {
  const { publicProfile } = useProfile()
  const { campaignId } = useCampaign()

  const firstPlayer = useRealTimeData(
    `clients/${clientId}/profiles/${data[0]?.playerId}`
  )
  const secondPlayer = useRealTimeData(
    `clients/${clientId}/profiles/${data[1]?.playerId}`
  )
  const thirdPlayer = useRealTimeData(
    `clients/${clientId}/profiles/${data[2]?.playerId}`
  )

  const firstPlayerState = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/states/${data[0]?.playerId}`
  )
  const secondPlayerState = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/states/${data[1]?.playerId}`
  )
  const thirdPlayerState = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/states/${data[2]?.playerId}`
  )

  return (
    <TopRankingWrapper {...{ mobile, orientation }} {...props}>
      {secondPlayer && (
        <TopRanking
          type="player"
          data={{ ...secondPlayer, points: data[1]?.score }}
          rank={2}
          me={publicProfile.id === secondPlayer.id}
          onPress={() => pressTeam(secondPlayerState.teamId)}
          {...{ mobile, orientation }}
        />
      )}

      {firstPlayer && (
        <TopRanking
          type="player"
          data={{ ...firstPlayer, points: data[0]?.score }}
          rank={1}
          me={publicProfile.id === firstPlayer.id}
          onPress={() => pressTeam(firstPlayerState.teamId)}
          {...{ mobile, orientation }}
        />
      )}

      {thirdPlayer && (
        <TopRanking
          type="player"
          data={{ ...thirdPlayer, points: data[2]?.score }}
          rank={3}
          me={publicProfile.id === thirdPlayer.id}
          onPress={() => pressTeam(thirdPlayerState.teamId)}
          {...{ mobile, orientation }}
        />
      )}
    </TopRankingWrapper>
  )
}
