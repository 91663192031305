import React, {useEffect, useMemo, useRef} from 'react'
import styled from "@emotion/native"
import {Animated, PanResponder} from "react-native"

const limitBorderBack = 0
const limitBorderLock = 0

export const WrapperComponent = ({
                                   children, tileWidth,
                                   tileHeight, tilesNumbers,
                                   playerPosition, mapArray,
                                   playerFocus,
                                   updateMapMoved, finalDestinationFocus,
                                   openFinalDestinationInfo,
                                   updatePan, screenScale,
                                   screenWidth, screenHeight,
                                   mobile, mobileTablet,
                                 }) => {

  const pan = useRef(new Animated.ValueXY()).current

  useEffect(() => {
    if (finalDestinationFocus) {
      const destinationFinalX = -((((tilesNumbers * tileWidth) * screenScale) / 2) - (screenWidth / 2))
      const destinationFinalY = -((((tilesNumbers * tileHeight) * screenScale) / 2) - (screenHeight / 2))
      const newPan = {
        x: destinationFinalX,
        y: destinationFinalY
      }
      pan.setValue(newPan)
      updatePan(newPan)
      openFinalDestinationInfo()
    }
  }, [finalDestinationFocus, tileHeight, tileWidth, tilesNumbers, screenScale, screenWidth, screenHeight])

  useEffect(() => {
    if (!pan) return
    const playerPosXReBase = Math.ceil(playerPosition.tile + (tilesNumbers - mapArray?.[playerPosition.line]?.length) / 2)
    const posY = -(((playerPosition.line * (tileHeight * screenScale)) / 2) - (screenHeight / 2))
    const posX = -((playerPosXReBase) * (tileWidth * screenScale) - ((screenWidth / 2) - ((playerPosition.line % 2 !== 0) ? (((tileWidth * screenScale) / 2)) : 0)))
    const newPan = {
      x: posX,
      y: posY
    }
    pan.setValue(newPan)
    updatePan(newPan)
  }, [pan, tileHeight, tileWidth, playerFocus, screenScale])

  const panResponder = useMemo(
    () => PanResponder.create({
      onStartShouldSetPanResponderCapture: (e, gestureState) => {
        return updateMapMoved(true)
      },
      onMoveShouldSetPanResponder: (event, gestureState) => {
        // return true

        if (mobile) return true
        //LEGACY camera bugs
        if (mobileTablet &&
          (((gestureState.dy > 20 && gestureState.dx > 1) ||
            (gestureState.dy > 20 && gestureState.dx < -1)) ||
            ((gestureState.dy < -20 && gestureState.dx > 1) ||
              (gestureState.dy < -20 && gestureState.dx < -1)) ||
            ((gestureState.dx > 20 && gestureState.dy > 1) ||
              (gestureState.dx > 20 && gestureState.dy < -1)) ||
            ((gestureState.dx < -20 && gestureState.dy > 1) ||
              (gestureState.dx < -20 && gestureState.dy < -1)) ||
            (gestureState.dy > 20 && gestureState.dx < -20) ||
            (gestureState.dy > 20 && gestureState.dx > 20) ||
            (gestureState.dy < -20 && gestureState.dx < -20) ||
            (gestureState.dy < -20 && gestureState.dx > 20))
        ) {
          return true
        }
        if (
          ((gestureState.dy > 30 && gestureState.dx > 1) ||
            (gestureState.dy > 30 && gestureState.dx < -1)) ||
          ((gestureState.dy < -30 && gestureState.dx > 1) ||
            (gestureState.dy < -30 && gestureState.dx < -1)) ||
          ((gestureState.dx > 30 && gestureState.dy > 1) ||
            (gestureState.dx > 30 && gestureState.dy < -1)) ||
          ((gestureState.dx < -30 && gestureState.dy > 1) ||
            (gestureState.dx < -30 && gestureState.dy < -1)) ||
          (gestureState.dy > 30 && gestureState.dx < -30) ||
          (gestureState.dy > 30 && gestureState.dx > 30) ||
          (gestureState.dy < -30 && gestureState.dx < -30) ||
          (gestureState.dy < -30 && gestureState.dx > 30)
        ) {
          return true
        }
        return false
      },
      onPanResponderGrant: (e, gestureState) => {
        pan.setOffset({
          x: pan.x._value,
          y: pan.y._value
        })
      },
      onPanResponderMove: (event, gestureState) => {
        return Animated.event(
          [
            null,
            {dx: pan.x, dy: pan.y}
          ], {
            listener: (e) => {
            },
            // useNativeDriver: true,
          }
        )(event, gestureState)
      },
      onPanResponderRelease: (e, gestureState) => {
        pan.flattenOffset()
        updatePan(pan.__getValue())
      },
      onPanResponderEnd: (e, gestureState) => {
        updateMapMoved(false)
        const maxTiles = 20
        const maxWidth = ((600 * screenScale) * maxTiles)
        const maxHeight = ((380 * screenScale) * maxTiles)
        const limitInfX = (maxWidth - screenWidth) > 0 ? -(maxWidth - screenWidth) : maxWidth - screenWidth
        const limitInfY = (maxHeight - screenHeight) > 0 ? -(maxHeight - screenHeight) : maxHeight - screenHeight
        const screenPosition = pan.__getValue()
        if (screenPosition.x > limitBorderLock && screenPosition.y > limitBorderLock) {
          Animated.spring(
            pan,
            {
              toValue: {x: limitBorderBack, y: limitBorderBack},
              restSpeedThreshold: 500,
              restDisplacementThreshold: 40
            }
          ).start(() => updatePan({x: limitBorderBack, y: limitBorderBack}))
        }
        if (screenPosition.x < limitInfX && screenPosition.y < limitInfY) {
          Animated.spring(
            pan,
            {
              toValue: {x: Math.abs(limitInfX), y: limitInfY},
              restSpeedThreshold: 500,
              restDisplacementThreshold: 40
            }
          ).start(() => updatePan({x: Math.abs(limitInfX), y: limitInfY}))
        }
        if (screenPosition.x > limitBorderLock) {
          Animated.spring(
            pan,
            {
              toValue: {x: limitBorderBack, y: pan.y._value},
              restSpeedThreshold: 500,
              restDisplacementThreshold: 40
            }
          ).start(() => updatePan({x: limitBorderBack, y: pan.y._value}))
        }
        if (screenPosition.y > limitBorderLock) {
          Animated.spring(
            pan,
            {
              toValue: {x: pan.x._value, y: limitBorderBack},
              restSpeedThreshold: 500,
              restDisplacementThreshold: 40
            }
          ).start(() => updatePan({x: pan.x._value, y: limitBorderBack}))
        }
        if (screenPosition.x < limitInfX) {
          Animated.spring(
            pan,
            {
              toValue: {x: limitInfX, y: pan.y._value},
              restSpeedThreshold: 500,
              restDisplacementThreshold: 40
            }
          ).start(() => updatePan({x: limitInfX, y: pan.y._value}))
        }
        if (screenPosition.y < (limitInfY)) {
          Animated.spring(
            pan,
            {
              toValue: {x: pan.x._value, y: limitInfY},
              restSpeedThreshold: 500,
              restDisplacementThreshold: 40
            }
          ).start(() => updatePan({x: pan.x._value, y: limitInfY}))
        }
      }
    }),
    [screenScale]
  )

  const onceInitPan = useRef(true)

  useEffect(() => {
    if (!pan || !onceInitPan.current) return
    pan.addListener((value) => {
      if (onceInitPan.current) {
        updatePan(value)
      }
      if (value.x && value.y && onceInitPan.current) onceInitPan.current = false
    })
  }, [pan])


  return <MainWrapperMap
    screenWidth={screenWidth}
    screenHeight={screenHeight}
  >
    <WrapperMap
      {...panResponder.panHandlers}
      screenWidth={screenWidth}
      screenHeight={screenHeight}
    >
      <MapAnimated pan={pan} screenScale={screenScale}>
        {children}
      </MapAnimated>
    </WrapperMap>
  </MainWrapperMap>
}

const MainWrapperMap = styled.ScrollView(({screenWidth, screenHeight}) => ({
  overflow: 'visible',
  maxWidth: screenWidth,
  maxHeight: screenHeight,
  touchAction: 'none',
  position: 'absolute',
  top: 0,
  left: 0,
}))

const MapAnimated = styled(Animated.View)({
  position: 'absolute',
  transformStyle: 'preserve-3d',
  willChange: 'transform',
  touchAction: 'none',
}, ({pan, screenScale}) => ({
  transform: [{translateX: pan.x}, {translateY: pan.y}, {scale: screenScale}]
}))

const WrapperMap = styled.View(({screenWidth, screenHeight}) => ({
  position: 'relative',
  flexDirection: 'column',
  width: screenWidth,
  height: screenHeight,
  overflow: 'hidden',
}))
