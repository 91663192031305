import firebase                          from 'firebase'
import { createFirebaseGenericAccessor } from '@civitime/game-server/lib/utils/firebaseGenericAccessors'
import { phase1Database }                from '@civitime/game-server/lib/databases/Phases/phase1Database'

const options = {
  apiKey: "AIzaSyB6sy26YH0E4v-vGyFy191UkxsvMMCs81Y",
  authDomain: "ct-next.firebaseapp.com",
  databaseURL: "https://ct-next.firebaseio.com",
  projectId: "ct-next",
  storageBucket: "ct-profiles",
  messagingSenderId: "718575971995",
  appId: "1:718575971995:web:738f8a3e469fba0650603e"
}
export const fbApp = firebase
  .initializeApp(options)


export const firebaseAccessor = createFirebaseGenericAccessor(fbApp, phase1Database, true)
