import Constants from 'expo-constants'
import {isDev} from "../../isDev";

const baseserveruri = Constants.manifest.extra.baseuri
const prodUri = 'https://europe-west3-ct-next.cloudfunctions.net'

export const env = {
  AUTH_SERVER_URI: baseserveruri + (isDev ? '/authServerDev' : '/authServer'),
  GAME_SERVER_URI: baseserveruri + (isDev ? '/gameServerDev' : '/gameServer'),
}
