export const isDev = evaluateIsDev()

const devParts = ["192", "localhost", "ct-next-dev", "ct-next-pre-prod"]

function evaluateIsDev() {
  return false;
  // try {
  //   const parts = window.location.hostname.split(".")
  //   return devParts.includes(parts[0])
  // } catch (e) {
  //   return true
  // }
}
