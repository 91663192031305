import React, {useEffect, useRef, useState} from 'react'
import {useScreenDimensions} from '@civitime/library/storybook/hooks/useScreenDimensions'
import {T, useTranslator} from '../../../translations/translate'
import {colors} from '@civitime/library/storybook/configs/colors'
import {FragmentButton} from '@civitime/library/storybook/stories/Button'
import {WrapperModalModules} from './Modules/Components/ModulesModal'
import styled from '@emotion/native'
import {ModulesModal} from '@civitime/library/storybook/stories/Modals'
import {MediumText} from '@civitime/library/storybook/stories/Texts'
import {ThunderRoundIcon} from '@civitime/library/storybook/stories/Icons'
import {
  calculateRemainingTime,
  clearedOffsetDate
} from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers'
import {calculateDateDiff} from '@civitime/library/storybook/stories/Counters'

export const ModalNoMorePAModal = ({startAt, close}) => {
  if (!startAt) return null
  const root = useRef(null)
  const {mobileTablet} = useScreenDimensions(root)
  const {t} = useTranslator()
  const [now, setNow] = useState(() => clearedOffsetDate(new Date()))
  const tick = () => setNow(clearedOffsetDate(new Date()))
  const time = calculateRemainingTime(
    now.toISOString(),
    clearedOffsetDate(startAt).toISOString()
  )
  const timerValue = calculateDateDiff(time, true)

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => clearInterval(timerID)
  }, [])

  return <WrapperFragmentModules ref={root} style={{zIndex: 100}}>
    <ModalPA noButton onPress={close} color={colors.secondary} padding={mobileTablet ? 30 : 70} borderRadius={11}>
      <Wrapper>
        <FragmentText>
          <T path="phase_1.noMorePA.text"/>
        </FragmentText>
        <FragmentText>
          <T path="phase_1.noMorePA.explanation" data={{0: timerValue}}/>
        </FragmentText>
        <ActionPointsIcon width={50}
                          height={50}
                          color={'white'}
                          wrapperColor={'#4A4A4A'}/>
        <FragmentButton text={t('phase_1.noMorePA.close')}
                        style={{margin: 'auto', marginTop: 20}}
                        onPress={close}/>
      </Wrapper>
    </ModalPA>
  </WrapperFragmentModules>
}

const ModalPA = styled(ModulesModal)({
  top: '20%',
})

const WrapperFragmentModules = styled(WrapperModalModules)({
  backgroundColor: colors.test
})

const FragmentText = styled(MediumText)({
  marginBottom: 10
})

const Wrapper = styled.View({
  display: 'flex',
  justifyContent: 'center',
  alignItems: "center"
})

const ActionPointsIcon = styled(ThunderRoundIcon)({
  margin: 10
})
