import React from "react"
import styled from "@emotion/native"
import { useRealTimeData } from "../../Utils/dataClient"
import { clientId } from "../../../clientId"

import { colors } from "@civitime/library/storybook/configs/colors"
import { BoldText } from "@civitime/library/storybook/stories/Texts"
import { AvatarComponent } from "@civitime/library/storybook/stories/Avatar"

export const MemberListItem = ({ member, noBorder, mobile, orientation }) => {
  const memberInfos = useRealTimeData(
    `clients/${clientId}/profiles/${member}`,
    []
  )

  return memberInfos ? (
    <WrapperMemberListItem {...{ noBorder, mobile, orientation }}>
      <AvatarComponent
        circleAvatarConfig={{
          size: mobile ? 35 : 50,
          withBorder: true,
          backgroundColor: colors.extraLightBackground,
        }}
        avatar={memberInfos?.avatar}
      />
      <MemberListItemName>{memberInfos.name}</MemberListItemName>
    </WrapperMemberListItem>
  ) : null
}

const WrapperMemberListItem = styled.View(
  {
    flexDirection: "row",
    alignItems: "center",
  },
  ({ mobile, orientation }) => ({
    paddingVertical: mobile ? 4 : 8,
    paddingHorizontal: mobile ? 40 : 50,
    width: mobile && orientation === "portrait" ? "100%" : "50%",
  }),
  ({ noBorder }) =>
    !noBorder && {
      borderBottomWidth: 1,
      borderBottomColor: colors.text,
    }
)

const MemberListItemName = styled(BoldText)({
  textTransform: "uppercase",
  marginLeft: 17,
})
