import React from "react"

import { useProfile } from "../Auth/useProfile"

import { AvatarHomeSelection } from "./AvatarHomeSelection"
import { AvatarProfile } from "./AvatarProfile"

export const Avatar = ({props}) => {
  const { publicProfile } = useProfile()

  return publicProfile ? (
    publicProfile?.avatar ? (
      <AvatarProfile />
    ) : (
      <AvatarHomeSelection openHelper={props?.openHelper}/>
    )
  ) : null
}
