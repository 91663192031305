import React, {useEffect, useRef, useState} from "react"
import styled from "@emotion/native"
import {clientId} from "../../../clientId"
import {useCampaign} from "../../GlobalContexts"
import {useRealTimeData} from "../../Utils/dataClient"

import {TopPlayerRanking} from "../components/TopPlayerRanking"
import {PlayerRankingList} from "../components/PlayerRankingList"
import {Line} from "../components/Containers"
import {useScreenDimensions} from "@civitime/library/storybook/hooks/useScreenDimensions"
import {TeamDetails} from "../components/TeamDetails"
import {View} from 'react-native'

export const PlayersRanking = () => {
  const root = useRef(null)
  const { mobile, orientation, height } = useScreenDimensions(root)
  const { campaignId, campaignEnded } = useCampaign()
  const [ranking, setRanking] = useState([])
  const [teamPressed, setTeamPressed] = useState(null)
  const [visible, setVisible] = useState(false)

  const leaderboard = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/leaderboards/leaderboard`
  )

  const pressTeam = (teamId) => {
    setTeamPressed(teamId)
    setVisible(true)
  }

  useEffect(() => {
    if (!leaderboard) return
    delete leaderboard.loaded
    const sortedRank = Object.entries(leaderboard)
      .sort(([idA, scoreA], [idB, scoreB]) => scoreB - scoreA)
      .map(([id, score]) => ({ playerId: id, score: score }))
    setRanking(sortedRank)
  }, [leaderboard])

  return (
    <>
      <PlayerRankingWrapper ref={root}  mobile={mobile} heightScreen={height} campaignEnded={campaignEnded}>
        <View>
          <TopPlayerRanking
            data={ranking ? ranking.slice(0, 3) : []}
            {...{ mobile, orientation, pressTeam }}
          />
          <Line {...{ mobile, orientation }} />
        </View>
        <PlayerRankingList
          data={ranking ? ranking.slice(3) : []}
          {...{ mobile, orientation, pressTeam }}
        />
      </PlayerRankingWrapper>

      {visible && (
        <TeamDetails
          {...{ visible }}
          onRequestClose={() => {
            setVisible(false)
            setTeamPressed(null)
          }}
          teamId={teamPressed}
        />
      )}
    </>
  )
}

const PlayerRankingWrapper = styled.View(({mobile, heightScreen, campaignEnded}) => ({
  height: !mobile && !campaignEnded ? (heightScreen - 169) : !mobile ? (heightScreen - 302) : mobile && !campaignEnded ? (heightScreen - 172) : mobile ? (heightScreen - 345) : 'unset'
}))
