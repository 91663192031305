import React, {useEffect, useMemo, useRef, useState} from 'react'
import {View} from 'react-native'
import {useLocation} from 'react-router'
import styled from '@emotion/native'
import {useCampaign} from '../GlobalContexts'
import {useProfile} from '../Auth/useProfile'
import {colors} from '@civitime/library/storybook/configs/colors'
import {useScreenDimensions} from '@civitime/library/storybook/hooks/useScreenDimensions'
import {brand as isMobileApp} from "expo-device"
import {Phase1AppSkeleton} from "../Phases/Phase1/Components/AppSkeleton/Phase1AppSkeleton"
import {MobileHeader} from "./MobileHeader"
import {Header} from "./Header"

const appSkeletonElements = {
  1: Phase1AppSkeleton
}

const sendPostMessages = (userSession, technicalProfile) => {

  if(technicalProfile.lmsData) {
    let modulesDone = 0;

    if(userSession?.quizTunnel?.nextLevel) {
      const nextLevel = parseInt(userSession.quizTunnel.nextLevel.substr(userSession.quizTunnel.nextLevel.length -1), 10);
      modulesDone += (nextLevel -1)
    }

    if(userSession?.quizHarbor?.nextLevel) {
      const nextLevel = parseInt(userSession.quizHarbor.nextLevel.substr(userSession.quizHarbor.nextLevel.length -1), 10);
      modulesDone += (nextLevel -1)
    }

    if(userSession?.mastermind?.nextLevel) {
      const nextLevel = parseInt(userSession.mastermind.nextLevel.substr(userSession.mastermind.nextLevel.length -1), 10);
      modulesDone += (nextLevel -1)
    }

    if(userSession?.memory?.nextLevel) {
      const nextLevel = parseInt(userSession.memory.nextLevel.substr(userSession.memory.nextLevel.length -1), 10);
      modulesDone += (nextLevel -1)
    }

    if(modulesDone > 0) {
      window.parent.postMessage(JSON.stringify({
        type: "modulesAchieved",
        currentModules: modulesDone,
        totalModules: 20,
        success: modulesDone === 20
      }), "*");
    }

  }
}

export const AppSkeleton = ({
                              children,
                              serveDimensions,
                              openLangSelector,
                              phase,
                              ...props
                            }) => {
  const root = useRef(null)
  const {campaignNotStarted} = useCampaign()
  const {mobile, orientation, height, width} = useScreenDimensions(root)
  const {publicProfile, userState, notifications, userSession, technicalProfile} = useProfile()
  const location = useLocation()
  const [headerDisplayed, setHeaderDisplayed] = useState(false)
  const [phaseProps, setPhaseProps] = useState({})

  const AppSkeletonRender = useMemo(() => {
    if (!phase) return null
    return appSkeletonElements[phase]
  }, [phase])

  useEffect(() => {
    serveDimensions({
      mobile: mobile,
      orientation: orientation,
      height: height,
    })
  }, [mobile, orientation, height])

  useEffect(() => {
    sendPostMessages(userSession, technicalProfile);
  }, [userSession]);

  const returnToMap = location.pathname.match("/leaderboard")

  const backgroundImg =
    isMobileApp && orientation === "portrait"
      ? require("../../assets/Auth/fd-mobil-ok.png")
      : require("../../assets/Auth/fd-desk-ok.png")

  return publicProfile ? (
    <AppBackground ref={root}>
      {
        campaignNotStarted &&
        <AppBackgroundCampaignNotStarted>
          <BackgroundWaitingCampaignStart source={backgroundImg} alt={"background"}/>
        </AppBackgroundCampaignNotStarted>
      }
      {
        AppSkeletonRender && !campaignNotStarted &&
        <AppSkeletonRender returnToMap={returnToMap}
                           updateHeaderDisplayed={setHeaderDisplayed}
                           setPhaseProps={setPhaseProps}
                           {...props}/>
      }
      {
        mobile && returnToMap && headerDisplayed ? (
          <MobileHeader
            {...{
              avatar: publicProfile?.avatar,
              returnToMap,
              publicProfile,
              userState,
            }}
            openHelper={props?.openHelper}
            openLangSelector={openLangSelector}
            {...phaseProps}
          />
        ) : (
          headerDisplayed && (
            <Header
              {...{publicProfile, returnToMap, userState, mobile}}
              notifications={notifications}
              openHelper={props?.openHelper}
              openLangSelector={openLangSelector}
              screenWidth={width}
              phase={phase}
              screenHeight={height}
              {...phaseProps}
            />
          )
        )}
      <View
        style={
          mobile && orientation === "portrait"
            ? {position: "absolute", zIndex: 4}
            : {zIndex: returnToMap ? 0 : 4}
        }
      >
        {children}
      </View>
    </AppBackground>
  ) : null
}

const BackgroundWaitingCampaignStart = styled.Image({
  width: '100%',
  height: '100%',
})

const AppBackgroundCampaignNotStarted = styled.View({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
})

const AppBackground = styled.View({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.defaultBackground,
  zIndex: 1,
  overflow: 'hidden',
  userSelect: 'none',
})
