import {useCampaign} from "../../../../GlobalContexts"
import {useProfile} from "../../../../Auth/useProfile"
import {useEffect} from "react"

export const Phase1Init = () => {
  const {campaignNavigate, campaignSettings, campaignEnded, campaignNotStarted} = useCampaign()
  const {userState, loading, publicProfile} = useProfile()

  useEffect(() => {
    if (!loading) {
      if (!publicProfile.avatar) {
        return campaignNavigate("avatar")
      }

      if (!userState?.teamId && !campaignEnded) {
        return campaignNavigate("team")
      }
    }
    if (campaignNotStarted) return campaignNavigate("team")
  }, [userState, publicProfile, campaignSettings?.endAt, campaignNotStarted])

  return null
}
