import React, { useState, useEffect } from "react"
import { Animated } from "react-native"
import styled from "@emotion/native"

import { CardsItem } from "./CardsItem"

const initializeAnimation = () => {
  const animation = new Animated.Value(0)
  const frontInterpolate = animation.interpolate({
    inputRange: [0, 180],
    outputRange: ["0deg", "180deg"],
  })
  const backInterpolate = animation.interpolate({
    inputRange: [0, 180],
    outputRange: ["180deg", "360deg"],
  })
  const frontOpacity = animation.interpolate({
    inputRange: [0, 180],
    outputRange: [1, 0],
  })
  const backOpacity = animation.interpolate({
    inputRange: [0, 180],
    outputRange: [0, 1],
  })

  return {
    animation,
    frontOpacity,
    backOpacity,
    frontInterpolate,
    backInterpolate,
  }
}

export const Cards = ({ players, mobile, orientation, challengePlayer }) => {
  const [
    { animation, frontOpacity, backOpacity, frontInterpolate, backInterpolate },
  ] = useState(initializeAnimation)

  const frontAnimatedStyle = { transform: [{ rotateY: frontInterpolate }] }
  const backAnimatedStyle = { transform: [{ rotateY: backInterpolate }] }

  useEffect(() => {
    Animated.spring(animation, {
      toValue: players[0] ? 180 : 0,
      friction: 8,
      tension: 40,
      restSpeedThreshold: 0.5,
      restDisplacementThreshold: 0.5,
    }).start()
  }, [players])

  return (
    <WrapperCards {...{ mobile, orientation }}>
      {players &&
        players.map((player, index) => (
          <CardsItem
            key={index + "playerCard"}
            {...{
              player,
              mobile,
              orientation,
              frontAnimatedStyle: frontAnimatedStyle,
              frontOpacity: frontOpacity,
              backAnimatedStyle: backAnimatedStyle,
              backOpacity: backOpacity,
              challengePlayer,
            }}
          />
        ))}
    </WrapperCards>
  )
}

const WrapperCards = styled.View(
  {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    padding: 17,
    marginTop: 35,
  },
  ({ mobile, orientation }) =>
    mobile
      ? orientation === "portrait"
        ? {
            flexDirection: "column",
          }
        : {
            marginHorizontal: 25,
          }
      : {
          marginHorizontal: 100,
          alignItems: "center",
        }
)
