import { DefaultModal }              from '@civitime/library/storybook/stories/Modals'
import React, { useRef }             from 'react'
import { useTranslator }             from './translate'
import { MediumText }                from '@civitime/library/storybook/stories/Texts'
import { ActionButton, RoundButton } from '@civitime/library/storybook/stories/Button'
import { colors }                    from '@civitime/library/storybook/configs/colors'
import styled                        from '@emotion/native'
import { useHovered }                from '@civitime/library/storybook/hooks/useHovered'

export const LangSelectorModal = ({closeSelector}) => {
  const root = useRef(null)
  const { t, setLang, lang, langs } = useTranslator()
  return <SelectorModal
    title={t('app.langSelector.title')}
    onPress={closeSelector}
  >
    <LangsWrapper ref={root}>
      {
        langs && langs?.map(displayedLang => {
            return <>
              <LangWrapper>
                <LangText>{displayedLang.lang}</LangText>
                <LangIcon lang={displayedLang.shortLang}
                          setLang={setLang}
                          selected={lang === displayedLang.shortLang}/>
              </LangWrapper>
              <LangSeparator/>
            </>
          }
        )
      }
    </LangsWrapper>
    <ValidateButton onPress={closeSelector}>
      {t('app.langSelector.validate')}
    </ValidateButton>
  </SelectorModal>
}

const LangsWrapper = styled.View({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 30,
  marginBottom: 30,
  margin: 'auto',
  width: '70%',
})

const LangWrapper = styled.View({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

const LangIcon = ({ lang, setLang, selected }) => {
  const [hovered, hoverProps] = useHovered()
  return <LangButton onPress={() => {
    return setLang(lang) }
  }
                      color={(hovered || selected) ? colors.secondary
                        : colors.defaultBackground}
                      size={30}
                      selected={selected}
                      noShadow={true}
                      {...hoverProps}
                      hovered={hovered}>
    <LangIconText hovered={hovered} selected={selected}>{lang}</LangIconText>
  </LangButton>
}

const LangButton = styled(RoundButton)(({selected}) => ({
  boxShadow: selected && 'inset -1px -1px 3px rgba(255, 255, 255, 1), inset 1px 1px 3px rgba(0,0,0, 0.6)',
}))
const LangIconText = styled(MediumText)(({hovered, selected}) => ({
  textTransform: 'uppercase',
  color: (hovered || selected) ? colors.white : colors.secondary,
  alignSelf: 'center',
  borderWidth: 2,
  borderColor: selected ? 'transparent' : colors.secondary,
  borderRadius: '50%',
  padding: 3
}))

const LangText = styled(MediumText)({
  fontWeight: 700
})

const LangSeparator = styled.View({
  backgroundColor:  '#CED2DF',
  height: 1,
  width: '100%',
  marginTop: 5,
  marginBottom: 10
})

const ValidateButton = styled(ActionButton)({
  width: 'auto',
  margin: 'auto',
  marginBottom: 30
})

const SelectorModal = styled(DefaultModal)({
  zIndex: 10,
  margin: 'auto'
})
