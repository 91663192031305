import React, {useEffect, useState} from "react"
import {Route, Switch} from "react-router-native"
import {NewTeam} from "../../../../Teams/NewTeam"
import {TeamSearch} from "../../../../Teams/TeamSearch"
import {TeamInfos} from "../../../../Teams/TeamInfos"
import {Team} from "../../../../Teams/Team"
import {ChallengesHistory} from "../Challenges/pages/ChallengesHistory"
import {Home} from "../../../../MainComponents/Home"
import {Challenges} from "../Challenges/pages/Challenges"

export const Phase1Router = ({basePath, serveDimensions, setPhaseProps, phase}) => {
  const [displayModule, setDisplayModule] = useState(false)
  const enterModule = () => setDisplayModule(true)
  const quitModule = () => setDisplayModule(false)
  const [openedHelper, setOpenedHelper] = useState(false)
  const openHelper = () => setOpenedHelper(true)
  const closeHelper = () => setOpenedHelper(false)
  const [displayResult, setDisplayResult] = useState(null)

  useEffect(() => {
    setPhaseProps({
      quitModule: () => quitModule(),
      closeHelper: () => closeHelper(),
      openHelper: () => openHelper(),
      enterModule: () => enterModule(),
      displayModule: displayModule,
      openedHelper: openedHelper
    })
  }, [displayModule, openedHelper])

  return <Switch>
    <Route path={`${basePath}/team/new`}>
      <NewTeam
        height={serveDimensions?.height}
        mobile={serveDimensions?.mobile}
        openHelper={openHelper}
      />
    </Route>
    <Route path={`${basePath}/team/search`}>
      <TeamSearch/>
    </Route>
    <Route path={`${basePath}/team/:teamId`}>
      <TeamInfos/>
    </Route>
    <Route path={`${basePath}/team`}>
      <Team
        height={serveDimensions?.height}
        mobile={serveDimensions?.mobile}
        orientation={serveDimensions?.orientation}
        openHelper={openHelper}
        openedHelper={openedHelper}
      />
    </Route>
    <Route
      path={`${basePath}/history`}
      component={() => (
        <ChallengesHistory
          displayedResult={displayResult}
          displayResult={(v) => setDisplayResult(v)}
          height={serveDimensions?.height}
          mobile={serveDimensions?.mobile}
          orientation={serveDimensions?.orientation}
        />
      )}
    />
    <Route
      path={`${basePath}/challenges/:module/:level/:challengeId`}
      component={() => <Home phase={phase}/>}
    />
    <Route
      path={`${basePath}/challenges/:module/:level`}
      component={({match}) => (
        <Challenges enterModule={enterModule} match={match}/>
      )}
    />
  </Switch>
}
