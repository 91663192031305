import React, { useRef } from "react"
import { Route, Switch } from "react-router-native"
import styled from "@emotion/native"
import { useLocation } from "react-router"
import { T, useTranslator } from "../../translations/translate"
import { useCampaign } from "../../GlobalContexts"

import { TeamsRanking } from "./TeamsRanking"
import { PlayersRanking } from "./PlayersRanking"

import { colors } from "@civitime/library/storybook/configs/colors"
import { useScreenDimensions } from "@civitime/library/storybook/hooks/useScreenDimensions"
import { MOBILE_HEADER_SIZE } from "@civitime/library/storybook/configs/sizes"
import { TabsButton } from "@civitime/library/storybook/stories/Button"
import {
  IndividualRankIcon,
  TeamRankIcon,
} from "@civitime/library/storybook/stories/Icons"
import {MediumText, Title1} from "@civitime/library/storybook/stories/Texts"

export const RankingTabs = ({}) => {
  const location = useLocation()
  const root = useRef(null)
  const { mobile } = useScreenDimensions(root)
  const { t } = useTranslator()
  const { height, width } = useScreenDimensions(root)
  const { campaignNavigate, campaignSettings } = useCampaign()
  const { basePath, campaignEnded } = useCampaign()
  const teamRankLocation = location.pathname.match("/leaderboard/team")

  return (
    <Screen
      ref={root}
      color={colors.text}
      title={t("app.team.yourTeam")}
      onPress={() => campaignNavigate("")}
      height={height}
      width={width}
      withShadow
      mobile={mobile}
    >
      <TabsButtonWrapper {...{ mobile }}>
        <TabsButtons
          {...{ mobile, width }}
          color={colors.lightText}
          backgroundColor={colors.primary}
          last={mobile}
          first={true}
          icon={
            <TeamRankIcon
              color={teamRankLocation ? "#F2F2F2" : "#4A4A4A"}
              width={35}
              height={35}
            />
          }
          onPress={() => campaignNavigate("leaderboard/team")}
          selected={teamRankLocation}
        >
          <T path="app.leaderboards.teamLeaderboard.title" />
        </TabsButtons>
        <TabsButtons
          {...{ mobile, width }}
          color={colors.lightText}
          backgroundColor={colors.primary}
          last={true}
          first={mobile}
          icon={
            <IndividualRankIcon
              color={!teamRankLocation ? "#F2F2F2" : "#4A4A4A"}
              width={35}
              height={35}
            />
          }
          onPress={() => campaignNavigate("leaderboard/player")}
          selected={!teamRankLocation}
        >
          <T path="app.leaderboards.playerLeaderboard.title" />
        </TabsButtons>
      </TabsButtonWrapper>
      {
        campaignEnded &&
        <WrapperCampaignEnded mobile={mobile} width={width}>
        <TitleCampaignEnded>
          <T path="phase_1.campaignEnded.ranking.title" data={{date: convertDate(campaignSettings?.endAt)}}/>
        </TitleCampaignEnded>
        <TextCampaignEnded>
          <T path="phase_1.campaignEnded.ranking.text"/>
        </TextCampaignEnded>
      </WrapperCampaignEnded>
      }
      <Switch>
        <Route path={`${basePath}/leaderboard/player`}>
          <PlayersRanking />
        </Route>
        <Route path={`${basePath}/leaderboard/team`}>
          <TeamsRanking />
        </Route>
      </Switch>
    </Screen>
  )
}

const TitleCampaignEnded = styled(Title1)({
  marginBottom: 15,
  color: colors.primary
})

const TextCampaignEnded = styled(MediumText)({

})

const WrapperCampaignEnded = styled.View(({mobile, width}) => ({
  padding: 20,
  backgroundColor: colors.violetLight,
  maxWidth: mobile ? "none" : (width / 3) * 2,
  marginLeft: mobile ? "auto" : '50%',
  transform: mobile ? 'none' : [{translateX: '-50%'}],
  marginRight: mobile ? "auto" : 0,
  width: mobile ? "95%" : "none",
}))

const Screen = styled.View(({ width, height, mobile }) => ({
  width: width,
  minHeight: height,
  paddingTop: mobile ? MOBILE_HEADER_SIZE : MOBILE_HEADER_SIZE + 40,
}))

const TabsButtonWrapper = styled.View(({ width, mobile }) => ({
  display: "flex",
  flexDirection: mobile ? "column" : "row",
  justifyContent: "center",
  width: width * 0.8,
}))

const TabsButtons = styled(TabsButton)(({ width, mobile }) => ({
  maxWidth: mobile ? "none" : width / 3,
  marginLeft: mobile ? "auto" : 0,
  marginRight: mobile ? "auto" : 0,
  width: mobile ? "95%" : "none",
}))

export const convertDate = (inputFormat) => {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  const d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}
