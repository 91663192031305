export const formatTiledMap = (map) => {
  const tilesCalculate = map?.layers?.[0]?.width + map?.layers?.[0]?.height
  const linesCalculate = map?.layers?.[0]?.width
  const copyArray = [...map?.layers?.[0]?.data]
  const firstMapFormat = []

  while (copyArray.length > 0) {
    firstMapFormat.push(copyArray.splice(0, linesCalculate))
  }

  const mapArray = [...firstMapFormat]
  const mapFormat = []
  let secondLineIterator = 0
  let rawSecondLineIterator = 0
  let setSecondPosition = true
  let secondPosition = null
  let rawSecondPosition = null

  for (let i = 0; i < tilesCalculate; i++) {
    const tempsLine = []
    let position = i
    let lineIterator = 0
    if (i > tilesCalculate / 2) {
      if (setSecondPosition) {
        rawSecondPosition = i - 1
        secondPosition = i - 1
        rawSecondLineIterator = 0
        setSecondPosition = false
      }
      while (mapArray?.[secondLineIterator + 1]?.[secondPosition - 1]) {
        tempsLine.push(mapArray[secondLineIterator + 1][secondPosition - 1])
        secondPosition -= 1
        secondLineIterator += 1
      }
      secondPosition = rawSecondPosition
      rawSecondLineIterator += 1
      secondLineIterator = rawSecondLineIterator
      mapFormat.push(tempsLine)
    } else {
      while (mapArray?.[lineIterator]?.[position - 1]) {
        tempsLine.push(mapArray[lineIterator][position - 1])
        position -= 1
        lineIterator += 1
      }
      mapFormat.push(tempsLine)
    }
  }
  mapFormat.shift()
  mapFormat.forEach((line) => line.reverse())
  return mapFormat
}

export const findAvailableMoves = (indexLine, indexTile, playerPosition, tilesNumbers, isModule) => {
  if (!playerPosition) return
  return (
    (indexLine === playerPosition.line && indexTile === playerPosition.tile && isModule) ||
    //Sides
    indexLine === playerPosition.line && indexTile === playerPosition.tile - 1 ||
    indexLine === playerPosition.line && indexTile === playerPosition.tile + 1 ||
    //Up_Down
    playerPosition.line > (tilesNumbers - 1) && playerPosition.line !== tilesNumbers && indexLine === playerPosition.line - 2 && indexTile === playerPosition.tile + 1 ||
    playerPosition.line < (tilesNumbers - 1) && playerPosition.line !== (tilesNumbers - 2) && indexLine === playerPosition.line - 2 && indexTile === playerPosition.tile - 1 ||

    playerPosition.line > (tilesNumbers - 1) && playerPosition.line !== tilesNumbers && indexLine === playerPosition.line + 2 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line < (tilesNumbers - 1) && playerPosition.line !== (tilesNumbers - 2) && indexLine === playerPosition.line + 2 && indexTile === playerPosition.tile + 1 ||

    playerPosition.line === (tilesNumbers - 2) && indexLine === playerPosition.line + 2 && indexTile === playerPosition.tile ||
    playerPosition.line === (tilesNumbers - 2) && indexLine === playerPosition.line - 2 && indexTile === playerPosition.tile - 1 ||

    playerPosition.line === tilesNumbers && indexLine === playerPosition.line + 2 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line === tilesNumbers && indexLine === playerPosition.line - 2 && indexTile === playerPosition.tile ||

    playerPosition.line === (tilesNumbers - 1) && indexLine === playerPosition.line - 2 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line === (tilesNumbers - 1) && indexLine === playerPosition.line + 2 && indexTile === playerPosition.tile - 1 ||
    //Diago
    playerPosition.line > (tilesNumbers - 1) && indexLine === playerPosition.line - 1 && indexTile === playerPosition.tile ||
    playerPosition.line > (tilesNumbers - 1) && indexLine === playerPosition.line - 1 && indexTile === playerPosition.tile + 1 ||

    playerPosition.line < (tilesNumbers - 1) && indexLine === playerPosition.line - 1 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line < (tilesNumbers - 1) && indexLine === playerPosition.line - 1 && indexTile === playerPosition.tile ||

    playerPosition.line > (tilesNumbers - 1) && indexLine === playerPosition.line + 1 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line > (tilesNumbers - 1) && indexLine === playerPosition.line + 1 && indexTile === playerPosition.tile ||

    playerPosition.line < (tilesNumbers - 1) && indexLine === playerPosition.line + 1 && indexTile === playerPosition.tile ||
    playerPosition.line < (tilesNumbers - 1) && indexLine === playerPosition.line + 1 && indexTile === playerPosition.tile + 1 ||

    playerPosition.line === (tilesNumbers - 1) && indexLine === playerPosition.line - 1 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line === (tilesNumbers - 1) && indexLine === playerPosition.line - 1 && indexTile === playerPosition.tile ||
    playerPosition.line === (tilesNumbers - 1) && indexLine === playerPosition.line + 1 && indexTile === playerPosition.tile - 1 ||
    playerPosition.line === (tilesNumbers - 1) && indexLine === playerPosition.line + 1 && indexTile === playerPosition.tile
  )
}

export const updateMapFog = (map, tilesNumbers, playerPosition, updateFog, trip) => {
  if (!playerPosition) return
  const tempMap = [...map] || []
  if (trip) {
    tempMap[playerPosition.line][playerPosition.tile] = 1
  }
  if (playerPosition.line === tilesNumbers) {
    tempMap[playerPosition.line][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line - 1][playerPosition.tile + 1] = 1
    tempMap[playerPosition.line - 1][playerPosition.tile] = 1

    tempMap[playerPosition.line - 2][playerPosition.tile + 1] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile - 1] = 1

    tempMap[playerPosition.line + 1][playerPosition.tile] = 1
    tempMap[playerPosition.line + 1][playerPosition.tile - 1] = 1

    tempMap[playerPosition.line + 2][playerPosition.tile] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile - 2] = 1
  } else if (playerPosition.line === tilesNumbers - 2) {
    tempMap[playerPosition.line][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line - 1][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line - 1][playerPosition.tile] = 1

    tempMap[playerPosition.line - 2][playerPosition.tile - 2] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile - 1] = 1

    tempMap[playerPosition.line + 1][playerPosition.tile] = 1
    tempMap[playerPosition.line + 1][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line + 2][playerPosition.tile] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile + 1] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile - 1] = 1
  } else if (playerPosition.line < tilesNumbers - 1) {
    tempMap[playerPosition.line][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line - 1][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line - 1][playerPosition.tile] = 1

    tempMap[playerPosition.line - 2][playerPosition.tile - 2] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile - 1] = 1

    tempMap[playerPosition.line + 1][playerPosition.tile] = 1
    tempMap[playerPosition.line + 1][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line + 2][playerPosition.tile] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile + 1] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile + 2] = 1
  } else if (playerPosition.line > tilesNumbers - 1) {
    tempMap[playerPosition.line][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line - 1][playerPosition.tile + 1] = 1
    tempMap[playerPosition.line - 1][playerPosition.tile] = 1

    tempMap[playerPosition.line - 2][playerPosition.tile + 2] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line + 1][playerPosition.tile] = 1
    tempMap[playerPosition.line + 1][playerPosition.tile - 1] = 1

    tempMap[playerPosition.line + 2][playerPosition.tile] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile - 2] = 1
  } else if (playerPosition.line === tilesNumbers - 1) {
    tempMap[playerPosition.line][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line][playerPosition.tile + 1] = 1

    tempMap[playerPosition.line - 1][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line - 1][playerPosition.tile] = 1

    tempMap[playerPosition.line - 2][playerPosition.tile - 2] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile] = 1
    tempMap[playerPosition.line - 2][playerPosition.tile - 1] = 1

    tempMap[playerPosition.line + 1][playerPosition.tile - 1] = 1
    tempMap[playerPosition.line + 1][playerPosition.tile] = 1

    tempMap[playerPosition.line + 2][playerPosition.tile - 2] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile] = 1
    tempMap[playerPosition.line + 2][playerPosition.tile - 1] = 1
  }
  updateFog(tempMap)
}
