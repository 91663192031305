import styled from "@emotion/native"
import React, { useState } from "react"
import { T } from "../../translations/translate"

import { TeamListItem } from "./TeamListItem"
import { MediumText } from "@civitime/library/storybook/stories/Texts"

export const TeamList = ({ teams, onPress }) => {
  const [collapsedTeam, setCollapsedTeam] = useState(null)

  return teams ? (
    teams.length ? (
      teams.map((team, index) => {
        const noBorder = teams.length - 1 === index

        return (
          <TeamListItem
            key={index + "teamListItem"}
            {...{ team, noBorder }}
            onPress={() => onPress(team.id)}
            collapsed={!(collapsedTeam === team.id)}
            onCollapsed={() =>
              setCollapsedTeam((prev) => (prev === team.id ? null : team.id))
            }
          />
        )
      })
    ) : (
      <NoResultText>
        <T path="app.team.noResults" />
      </NoResultText>
    )
  ) : null
}

const NoResultText = styled(MediumText)({
  fontSize: 18,
  lineHeight: 21,
  marginTop: 10,
})
