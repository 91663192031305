import React, {useEffect, useState} from "react"
import {Animated} from "react-native"
import styled from "@emotion/native"

import {useProfile} from "../../../../../Auth/useProfile"
import {PlayerInfos} from "../components/PlayerInfos"

import {colors} from "@civitime/library/storybook/configs/colors"
import Thunder from "@civitime/library/assets/challenges/thunder.svg"
import {useCampaign} from "../../../../../GlobalContexts"

export const MobileChallengeLaunch = ({player, mobile, orientation, launchModuleAfterAnimation}) => {
  const THUNDER_WIDTH = 134
  const THUNDER_HEIGHT = 300

  const {publicProfile} = useProfile()
  const [wrapperHeight, setWrapperHeight] = useState(null)
  const top = new Animated.Value(0)
  const bottom = new Animated.Value(0)
  const scale = new Animated.Value(0.2)
  const final = (wrapperHeight - 130 * 2) / 2

  const {campaignNavigate} = useCampaign()

  useEffect(() => {
    Animated.parallel([
      Animated.timing(top, {toValue: final, duration: 700}),
      Animated.timing(bottom, {toValue: final, duration: 700}),
      Animated.timing(scale, {toValue: 1, duration: 800}),
    ]).start(() => {
      setTimeout(() => {
        campaignNavigate('wait')
        launchModuleAfterAnimation()
      }, 1000)
    })
  }, [])

  return (
    <WrapperChallengeLaunch
      onLayout={(event) => setWrapperHeight(event.nativeEvent.layout.height)}
    >
      <WrapperTop style={{top}}>
        <PlayerInfos player={publicProfile} {...{mobile, orientation}} />
      </WrapperTop>
      <WrapperPlaceholderThunder>
        <Thunder
          width={THUNDER_WIDTH}
          height={THUNDER_HEIGHT}
          fill={colors.challenges.avatarHovered}
        />
      </WrapperPlaceholderThunder>
      <WrapperThunder style={{transform: [{scale}]}}>
        <Thunder
          width={THUNDER_WIDTH}
          height={THUNDER_HEIGHT}
          fill={colors.secondary}
        />
      </WrapperThunder>
      <WrapperBottom style={{bottom}}>
        <PlayerInfos {...{player, mobile, orientation}} />
      </WrapperBottom>
    </WrapperChallengeLaunch>
  )
}

const WrapperChallengeLaunch = styled.View({
  position: "absolute",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
})

const PlayerShape = styled(Animated.View)({
  position: "absolute",
  height: 130,
  width: "100%",
  justifyContent: "center",
})
const WrapperTop = styled(PlayerShape)({
  backgroundColor: colors.challenges.playerBackground,
  borderTopRightRadius: 10,
  borderTopLeftRadius: 10,
  alignItems: "flex-start",
})
const WrapperBottom = styled(PlayerShape)({
  backgroundColor: colors.extraLightBackground,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  alignItems: "flex-end",
})

const WrapperPlaceholderThunder = styled.View({
  position: "absolute",
  zIndex: 9,
  transform: [{translateX: 2}, {translateY: 2}],
})

const WrapperThunder = styled(Animated.View)({
  zIndex: 10,
})
