import React, {useMemo} from "react"
import {Phase1Init} from "../Phases/Phase1/Components/Router/Phase1Init"

const phasesHomeElements = {
  1: Phase1Init
}

export const Home = ({phase}) => {
  const HomeRender = useMemo(() => {
    if (!phase) return null
    return phasesHomeElements[phase]
  }, [phase])

  return HomeRender ? (
    <HomeRender/>
  ) : <></>
}
