import React, { useRef } from "react"
import styled from "@emotion/native"

import { MemberListItem } from "./MemberListItem"
import { useScreenDimensions } from "@civitime/library/storybook/hooks/useScreenDimensions"

export const MembersList = ({ members, ...props }) => {
  const root = useRef(null)
  const { mobile, orientation } = useScreenDimensions(root)

  return (
    <WrapperMemberList {...{ mobile, orientation }} ref={root} {...props}>
      {members.map((member, index) => {
        const noBorder =
          members.length % 2 === 1 || (mobile && orientation === "portrait")
            ? members.length - 1 === index
            : members.length - 1 === index || members.length - 2 === index
        return (
          <MemberListItem
            key={index + "member"}
            {...{ member, index, noBorder, mobile, orientation }}
          />
        )
      })}
    </WrapperMemberList>
  )
}

const WrapperMemberList = styled.View(
  {
    flexWrap: "wrap",
    alignSelf: "center",
  },
  ({ mobile, orientation }) => ({
    flexDirection: mobile && orientation === "portrait" ? "column" : "row",
    width: mobile ? "90%" : "80%",
  })
)
