import React from "react"
import styled from "@emotion/native"
import {TouchableWithoutFeedback} from "react-native"
import {clientId} from "../../../clientId"
import {useCampaign} from "../../GlobalContexts"
import {useRealTimeData} from "../../Utils/dataClient"

import {
  RankingLineWrapper,
  TeamNameWrapper,
  WrapperRank,
  Name,
  WrapperPoints,
} from "./Containers"

import {colors} from "@civitime/library/storybook/configs/colors"
import {AvatarComponent} from "@civitime/library/storybook/stories/Avatar"

export const PlayerRankingListItem = ({
                                        player,
                                        index,
                                        mobile,
                                        isMe,
                                        pressTeam,
                                      }) => {
  const {campaignId} = useCampaign()

  const playerData = useRealTimeData(
    `clients/${clientId}/profiles/${player.playerId}`
  )
  const playerState = useRealTimeData(
    `clients/${clientId}/campaigns/${campaignId}/states/${player.playerId}`
  )

  const displayedName = calculateDisplayedName(playerData?.name)

  return (
    <TouchableWithoutFeedback onPress={() => pressTeam(playerState.teamId)}>
      <RankingLineWrapper {...{me: isMe, mobile}}>
        {playerData && (
          <>
            <WrapperRank mobile={mobile ? mobile : undefined}>
              {index + 4}
            </WrapperRank>
            <PlayerTeamNameWrapper {...{mobile}}>
              <WrapperAvatar
                circleAvatarConfig={{
                  size: 30,
                  withBorder: true,
                  backgroundColor: colors.extraLightBackground,
                }}
                avatar={playerData?.avatar}
              />
              <Name mobile={mobile ? mobile : undefined}>
                {mobile ? displayedName : playerData?.name}
              </Name>
            </PlayerTeamNameWrapper>

            <WrapperPoints {...{mobile}} count={player?.score}/>
          </>
        )}
      </RankingLineWrapper>
    </TouchableWithoutFeedback>
  )
}

const WrapperAvatar = styled(AvatarComponent)({
  padding: 10,
})

const PlayerTeamNameWrapper = styled(TeamNameWrapper)(({mobile}) => ({
  flex: mobile ? 0.8 : 0.5,
}))

export const calculateDisplayedName = (name) => {
  if (!name) return ""
  return name.length < 5 ? name
    : name?.split('')?.filter((num, i) => i < 5 ? num : null)?.filter(Boolean)?.join('') + '...'
}
