import React, { useRef } from "react"
import styled from "@emotion/native"
import { useProfile } from "../../Auth/useProfile"

import { MyTeamMemberListItem } from "./MyTeamMemberListItem"
import { useScreenDimensions } from "@civitime/library/storybook/hooks/useScreenDimensions"

export const MyTeamMembersList = ({ members }) => {
  const root = useRef(null)
  const { mobile, orientation } = useScreenDimensions(root)
  const { publicProfile } = useProfile()

  const membersSort = members.filter(
    (memberId) => memberId !== publicProfile.id
  )
  membersSort.unshift(publicProfile.id)

  return (
    <WrapperMemberList {...{ mobile }} ref={root}>
      {membersSort.map((member, index) => {
        const noBorder = membersSort.length - 1 === index

        return (
          <MyTeamMemberListItem
            key={index + "member"}
            {...{ member, noBorder, mobile, orientation }}
          />
        )
      })}
    </WrapperMemberList>
  )
}

const WrapperMemberList = styled.View(
  {
    flexDirection: "column",
  },
  ({ mobile }) => ({
    width: mobile ? "90%" : "70%",
  })
)
