export default{
  "v": "5.5.8",
  "fr": 35.6740264892578,
  "ip": 0,
  "op": 167.000124003646,
  "w": 3000,
  "h": 4200,
  "nm": "panneau destFinale",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 271,
      "h": 271,
      "u": "",
      "p": require("./images/img_0.png"),
      "e": 0
    },
    {
      "id": "image_1",
      "w": 1155,
      "h": 765,
      "u": "",
      "p": require("./images/img_1.png"),
      "e": 0
    },
    {
      "id": "image_2",
      "w": 722,
      "h": 583,
      "u": "",
      "p": require("./images/img_2.png"),
      "e": 0
    },
    {
      "id": "image_3",
      "w": 892,
      "h": 845,
      "u": "",
      "p": require("./images/img_3.png"),
      "e": 0
    },
    {
      "id": "image_4",
      "w": 1203,
      "h": 1271,
      "u": "",
      "p": require("./images/img_4.png"),
      "e": 0
    },
    {
      "id": "image_5",
      "w": 120,
      "h": 185,
      "u": "",
      "p": require("./images/img_5.png"),
      "e": 0
    },
    {
      "id": "image_6",
      "w": 1153,
      "h": 1166,
      "u": "",
      "p": require("./images/img_6.png"),
      "e": 0
    },
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "point.ai",
          "cl": "ai",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 14.27,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 49.944,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 57.0784423828125,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 7.135,
                  "s": [
                    620,
                    1119,
                    0
                  ],
                  "to": [
                    0,
                    -38.791,
                    0
                  ],
                  "ti": [
                    0,
                    35.546,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 21.404,
                  "s": [
                    620,
                    886.257,
                    0
                  ],
                  "to": [
                    0.25,
                    0.204,
                    0
                  ],
                  "ti": [
                    0.042,
                    2.148,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 35.674,
                  "s": [
                    620,
                    905.726,
                    0
                  ],
                  "to": [
                    -0.042,
                    -2.148,
                    0
                  ],
                  "ti": [
                    0.014,
                    1.318,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 39.955,
                  "s": [
                    619.75,
                    873.367,
                    0
                  ],
                  "to": [
                    -2.454,
                    -237.653,
                    0
                  ],
                  "ti": [
                    95.145,
                    53.41,
                    0
                  ]
                },
                {
                  "t": 57.0784423828125,
                  "s": [
                    235.407,
                    157.973,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                135.5,
                135.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    1,
                    1,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 21.404,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 35.674,
                  "s": [
                    90,
                    90,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 39.955,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "t": 57.0784423828125,
                  "s": [
                    96,
                    96,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 7.13480529785156,
          "op": 78.4828582763672,
          "st": 7.13480529785156,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "point.ai",
          "cl": "ai",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 42.809,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 49.9436370849609,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    620,
                    1119,
                    0
                  ],
                  "to": [
                    0,
                    -38.791,
                    0
                  ],
                  "ti": [
                    0,
                    35.546,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 14.27,
                  "s": [
                    620,
                    886.257,
                    0
                  ],
                  "to": [
                    0.25,
                    0.204,
                    0
                  ],
                  "ti": [
                    0.042,
                    2.148,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 28.539,
                  "s": [
                    620,
                    905.726,
                    0
                  ],
                  "to": [
                    -0.042,
                    -2.148,
                    0
                  ],
                  "ti": [
                    0.014,
                    1.318,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 1
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 32.82,
                  "s": [
                    619.75,
                    873.367,
                    0
                  ],
                  "to": [
                    -2.454,
                    -237.653,
                    0
                  ],
                  "ti": [
                    95.145,
                    53.41,
                    0
                  ]
                },
                {
                  "t": 49.9436370849609,
                  "s": [
                    235.407,
                    159.973,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                135.5,
                135.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    1,
                    1,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 14.27,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 28.539,
                  "s": [
                    90,
                    90,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 32.82,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "t": 49.9436370849609,
                  "s": [
                    96,
                    96,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 71.3480529785156,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Calque de forme 4",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 28.5392211914063,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 7.135,
                  "s": [
                    599,
                    600,
                    0
                  ],
                  "to": [
                    -34.083,
                    76.167,
                    0
                  ],
                  "ti": [
                    34.083,
                    -76.167,
                    0
                  ]
                },
                {
                  "t": 28.5392211914063,
                  "s": [
                    394.5,
                    1057,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                -104,
                -90,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    81,
                    81,
                    100
                  ]
                },
                {
                  "t": 28.5392211914063,
                  "s": [
                    490,
                    490,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "sr",
                  "sy": 1,
                  "d": 1,
                  "pt": {
                    "a": 0,
                    "k": 5,
                    "ix": 3
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 4
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "ir": {
                    "a": 0,
                    "k": 9.749,
                    "ix": 6
                  },
                  "is": {
                    "a": 0,
                    "k": 0,
                    "ix": 8
                  },
                  "or": {
                    "a": 0,
                    "k": 19.498,
                    "ix": 7
                  },
                  "os": {
                    "a": 0,
                    "k": 0,
                    "ix": 9
                  },
                  "ix": 1,
                  "nm": "Tracé polyétoile 1",
                  "mn": "ADBE Vector Shape - Star",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.105882352941,
                      0.646920776367,
                      0.886274509804,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fond 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -105,
                      -90
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Polyétoile 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 49.9436370849609,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Calque de forme 3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 28.5392211914063,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 7.135,
                  "s": [
                    599,
                    600,
                    0
                  ],
                  "to": [
                    -34.417,
                    -81.833,
                    0
                  ],
                  "ti": [
                    34.417,
                    81.833,
                    0
                  ]
                },
                {
                  "t": 28.5392211914063,
                  "s": [
                    392.5,
                    109,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                -104,
                -90,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    81,
                    81,
                    100
                  ]
                },
                {
                  "t": 28.5392211914063,
                  "s": [
                    490,
                    490,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "sr",
                  "sy": 1,
                  "d": 1,
                  "pt": {
                    "a": 0,
                    "k": 5,
                    "ix": 3
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 4
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "ir": {
                    "a": 0,
                    "k": 9.749,
                    "ix": 6
                  },
                  "is": {
                    "a": 0,
                    "k": 0,
                    "ix": 8
                  },
                  "or": {
                    "a": 0,
                    "k": 19.498,
                    "ix": 7
                  },
                  "os": {
                    "a": 0,
                    "k": 0,
                    "ix": 9
                  },
                  "ix": 1,
                  "nm": "Tracé polyétoile 1",
                  "mn": "ADBE Vector Shape - Star",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.886274509804,
                      0.105882352941,
                      0.841660204121,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fond 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -105,
                      -90
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Polyétoile 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 49.9436370849609,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Calque de forme 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 21.4044158935547,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 7.135,
                  "s": [
                    599,
                    600,
                    0
                  ],
                  "to": [
                    81.583,
                    35.167,
                    0
                  ],
                  "ti": [
                    -81.583,
                    -35.167,
                    0
                  ]
                },
                {
                  "t": 21.4044158935547,
                  "s": [
                    1088.5,
                    811,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                -104,
                -90,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    81,
                    81,
                    100
                  ]
                },
                {
                  "t": 21.4044158935547,
                  "s": [
                    490,
                    490,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "sr",
                  "sy": 1,
                  "d": 1,
                  "pt": {
                    "a": 0,
                    "k": 5,
                    "ix": 3
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 4
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "ir": {
                    "a": 0,
                    "k": 9.749,
                    "ix": 6
                  },
                  "is": {
                    "a": 0,
                    "k": 0,
                    "ix": 8
                  },
                  "or": {
                    "a": 0,
                    "k": 19.498,
                    "ix": 7
                  },
                  "os": {
                    "a": 0,
                    "k": 0,
                    "ix": 9
                  },
                  "ix": 1,
                  "nm": "Tracé polyétoile 1",
                  "mn": "ADBE Vector Shape - Star",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.105882352941,
                      0.223529411765,
                      0.886274509804,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fond 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -105,
                      -90
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Polyétoile 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 49.9436370849609,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "Calque de forme 5",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 42.809,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 49.9436370849609,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": -23,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                600,
                600,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                -20,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0,
                          -20
                        ],
                        [
                          552,
                          -20
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 1",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          20,
                          -0.5
                        ],
                        [
                          572,
                          -0.5
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -90,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 2",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          67.97,
                          0
                        ],
                        [
                          0,
                          -67.97
                        ],
                        [
                          -67.97,
                          0
                        ],
                        [
                          0,
                          67.97
                        ]
                      ],
                      "o": [
                        [
                          -67.97,
                          0
                        ],
                        [
                          0,
                          67.97
                        ],
                        [
                          67.97,
                          0
                        ],
                        [
                          0,
                          -67.97
                        ]
                      ],
                      "v": [
                        [
                          0,
                          -123.07
                        ],
                        [
                          -123.07,
                          0
                        ],
                        [
                          0,
                          123.07
                        ],
                        [
                          123.07,
                          0
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.659028116862,
                      0.853615196078,
                      0.721571380017,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fond 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -2.602,
                      -26.086
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833,
                            0.833
                          ],
                          "y": [
                            0.833,
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167,
                            0.167
                          ],
                          "y": [
                            0.167,
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          0,
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          482,
                          482
                        ]
                      }
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          100
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          0
                        ]
                      }
                    ],
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Ellipse 1",
              "np": 3,
              "cix": 2,
              "bm": 3,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.5,
                          19.75
                        ],
                        [
                          552.5,
                          19.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -180,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 3",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -19.75,
                          0.25
                        ],
                        [
                          532.25,
                          0.25
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 14.27,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -270,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 4",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          13.535,
                          -14.402
                        ],
                        [
                          565.535,
                          -14.402
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -44,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 5",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          14.675,
                          13.472
                        ],
                        [
                          566.675,
                          13.472
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -134,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 6",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 7,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -13.472,
                          14.425
                        ],
                        [
                          538.528,
                          14.425
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -224,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 7",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 8,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -14.421,
                          -13.545
                        ],
                        [
                          537.578,
                          -13.545
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 21.404,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 49.9436370849609,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -314,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 8",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 9,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 14.2696105957031,
          "op": 64.2132476806641,
          "st": 14.2696105957031,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "Calque de forme 1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 28.539,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 35.6740264892578,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                600,
                600,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                0,
                -20,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0,
                          -20
                        ],
                        [
                          552,
                          -20
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 1",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          20,
                          -0.5
                        ],
                        [
                          572,
                          -0.5
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -90,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 2",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          0.5,
                          19.75
                        ],
                        [
                          552.5,
                          19.75
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -180,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 3",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -19.75,
                          0.25
                        ],
                        [
                          532.25,
                          0.25
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 0,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      0.694393382353,
                      0,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 20,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -270,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 4",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          13.535,
                          -14.402
                        ],
                        [
                          565.535,
                          -14.402
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -44,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 5",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          14.675,
                          13.472
                        ],
                        [
                          566.675,
                          13.472
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -134,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 6",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 7,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -13.472,
                          14.425
                        ],
                        [
                          538.528,
                          14.425
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -224,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 7",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 8,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -14.421,
                          -13.545
                        ],
                        [
                          537.578,
                          -13.545
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Tracé 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tm",
                  "s": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          0
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          93
                        ]
                      }
                    ],
                    "ix": 1
                  },
                  "e": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": [
                            0.833
                          ],
                          "y": [
                            0.833
                          ]
                        },
                        "o": {
                          "x": [
                            0.167
                          ],
                          "y": [
                            0.167
                          ]
                        },
                        "t": 7.135,
                        "s": [
                          1
                        ]
                      },
                      {
                        "t": 35.6740264892578,
                        "s": [
                          100
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "o": {
                    "a": 0,
                    "k": 0,
                    "ix": 3
                  },
                  "m": 1,
                  "ix": 2,
                  "nm": "Réduire les tracés 1",
                  "mn": "ADBE Vector Filter - Trim",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0,
                      1,
                      0.672334558824,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 10,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Contour 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": -314,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transformer "
                }
              ],
              "nm": "Forme 8",
              "np": 4,
              "cix": 2,
              "bm": 0,
              "ix": 9,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 49.9436370849609,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_2",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "Objet dynamique vectoriel",
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 14.2696105957031,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 52.798,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    1.667,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 55.651,
                  "s": [
                    762,
                    1120,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 58.505,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    -3.333,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 61.359,
                  "s": [
                    742,
                    1120,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    -3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 64.213,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    3.333,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 67.067,
                  "s": [
                    762,
                    1120,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 69.921,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    -3.333,
                    0,
                    0
                  ],
                  "ti": [
                    1.667,
                    28,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 72.775,
                  "s": [
                    742,
                    1120,
                    0
                  ],
                  "to": [
                    -1.667,
                    -28,
                    0
                  ],
                  "ti": [
                    0,
                    28,
                    0
                  ]
                },
                {
                  "t": 85.6176635742188,
                  "s": [
                    742,
                    952,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                579,
                948,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    6,
                    6,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 35.674,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 38.528,
                  "s": [
                    110,
                    110,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 41.382,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 44.236,
                  "s": [
                    105,
                    105,
                    100
                  ]
                },
                {
                  "t": 47.0897149658203,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 171.235327148438,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "Groupe 1 copie 2",
          "refId": "image_2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 97.033,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 114.157,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 131.28,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 148.403950195313,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                476,
                654.5,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                446,
                422.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 92.7524688720703,
          "op": 171.235327148438,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "Groupe 1 copie",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 89.899,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 107.022,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 124.146,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 141.269144897461,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                1020,
                654.5,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                446,
                422.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 85.6176635742188,
          "op": 171.235327148438,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 2,
          "nm": "Groupe 1",
          "refId": "image_4",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 72.775,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 89.899,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 107.022,
                  "s": [
                    50
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 124.146,
                  "s": [
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 141.269,
                  "s": [
                    50
                  ]
                },
                {
                  "t": 158.392677612305,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                729.5,
                753.5,
                0
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                601.5,
                635.5,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 69.9210919189453,
          "op": 171.235327148438,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 2,
          "nm": "Calque 6",
          "refId": "image_5",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 14.27,
                  "s": [
                    41.961
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 72.775,
                  "s": [
                    41.961
                  ]
                },
                {
                  "t": 81.3367803955078,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 52.798,
                  "s": [
                    752,
                    1110,
                    0
                  ],
                  "to": [
                    1.667,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 55.651,
                  "s": [
                    762,
                    1110,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 58.505,
                  "s": [
                    752,
                    1110,
                    0
                  ],
                  "to": [
                    -3.333,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 61.359,
                  "s": [
                    742,
                    1110,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    -3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 64.213,
                  "s": [
                    752,
                    1110,
                    0
                  ],
                  "to": [
                    3.333,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 67.067,
                  "s": [
                    762,
                    1110,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 69.921,
                  "s": [
                    752,
                    1110,
                    0
                  ],
                  "to": [
                    -3.333,
                    0,
                    0
                  ],
                  "ti": [
                    1.667,
                    20.333,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 72.775,
                  "s": [
                    742,
                    1110,
                    0
                  ],
                  "to": [
                    -1.667,
                    -20.333,
                    0
                  ],
                  "ti": [
                    0,
                    20.333,
                    0
                  ]
                },
                {
                  "t": 81.3367803955078,
                  "s": [
                    742,
                    988,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                245,
                371,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    6,
                    6,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 35.674,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 38.528,
                  "s": [
                    110,
                    110,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 41.382,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 44.236,
                  "s": [
                    105,
                    105,
                    100
                  ]
                },
                {
                  "t": 47.0897149658203,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 171.235327148438,
          "st": 0,
          "bm": 1
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 2,
          "nm": "Objet dynamique vectoriel",
          "refId": "image_6",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    0
                  ]
                },
                {
                  "t": 14.2696105957031,
                  "s": [
                    100
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 52.798,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    1.667,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 55.651,
                  "s": [
                    762,
                    1120,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 58.505,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    -3.333,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 61.359,
                  "s": [
                    742,
                    1120,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    -3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 64.213,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    3.333,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 67.067,
                  "s": [
                    762,
                    1120,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    3.333,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "t": 69.921,
                  "s": [
                    752,
                    1120,
                    0
                  ],
                  "to": [
                    -3.333,
                    0,
                    0
                  ],
                  "ti": [
                    1.667,
                    0,
                    0
                  ]
                },
                {
                  "t": 72.7750140380859,
                  "s": [
                    742,
                    1120,
                    0
                  ]
                }
              ],
              "ix": 2
            },
            "a": {
              "a": 0,
              "k": [
                576.5,
                831,
                0
              ],
              "ix": 1
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 7.135,
                  "s": [
                    6,
                    6,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 35.674,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 38.528,
                  "s": [
                    110,
                    110,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 41.382,
                  "s": [
                    100,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 44.236,
                  "s": [
                    105,
                    105,
                    100
                  ]
                },
                {
                  "t": 47.0897149658203,
                  "s": [
                    100,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 171.235327148438,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "points",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1396,
            1888,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            510,
            590,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1020,
      "h": 1180,
      "ip": 96.0000712835328,
      "op": 167.348124262048,
      "st": 96.0000712835328,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 0,
      "nm": "Composition 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 22,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            2028,
            2184,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            600,
            600,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1200,
      "h": 1200,
      "ip": 118.000087619342,
      "op": 167.943724704303,
      "st": 118.000087619342,
      "bm": 3
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 0,
      "nm": "Composition 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 22,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            2028,
            2184,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            600,
            600,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1200,
      "h": 1200,
      "ip": 108.000080193974,
      "op": 157.943717278935,
      "st": 108.000080193974,
      "bm": 3
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 0,
      "nm": "Composition 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 45,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            956,
            2136,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            600,
            600,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1200,
      "h": 1200,
      "ip": 98.0000727686064,
      "op": 147.943709853567,
      "st": 98.0000727686064,
      "bm": 3
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 0,
      "nm": "Composition 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 45,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            956,
            2136,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            600,
            600,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1200,
      "h": 1200,
      "ip": 88.0000653432384,
      "op": 137.943702428199,
      "st": 88.0000653432384,
      "bm": 3
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 0,
      "nm": "Composition 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 45,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1584,
            2408,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            600,
            600,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            101,
            101,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1200,
      "h": 1200,
      "ip": 79.0000586604072,
      "op": 128.943695745368,
      "st": 79.0000586604072,
      "bm": 3
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 0,
      "nm": "Composition 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1776,
            1736,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            600,
            600,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            71,
            71,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1200,
      "h": 1200,
      "ip": 69.0000512350392,
      "op": 118.94368832,
      "st": 69.0000512350392,
      "bm": 3
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 0,
      "nm": "coffre destFinale1",
      "refId": "comp_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1472,
            2408,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            750,
            800,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "w": 1500,
      "h": 1600,
      "ip": 0,
      "op": 171.235327148438,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
