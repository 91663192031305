import React, {useEffect, useRef, useState} from 'react'
import styled from '@emotion/native'
import {findAvailableMoves} from './Tools'
import {Animated, Platform, View} from 'react-native'
import {colors} from '@civitime/library/storybook/configs/colors'
import {useProfile} from '../../../Auth/useProfile'
import {AvatarComponent} from '@civitime/library/storybook/stories/Avatar'
import LottieView from 'lottie-react-native'
import {calculateActualActionPoints} from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers'
import {useCampaign} from '../../../GlobalContexts'
import Down from '../../../../assets/down.svg'
import DownLeft from '../../../../assets/downLeft.svg'
import DownRight from '../../../../assets/downRight.svg'
import Left from '../../../../assets/left.svg'
import UpLeft from '../../../../assets/upLeft.svg'

const tutoAssets = {
  "down": Down,
  "downLeft": DownLeft,
  "downRight": DownRight,
  "left": Left,
  "upLeft": UpLeft,
}

export const MapUi = ({
                        mapData,
                        tilesNumbers,
                        tileWidth,
                        tileHeight,
                        campaignId,
                        rawMapData,
                        playerPosition,
                        mapAssets,
                        playerAsset,
                        mapState,
                        modulesEnded,
                        tileHovered,
                        tilesMode,
                        userState,
                        normalMove,
                        openFinalDestinationDiscovered,
                        showPlayer,
                        pawnStatus,
                        panValue,
                        screenWidth,
                        screenHeight,
                        mobile,
                        screenScale,
                        zoomLevel
                      }) => {

  const {publicProfile, emitCommand, userSession} = useProfile()
  const {campaignSettings} = useCampaign()
  const finalDestinationAnim = require('../Animations/FinalDestination/data.js')
  const finalDestinationRef = useRef(null)

  const PAAnimation1 = require('../Animations/PA/1pa/1pa.js')
  const PAAnimation1Ref = useRef(null)
  const PAAnimation2 = require('../Animations/PA/2pa/2pa.js')
  const PAAnimation2Ref = useRef(null)

  const flagAnimation = require('../Animations/Flag/data.js')
  const flagAnimationRef = useRef(null)

  const portalAnimation = require('../Animations/Portal/data.js')
  const portalAnimationRef = useRef(null)

  const mapWidth = tileWidth * tilesNumbers

  const [arrowTutoAnimation,] = useState(new Animated.Value(0.2))
  const runAnimation = useRef(false)

  const launchAnimation1 = () => {
    Animated.sequence([
      Animated.timing(arrowTutoAnimation, {toValue: 1, duration: 800, useNativeDriver: true}),
      Animated.timing(arrowTutoAnimation, {toValue: 0.2, duration: 800, useNativeDriver: true}),
      Animated.timing(arrowTutoAnimation, {toValue: 1, duration: 800, useNativeDriver: true}),
      Animated.timing(arrowTutoAnimation, {toValue: 0.2, duration: 800, useNativeDriver: true}),
    ]).start(() => {
      return runAnimation.current && launchAnimation1()
    })
  }

  useEffect(() => {
    if (userSession) {
      runAnimation.current = false
      return
    }
    runAnimation.current = true
    launchAnimation1()
  }, [userSession])

  const actionPoints = calculateActualActionPoints(campaignSettings?.actionPointsPerDay,
    campaignSettings?.maxActionPoints,
    userState?.actionPoints,
    new Date().toISOString(),
    campaignSettings?.startAt
  )
  useEffect(() => {
    if (normalMove) {
      setTimeout(() => {
        PAAnimation1Ref?.current?.play()
        PAAnimation2Ref?.current?.play()
      }, 300)
    }
  }, [normalMove, playerPosition])

  const finalDestinationModalHandler = () => {
    if (!userState?.finalDestinationModalSeen) {
      openFinalDestinationDiscovered()
      showPlayer()
      emitCommand({
        type: 'SeeFinalDestinationModal',
        payload: {}
      })
    } else {
      showPlayer()
    }
  }

  return <>
    {
      !tilesMode &&
      <MapBackground source={mapAssets[0]}
                     style={{
                       width: (tilesNumbers * tileWidth),
                       height: (tilesNumbers * tileHeight),
                     }}/>
    }
    {
      mapData?.flatMap((line, indexLine) => line
        .map((tile, i) => ({
          tile: tile,
          line: indexLine,
          lineLength: line.length,
          position: i
        }))
        .flat())
        .map((tile, indexTile) => {
          const tileRef = rawMapData?.tilesets?.filter((asset) => asset?.firstgid === tile?.tile)?.[0]
          const tileProperties = tileRef?.properties || []
          const positionX = ((tile.position * tileWidth) + (-(tileWidth / 2) * (tile.lineLength - 1))) - tileWidth / 2
          const positionY = (tile.line * (tileHeight / 2))
          const isRiver = tileProperties.filter(v => v?.value === 'river').length
          const interactiveTutoPosition = calculateInteractiveTutoPosition(playerPosition)
          const AssetTuto = tutoAssets[interactiveTutoPosition?.asset] || null
          return tilesMode
            && virtualScroll(mapState, tileWidth * 20, tileHeight * 20, screenWidth, screenHeight, panValue.x, panValue.y, tile.line, tile.position, mobile, zoomLevel, screenScale)
            && <WrapperTile
              key={indexTile + 'tile' + 'Wrapper list'}
              positionX={positionX}
              positionY={positionY}
              width={mapWidth}
              style={{
                width: tileRef?.imagewidth,
                height: tileRef?.imageheight
              }}
            >
              <View style={{position: 'relative'}}>
                {
                  tilesMode &&
                  <Tile
                    source={{
                      uri: mapState?.[tile.line]?.[tile.position] ?
                        (
                          userState?.fragments === 6 && tileProperties.some((p) => p?.name === 'finalDestination') ?
                            mapAssets['fd-tile'] ? mapAssets['fd-tile'] :
                              `https://ct-campaigns.civitimeapp.com/${campaignId}/map/images/tile-arrive-fd.png`
                            : mapAssets[mapData[tile.line][tile.position]] ? mapAssets[mapData[tile.line][tile.position]] : ''
                        )
                        : '',
                      cache: 'only-if-cached'
                    }}
                    key={indexTile + 'tile'}
                    style={{
                      position: 'absolute',
                      width: tileRef?.imagewidth,
                      height: tileRef?.imageheight,
                      top: 0,
                      left: 0,
                      transform: [{
                        translateY: (tileHeight - tileRef?.imageheight) < 0 ?
                          Math.round(tileHeight - tileRef?.imageheight)
                          : 0
                      }]
                    }}
                  />
                }
                {
                  userState?.finalDestinationDiscovered &&
                  tileProperties.some((p) => p?.name === 'finalDestination')
                  && Platform.OS === 'web' ?
                    <>
                      <FinalDestinationAnimation source={finalDestinationAnim?.default}
                                                 tileHeight={tileHeight}
                                                 width={tileWidth}
                                                 height={tileHeight}
                                                 ref={finalDestinationRef}
                                                 autoPlay
                                                 onAnimationFinish={finalDestinationModalHandler}
                      />
                    </>
                    : null
                }
                {
                  (actionPoints >= (isRiver ? 2 : 1) &&
                    tileProperties.some((p) => p?.name === 'practicable') &&
                    findAvailableMoves(tile.line, tile.position, playerPosition, tilesNumbers)
                    || !mapState?.[tile.line]?.[tile.position] && !tilesMode)
                  ||
                  (!findAvailableMoves(tile.line, tile.position, playerPosition, tilesNumbers) &&
                    !mapState?.[tile.line]?.[tile.position])
                    ? <OverlayMovementsAvailable
                      width={tileWidth}
                      height={tileHeight}
                    >
                      <Tri1 hover={tileHovered?.line === tile.line && tileHovered?.tile === tile.position}
                            isFog={!mapState?.[tile.line]?.[tile.position]}
                      />
                      <Tri2 hover={tileHovered?.line === tile.line && tileHovered?.tile === tile.position}
                            isFog={!mapState?.[tile.line]?.[tile.position]}
                      />
                      {
                        displayedInteractiveTuto(!userSession, interactiveTutoPosition, tile, AssetTuto) &&
                        <WrapperInteractiveTuto width={tileWidth}
                                                height={tileHeight}
                                                style={{
                                                  opacity: arrowTutoAnimation
                                                }}>
                          <AssetTuto width="20%" height="20%"/>
                        </WrapperInteractiveTuto>

                      }
                    </OverlayMovementsAvailable>
                    : null
                }
                {
                  tile.line === playerPosition.line &&
                  tile.position === playerPosition.tile &&
                  tileProperties.some((p) => p?.value === 'river') ?
                    <PAAnimation source={PAAnimation2?.default}
                                 ref={PAAnimation2Ref}
                                 width={tileWidth}
                                 height={tileHeight}
                    /> :
                    tile.line === playerPosition.line &&
                    tile.position === playerPosition.tile ?
                      <PAAnimation source={PAAnimation1?.default}
                                   ref={PAAnimation1Ref}
                                   width={tileWidth}
                                   height={tileHeight}
                      /> : null
                }

                {
                  tileProperties.some((p) => p?.name === 'practicable') &&
                  tileProperties.some((p) => p?.name === 'module') &&
                  modulesEnded.some((position) => tile.line === position.line && tile.position === position.tile) ?
                    <FlagAnimation source={flagAnimation?.default}
                                   width={323}
                                   height={471}
                                   autoPlay
                                   ref={flagAnimationRef}
                    />
                    : null
                }
                {
                  tileProperties.some((p) => p?.value === 'portal') &&
                  mapState?.[tile.line]?.[tile.position] ?
                    <PortalAnimation source={portalAnimation?.default}
                                     width={600}
                                     height={450}
                                     autoPlay
                                     loop
                                     ref={portalAnimationRef}
                    />
                    : null
                }
                {
                  !pawnStatus && tile.line === playerPosition?.line && tile.position === playerPosition?.tile ?
                    <WrapperPlayer width={tileRef?.imagewidth}
                                   height={tileRef?.imageheight}
                    >
                      <WrapperPawn>
                        <PlayerPawn source={playerAsset || ''} alt={'player_pawn'}/>
                        <PawnAvatar circleAvatarConfig={{
                          size: 145,
                          height: 140,
                          width: 143,
                          withBorder: true,
                          backgroundColor: colors.extraLightBackground,
                        }}
                                    avatar={publicProfile?.avatar}
                        />
                      </WrapperPawn>
                    </WrapperPlayer>
                    : null
                }
              </View>
            </WrapperTile>
        })
    }
  </>
}

const displayedInteractiveTuto = (userSessionExist, interactiveTutoPosition, playerPosition, AssetTuto) => {
  return userSessionExist && AssetTuto && interactiveTutoPosition?.position?.tile === playerPosition.position &&
    interactiveTutoPosition?.position?.line === playerPosition.line
}

export const virtualScroll = (mapState, mapWidth, mapHeight, screenWidth, screenHeight, panX, panY, tileIndexLine, tileIndexPosition, mobile, zoomLevel, screenScale) => {
  const maxTile = 20
  const maxLine = 40
  const offset = 3
  const minLineY = Math.floor((Math.abs(panY) * maxLine) / (mapHeight * screenScale)) - offset
  const maxLineY = Math.ceil(((Math.abs(panY) + (screenHeight)) * maxLine) / (mapHeight * screenScale)) + offset
  const minIndexX = Math.floor((Math.abs(panX) * maxTile) / (mapWidth * screenScale)) - offset
  const maxIndexX = Math.ceil(((Math.abs(panX) + screenWidth) * maxTile) / (mapWidth * screenScale)) + offset
  const rebasePlayerXPos = Math.ceil(tileIndexPosition + (maxTile - mapState[tileIndexLine].length) / 2)
  return tileIndexLine >= minLineY &&
    tileIndexLine <= maxLineY &&
    rebasePlayerXPos >= minIndexX &&
    rebasePlayerXPos <= maxIndexX
}

const WrapperInteractiveTuto = styled(Animated.View)(({width, height}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: width,
  height: height,
  justifyContent: 'center',
  alignItems: 'center'
}))

const PortalAnimation = styled(LottieView)({
  position: 'absolute',
  top: -100,
}, ({width, height}) => ({
  width: width,
  height: height
}))

const FlagAnimation = styled(LottieView)({
  position: 'absolute',
  right: -200,
  top: -278,
}, ({width, height}) => ({
  width: width,
  height: height
}))

export const PAAnimation = styled(LottieView)({
  position: 'absolute',
  top: -250,
  right: -270,
}, ({width, height}) => ({
  width: width * 1.4,
  height: height * 1.4,
}))

export const FinalDestinationAnimation = styled(LottieView)({
  position: 'absolute',
}, ({tileHeight, width}) => ({
  top: -tileHeight,
  width: width,
  height: 800,
}))

const MapBackground = styled.Image({
  position: 'absolute',
  left: 0,
  top: 0
})

const Tile = styled.Image({})

const PlayerPawn = styled.Image({
  width: 300,
  height: 300
})

const WrapperTile = styled.View({
  position: 'absolute',
}, ({width, positionX, positionY}) => ({
  left: width / 2,
  transform: [
    {
      translateX: positionX
    },
    {
      translateY: positionY
    }
  ],
}))

const WrapperPlayer = styled.TouchableOpacity({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  transform: [{translateY: -50}]
}, ({width, height}) => ({
  width: width,
  height: height
}))

const OverlayMovementsAvailable = styled.TouchableOpacity(({width, height}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: width,
  height: height
}))

export const Tri1 = styled.View({
  width: 0,
  height: 0,
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
}, ({hover, isFog}) => ({
  borderTopWidth: 0,
  borderLeftWidth: isFog ? 300 : 285,
  borderRightWidth: isFog ? 300 : 285,
  borderBottomWidth: isFog ? 175 : 160,
  borderBottomColor: hover ? 'orange' : isFog ? '#153042' : '#FFF94F',
  opacity: isFog ? 1 : 0.3,
}))

export const Tri2 = styled.View({
  width: 0,
  height: 0,
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
  borderBottomColor: 'transparent',
}, ({hover, isFog}) => ({
  borderBottomWidth: 0,
  borderLeftWidth: isFog ? 300 : 285,
  borderRightWidth: isFog ? 300 : 285,
  borderTopWidth: isFog ? 175 : 160,
  borderTopColor: hover ? 'orange' : isFog ? '#153042' : '#FFF94F',
  opacity: isFog ? 1 : 0.3,
}))

const WrapperPawn = styled.View({
  position: 'relative',
  alignItems: 'center',
})

const PawnAvatar = styled(AvatarComponent)({
  position: 'absolute',
  transform: [{rotateY: '-26deg'}, {rotateX: '-26deg'}, {skew: '-9deg, 16deg'}],
  top: 30,
  left: 73,
})

const calculateInteractiveTutoPosition = (playerPosition) => {
  if (checkPlayerPositionEquality(playerPosition, [
    {line: 31, tile: 1},
    {line: 30, tile: 1},
    {line: 29, tile: 1},
    {line: 28, tile: 1},
    {line: 27, tile: 1},])) {
    return {
      position: {line: playerPosition.line - 1, tile: playerPosition.tile},
      asset: 'upLeft'
    }
  } else if (
    checkPlayerPositionEquality(playerPosition, [
      {line: 29, tile: 2},
      {line: 28, tile: 2},
      {line: 27, tile: 2},
      {line: 26, tile: 2},])
  ) {
    return {
      position: {line: playerPosition.line, tile: playerPosition.tile - 1},
      asset: 'left'
    }
  } else if (checkPlayerPositionEquality(playerPosition, [{line: 25, tile: 1}])) {
    return {
      position: {line: playerPosition.line + 1, tile: playerPosition.tile},
      asset: 'downRight'
    }
  } else if (
    checkPlayerPositionEquality(playerPosition,
      [{line: 25, tile: 2}, {line: 25, tile: 3}])
  ) {
    return {
      position: {line: playerPosition.line + 1, tile: playerPosition.tile - 1},
      asset: 'downLeft'
    }
  } else if (checkPlayerPositionEquality(playerPosition, [{line: 24, tile: 2}])) {
    return {
      position: {line: playerPosition.line + 2, tile: playerPosition.tile - 1},
      asset: 'down'
    }
  } else {
    return null
  }
}

const checkPlayerPositionEquality = (playerPosition, tilePositions) => {
  return tilePositions.some((position) => playerPosition?.line === position.line && playerPosition?.tile === position.tile)
}
