import React from "react"
import styled from "@emotion/native"
import { useTranslator } from "../../translations/translate"

import { colors }                   from "@civitime/library/storybook/configs/colors"
import { AvatarComponent }          from "@civitime/library/storybook/stories/Avatar"
import { LeaderboardBlazon }        from "@civitime/library/storybook/stories/Blazon"
import { LittleTextButton }         from "@civitime/library/storybook/stories/Button"
import { Points }                   from "@civitime/library/storybook/stories/Leaderboard"
import { TouchableWithoutFeedback } from "react-native"
import { calculateDisplayedName }   from './PlayerRankingListItem'

export const TopRanking = ({
  type,
  data,
  rank,
  teamMax = 0,
  mobile,
  orientation,
  onPress,
  me,
  ...props
}) => {
  const { t } = useTranslator()
  const name = calculateDisplayedName(data?.name)

  return (
    <TouchableWithoutFeedback disabled={type === "team"} {...{ onPress }}>
      <WrapperTopRanking {...props} first={rank === 1} {...{ mobile, me }}>
        {type === "team" && (
          <LeaderboardBlazon
            {...{ rank }}
            blazon={data.logo.shape}
            color={data.logo.color}
            name={mobile ? name : data.name}
            text={data.logo.text}
            size={mobile ? 40 : 50}
          />
        )}
        {type === "player" && (
          <AvatarComponent
            {...{ rank }}
            playerName={mobile ? name : data.name}
            circleAvatarConfig={{
              size: mobile ? 30 : 50,
              withBorder: true,
              backgroundColor: colors.extraLightBackground,
            }}
            cartridgeConfig={{
              width: 128,
              height: 20,
              backgroundColor: colors.text,
              color: colors.lightText,
            }}
            avatar={data.avatar}
          />
        )}
        {type === "team" && (
          <LittleTextButton hoverColor={colors.lightBlue} {...{ onPress }}>
            {data.members.length}/{teamMax}
            {!mobile &&
              (data.members.length > 1
                ? " " + t("app.team.members")
                : " " + t("app.team.member"))}
          </LittleTextButton>
        )}
        <Points count={data.points} />
      </WrapperTopRanking>
    </TouchableWithoutFeedback>
  )
}

const WrapperTopRanking = styled.View(
  {
    flexDirection: "column",
    alignItems: "center",
  },
  ({ first, mobile }) =>
    first
      ? {
          alignSelf: "flex-start",
        }
      : {
          marginTop: mobile ? 20 : 50,
        },
  ({ me }) =>
    me && {
      paddingVertical: 2,
      paddingHorizontal: 5,
      backgroundColor: colors.darkerBackground,
      boxShadow: "-1px -1px 4px #FFFFFF, 1px 1px 3px rgba(94, 104, 121, 0.945)",
    }
)
