import React, { useEffect, useState } from "react"
import { firebaseAccessor } from "../firebase"

export const useDataLoading = (
  path,
  defaultValue = null,
  alreadyLoaded = false
) => {
  const [data, setData] = useState(defaultValue)
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(alreadyLoaded)

  useEffect(() => {
    if (loaded) return
    let canceled = false
    firebaseAccessor
      .get(path)
      .then((v) => {
        if (canceled) return
        setData(v)
      })
      .catch((e) => {
        setError(e)
        console.error("failed to load data", e)
      })
      .then(() => setLoaded(true))

    return () => {
      canceled = true
    }
  }, [path])

  return { data, loaded, error }
}

const cache = {}
/**
 * Use cache and doesn't load remote data if cache exists
 */
export const useCachedData = (path, defaultValue) => {
  const { data, loaded } = useDataLoading(
    path,
    cache[path] || defaultValue,
    path in cache
  )

  if (loaded && data !== cache[path]) {
    cache[path] = data
  }
  if (loaded) return data
  if (path in cache) return cache[path]
  return defaultValue
}
/**
 * Use cache and load remote data (refresh cache after loading)
 */
export const usePreCachedData = (path, defaultValue) => {
  const { data, loaded } = useDataLoading(path, defaultValue)

  if (loaded && data !== cache[path]) {
    cache[path] = data
  }
  if (loaded) return data
  if (path in cache) return cache[path]
  return defaultValue
}

export const useRealTimeData = (path) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    const sub = firebaseAccessor
      .getSync(path, (snap) => {
        setData(snap)
      })
      .subscribe((value) => {
        if(value){
          setData({ ...value, loaded: true })
        }
      })

    return () => {
      sub.unsubscribe()
    }
  }, [path])

  return data
}
