import React from "react"
import styled from "@emotion/native"
import { T, useTranslator } from "../../translations/translate"
import { useCampaign } from "../../GlobalContexts"

import { colors } from "@civitime/library/storybook/configs/colors"
import { Blazon } from "@civitime/library/storybook/stories/Blazon"
import { PressedInCard } from "@civitime/library/storybook/stories/Cards"
import {
  TextButton,
  LittleTextButton,
} from "@civitime/library/storybook/stories/Button"
import {
  BoldText,
  SmallText,
  Title2,
} from "@civitime/library/storybook/stories/Texts"

export const CardTeam = ({
  team,
  index,
  mobile,
  orientation,
  teamMax,
  setAlertJoinTeam,
  setJoinTeamId,
  createButton
}) => {
  const { t } = useTranslator()
  const { campaignNavigate } = useCampaign()

  return (
    <WrapperTeam key={index + "team to choose"} {...{ mobile, orientation, createButton }}>
      <Blazon
        size={40}
        blazon={team.logo.shape}
        color={team.logo.color}
        text={team.logo.text}
        style={{ marginBottom: 10 }}
      />
      <WrapperPressInTeam
        noCursor
        customPadding={mobile && orientation === "portrait" ? 10 : 20}
        teamFull={team.members.length === teamMax}
      >
        <Title2>{team.name}</Title2>
        {team.members.length === teamMax ? (
          <LittleTextButton disabled noHover>
            <TeamFullText>
              <T path="app.team.teamFull" />
            </TeamFullText>
          </LittleTextButton>
        ) : (
          <LittleTextButton
            onPress={() => campaignNavigate("team/" + team.name, team)}
          >
            <BoldText>{team?.members?.length}</BoldText>
            <SmallText>
              /{teamMax}
              {team?.members?.length > 1
                ? " " + t("app.team.members")
                : " " + t("app.team.member")}
            </SmallText>
          </LittleTextButton>
        )}
        <TextButton
          onPress={() => {
            setAlertJoinTeam(true)
            setJoinTeamId(team.id)
          }}
          backgroundColor={colors.defaultBackground}
          disabled={team.members.length === teamMax}
        >
          <T path="app.team.join" />
        </TextButton>
      </WrapperPressInTeam>
    </WrapperTeam>
  )
}

const WrapperTeam = styled.View(
  {
    alignItems: "center",
  },
  ({ mobile, orientation, createButton }) => ({
    marginVertical: mobile && orientation === "portrait" ? 10 : 20,
    flexDirection: !createButton  && mobile && orientation === "portrait" ? "row" : "column",
    ...(mobile &&
      orientation === "portrait" && {
        justifyContent: "space-evenly",
      }),
  })
)

const WrapperPressInTeam = styled(PressedInCard)(
  ({ teamFull }) =>
    teamFull && {
      backgroundColor: colors.disabledBackground,
      boxShadow: "none",
    }
)

const TeamFullText = styled(BoldText)({
  textTransform: "uppercase",
})
