import React, { useState } from "react"
import { TouchableOpacity } from "react-native"
import styled from "@emotion/native"
import { T, useTranslator } from "../../translations/translate"
import { useCampaign } from "../../GlobalContexts"
import { authClient } from "../authClient"
import {
  AuthCard,
  AuthContent,
  AuthFooter,
}                                 from "@civitime/library/storybook/stories/Cards";
import { Input, InputRow }        from "@civitime/library/storybook/stories/Inputs";
import { TextButton }             from "@civitime/library/storybook/stories/Button";
import { colors }                 from "@civitime/library/storybook/configs/colors";
import { BaseText, BoldText }     from "@civitime/library/storybook/stories/Texts";
import { MailIcon }               from "@civitime/library/storybook/stories/Icons";
import { Dialog }                 from "@civitime/library/storybook/stories/Dialog";
import { clientId }               from '../../../clientId'
import { useLocation }            from 'react-router'
import { AuthLangSelectorButton } from './Auth'

export const ResetPassword = ({openLangSelector, campaignSettings}) => {
  const { t, lang } = useTranslator();
  const { campaignNavigate } = useCampaign();
  const [email, setEmail] = useState("");
  const [modalOpened, setModalOpened] = useState(false);
  const location = useLocation()
  const campaignId = location.pathname.split('/')[1]
  const canSubmit = email && true;

  const askResetPassword = async () => {
    if (!email) return
    setModalOpened(true)
    await authClient.askResetPassword(email, clientId, campaignId, lang)
  }

  return (
    <AuthCard>
      {campaignSettings?.internationalized && <AuthLangSelectorButton size={40}
                               iconSize={20}
                               onPress={openLangSelector}
                               text={lang}/>}
      <AuthContent>
        <Title>
          <T path={"app.auth.titles.forgotPassword"} />
        </Title>

        <LabelInputWrapper>
          <LabelInput>
            <T path={"app.auth.fields.typeEmail"} />
          </LabelInput>
        </LabelInputWrapper>
        <InputRow>
          <Input
            name="email"
            autofocus={true}
            icon={<MailIcon width={17} height={17} color={colors.text} />}
            placeholder={t("app.auth.fields.email")}
            onChangeText={setEmail}
            value={email}
            keyboardType="email-address"
            autoCompleteType="email"
            returnKeyType="send"
            onSubmitEditing={() => canSubmit && askResetPassword()}
          />
        </InputRow>

        <ActionsWrapper>
          <TextButton
            onPress={() => canSubmit && askResetPassword()}
            disabled={!canSubmit}
          >
            <T path={"app.auth.actions.forgotPassword"} />
          </TextButton>
        </ActionsWrapper>
      </AuthContent>

      <AuthFooter>
        <TouchableOpacity onPress={() => campaignNavigate("login")}>
          <BoldText>
            <T path={"app.auth.links.backToLoginFromReset"} />
          </BoldText>
        </TouchableOpacity>
      </AuthFooter>

      <Dialog
        visible={modalOpened}
        closable={true}
        onRequestClose={() => {
          setModalOpened(false)
          setEmail("")
        }}
      >
        <ModalText>
          <T path={"app.auth.alerts.resetPasswordEmailSent"} data={{ email }} />
        </ModalText>
        <ModalText style={{ marginTop: 10 }}>
          <T path={"app.auth.alerts.resetPasswordFollowInstructions"} />
        </ModalText>
      </Dialog>
    </AuthCard>
  )
}

const ActionsWrapper = styled.View({
  flexDirection: "row",
  justifyContent: "space-around",
  paddingTop: 60,
})

const LabelInputWrapper = styled.View({
  alignItems: "flex-start",
  marginTop: 40,
})

const LabelInput = styled(BaseText)({
  fontFamily: "Raleway_600SemiBold",
  fontSize: 15,
  lineHeight: 18,
})

const Title = styled(BaseText)({
  fontSize: 21,
  lineHeight: 25,
})

const ModalText = styled(BaseText)({
  fontSize: 18,
  lineHeight: 21,
})
