import React, { useEffect, useState } from 'react'
import { Input, InputRow }            from '@civitime/library/storybook/stories/Inputs'
import { PasswordIcon }               from '@civitime/library/storybook/stories/Icons'
import { colors }                     from '@civitime/library/storybook/configs/colors'
import { AuthCard, AuthContent }      from '@civitime/library/storybook/stories/Cards'
import { T, useTranslator }           from '../../translations/translate'
import { ActionsWrapper, Title }      from './Signup'
import { TextButton }                 from '@civitime/library/storybook/stories/Button'
import { ErrorAlert, ErrorText }      from '@civitime/library/storybook/stories/Error'
import { authClient }                 from '../authClient'
import { useLocation }                from 'react-router'
import { AuthLangSelectorButton }     from './Auth'

export const ChangePassword = ({campaignSettings, openLangSelector}) => {
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState({})
  const { t, lang } = useTranslator();
  const location = useLocation()

  useEffect(() => {
    setSubmitted(false)
    if (password.length <= 6) {
      setError((e) => ({ ...e, 'app.auth.errors.passwordTooShort': true }))
    } else {
      setError((e) => ({ ...e, 'app.auth.errors.passwordTooShort': false }))
    }
    if (password !== confirmedPassword) {
      setError((e) => ({ ...e, 'app.auth.errors.differentPasswords': true }))
    } else {
      setError((e) => ({ ...e, 'app.auth.errors.differentPasswords': false }))
    }
  }, [password, confirmedPassword])

  const submit = async () => {
    const campaignId = location.pathname.split('/')[1]
    setSubmitted(true)
    if (!Object?.entries(error).filter(([_, v]) => !!v).length) {
      setError({})
      const token = location.search.replace('?token=', '')
      const response = await authClient.resetPassword(token, password, campaignId)
    }
  }
  return <AuthCard>
    {campaignSettings?.internationalized && <AuthLangSelectorButton size={40}
                                                                    iconSize={30}
                                                                    onPress={openLangSelector}
                                                                    text={lang}/>}
    <AuthContent>
      <Title>
        <T path={'app.auth.titles.changePassword'}/>
      </Title>

      {submitted && Object?.entries(error).filter(([_, v]) => !!v).map(([e, v]) => {
        return <ErrorAlert>
          <ErrorText>
            <T path={e} data={{ 0: 6 }}/>
          </ErrorText>
        </ErrorAlert>
      })
      }

      <InputRow>
        <Input
          name="password"
          icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
          placeholder={t('app.auth.fields.password')}
          secureTextEntry={true}
          onChangeText={setPassword}
          value={password}
        />
      </InputRow>
      <InputRow>
        <Input
          name="password"
          icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
          placeholder={t('app.auth.fields.password')}
          secureTextEntry={true}
          onChangeText={setConfirmedPassword}
          value={confirmedPassword}
        />
      </InputRow>
      <ActionsWrapper>
        <TextButton
          color={colors.action}
          backgroundColor={colors.extraLightBackground}
          onPress={(e) => submit(e)}
        >
          <T path={'app.auth.actions.changePassword'}/>
        </TextButton>
      </ActionsWrapper>
    </AuthContent>
  </AuthCard>
}
