import React, { createContext, useContext, useEffect, useState } from "react"
import { Auth }                                                  from "./Auth/useProfile"
import { Router }                                                from "react-router-native"
import { history }                                               from "./Router/history.web"
import { TranslationCampaignProvider }                           from "./translations/translate"
import { generatePath }                                          from "react-router-native"
import { firebaseAccessor }                                      from "./firebase"
import { clientId }                                              from "../clientId"
import {clearedOffsetDate}                                       from '@civitime/game-server/lib/Domain/ActionPoints/ActionPoints.helpers'

export const GlobalContexts = ({ children }) => (
  <TranslationCampaignProvider>
    <ClientProvider>
      <Auth>
        <Router history={history}>{children}</Router>
      </Auth>
    </ClientProvider>
  </TranslationCampaignProvider>
)

const getCampaignSettings = async (clientId, campaignId) => {
  return await firebaseAccessor.get(
    `clients/${clientId}/campaigns/${campaignId}/settings`
  )
}

export const CampaignContext = createContext({ campaignId: null })

export const getCampaignId = () => {
  const url = window.location.href
  const paths = url.split("/");
  let result = paths[3] ? paths[3] : null;
  if(result && result.includes('?')) {
    result = result.split("?")[0];
  }
  return result
}

const ClientProvider = ({ children }) => {
  const [campaignContext, setCampaignContext] = useState({ loading: true })
  const campaignId = getCampaignId()
  const basePath = campaignId ? generatePath(`/:campaignId`, { campaignId: campaignId }) : ''
  const campaignNavigate = (path, state) => {
    history.push(`${basePath}/${path}`, state)
  }


  useEffect(() => {
    setCampaignContext((ctx) => ({
      ...ctx,
      basePath: basePath,
      campaignNavigate: campaignNavigate,
      campaignId: campaignId,
    }))
  }, [])
  useEffect(() => {
    ;(async () => {
      const campaignSettings = await getCampaignSettings(clientId, campaignId)
      const dateNow = new Date(Date.now()).toISOString()
      setCampaignContext((ctx) => ({
        ...ctx,
        campaignEnded: clearedOffsetDate(campaignSettings?.endAt) < clearedOffsetDate(dateNow),
        campaignNotStarted: clearedOffsetDate(dateNow) < clearedOffsetDate(campaignSettings?.startAt),
        campaignSettings: campaignSettings || {},
      }))
    })()
  }, [campaignId])

  return (
    <CampaignContext.Provider value={campaignContext}>
      {children}
    </CampaignContext.Provider>
  )
}

export const useCampaign = () => {
  return useContext(CampaignContext)
}
