import React, { useEffect, useState } from "react"
import styled from "@emotion/native"
import { T, useTranslator } from "../translations/translate"
import { useCampaign } from "../GlobalContexts"
import { useProfile } from "../Auth/useProfile"
import { env } from "../configs/env"
import { clientId } from "../../clientId"

import { DefaultModal } from "@civitime/library/storybook/stories/Modals"
import { colors } from "@civitime/library/storybook/configs/colors"
import { UseMultiTextesBarSelection } from "@civitime/library/storybook/stories/SelectionBar"
import { MOBILE_HEADER_SIZE } from "@civitime/library/storybook/configs/sizes"
import { Dialog } from "@civitime/library/storybook/stories/Dialog"
import { PressedInCard } from "@civitime/library/storybook/stories/Cards"
import {
  ActionButton,
  TextButton,
} from "@civitime/library/storybook/stories/Button"
import { MediumText, Title1 } from "@civitime/library/storybook/stories/Texts"
import { JoinTeamAlert } from "./components/JoinTeamAlert"
import { CardTeam } from "./components/CardTeam"
import { TeamList } from "./components/TeamList"

export const ChooseYourTeam = ({ mobile, orientation, height, openHelper }) => {
  const { t } = useTranslator()
  const {
    emitCommand,
    token,
    technicalProfile: { email },
  } = useProfile()
  const { campaignNavigate, campaignId, campaignSettings } = useCampaign()
  const teamMax = campaignSettings?.teamMax
  const [alertNewTeam, setAlertNewTeam] = useState(false)
  const [alertJoinTeam, setAlertJoinTeam] = useState(false)
  const [joinTeamId, setJoinTeamId] = useState(null)
  const [teams, setTeams] = useState(null)
  const [allTeamsFull, setAllTeamsFull] = useState(null)
  const [nbOfTeams, setNbOfTeams] = useState(null)
  const [inviteExist, setInviteExist] = useState(null)

  useEffect(() => {
    if (teams && teams.length > 0) return
    fetch(
      env.GAME_SERVER_URI +
        `/choose-team/${clientId}/${campaignId}/${email}/${teamMax}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }
    ).then((r) =>
      r.json().then((value) => {
        const { teams, allTeamsFull, nbOfTeams, inviteExist } = value
        setTeams(teams)
        setAllTeamsFull(allTeamsFull)
        setNbOfTeams(nbOfTeams)
        setInviteExist(inviteExist)
      })
    )
  }, [clientId, campaignId, email, teamMax, teams])

  const joinTeam = (id) => {
    emitCommand({
      type: "JoinTeam",
      payload: {
        teamId: id,
      },
    })
      .then(
        (value) => value[0].type === "TeamJoined" && campaignNavigate("team")
      )
      .then(openHelper())
  }

  return !teams || inviteExist === null ? null : teams.length === 0 ||
    (allTeamsFull && !inviteExist) ? (
    <>{campaignNavigate("team/new", allTeamsFull && "allTeamsFull")}</>
  ) : (
    <>
      <DefaultModal
        backgroundColor={colors.lightBlue}
        color={colors.text}
        title={t("app.team.chooseYourTeam")}
        maxHeight={(height - MOBILE_HEADER_SIZE) * 0.99}
        withShadow
      >
        {inviteExist ? (
          <WrapperInvites>
            <InviteIntro>
              {allTeamsFull ? (
                <T path="app.team.inviteIntroTeamFull" />
              ) : (
                <T path="app.team.inviteIntro" />
              )}
            </InviteIntro>
            <TeamList
              {...{ teams }}
              onPress={(teamId) => {
                setAlertJoinTeam(true)
                setJoinTeamId(teamId)
              }}
            />
          </WrapperInvites>
        ) : (
          <WrapperTeamsToChoose {...{ mobile, orientation }}>
            {teams.map((team, index) => (
              <CardTeam
                key={index + "teamListCard"}
                {...{
                  team,
                  index,
                  mobile,
                  orientation,
                  teamMax,
                  setAlertJoinTeam,
                  setJoinTeamId,
                  createButton: nbOfTeams < 3
                }}
              />
            ))}
            {nbOfTeams < 3 && (
              <WrapperCreateNewTeam mobile={mobile}>
                <PressedInCard
                  noCursor
                  customPadding={mobile && orientation === "portrait" ? 10 : 20}
                >
                  <CreateNewTeamTitle>
                    <T path="app.team.createYourTeam" />
                  </CreateNewTeamTitle>
                  <TextButton onPress={() => setAlertNewTeam(true)}>
                    <T path="app.team.create" />
                  </TextButton>
                </PressedInCard>
              </WrapperCreateNewTeam>
            )}
          </WrapperTeamsToChoose>
        )}

        <WrapperShowMore>
          {inviteExist && allTeamsFull && (
            <ActionButton onPress={() => setAlertNewTeam(true)}>
              <T path="app.team.create" />
            </ActionButton>
          )}
          {(inviteExist || nbOfTeams >= 3) && (
            <UseMultiTextesBarSelection
              texts={
                inviteExist
                  ? allTeamsFull
                    ? [t("app.team.teamsList")]
                    : [t("app.team.createTeam"), t("app.team.teamsList")]
                  : nbOfTeams === 3
                  ? [t("app.team.createTeam")]
                  : [t("app.team.createTeam"), t("app.team.teamsList")]
              }
              onPressArray={
                inviteExist
                  ? allTeamsFull
                    ? [() => campaignNavigate("team/search")]
                    : [
                        () => setAlertNewTeam(true),
                        () => campaignNavigate("team/search"),
                      ]
                  : nbOfTeams === 3
                  ? [() => setAlertNewTeam(true)]
                  : [
                      () => setAlertNewTeam(true),
                      () => campaignNavigate("team/search"),
                    ]
              }
              withoutActive
            />
          )}
        </WrapperShowMore>
      </DefaultModal>
      <AlertNewTeam
        visible={alertNewTeam}
        onRequestClose={() => setAlertNewTeam(false)}
        navToNewTeam={() => campaignNavigate("team/new")}
      />
      <JoinTeamAlert
        visible={alertJoinTeam}
        onRequestClose={() => {
          setAlertJoinTeam(false)
          setJoinTeamId(null)
        }}
        navToTeam={() => joinTeam(joinTeamId)}
      />
    </>
  )
}

const AlertNewTeam = ({ onRequestClose, visible, navToNewTeam }) => {
  return (
    <Dialog {...{ onRequestClose, visible }}>
      <WarningTitle>
        <T path="app.global.warning" />
      </WarningTitle>
      <MediumText>
        <T path="app.team.warningNewTeam" />
      </MediumText>

      <ModalActionButtonWrapper>
        <ActionButton onPress={navToNewTeam}>
          <T path="app.global.next" />
        </ActionButton>
        <TextButton onPress={onRequestClose}>
          <T path="app.global.cancel" />
        </TextButton>
      </ModalActionButtonWrapper>
    </Dialog>
  )
}

const WarningTitle = styled(Title1)({
  marginBottom: 13,
  textTransform: "uppercase",
})

const ModalActionButtonWrapper = styled.View({
  marginTop: 30,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
})

const WrapperTeamsToChoose = styled.View(({ mobile, orientation }) => ({
  flexDirection: mobile && orientation === "portrait" ? "column" : "row",
  justifyContent: "space-around",
}))

const WrapperTeam = styled.View(
  {
    alignItems: "center",
  },
  ({ mobile, orientation }) => ({
    marginVertical: mobile && orientation === "portrait" ? 10 : 20,
    flexDirection: mobile && orientation === "portrait" ? "row" : "column",
    ...(mobile &&
      orientation === "portrait" && {
        justifyContent: "space-around",
      }),
  })
)

const WrapperCreateNewTeam = styled(WrapperTeam)(({mobile}) => ({
  width: 178.72,
  marginTop: mobile ? 30 : 70,
  margin: 'auto'
}))

const CreateNewTeamTitle = styled(Title1)({
  textTransform: "uppercase",
  marginVertical: 3,
})

const WrapperShowMore = styled.View({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 20,
})

const WrapperInvites = styled.View({
  flexDirection: "column",
  marginHorizontal: 55,
  marginBottom: 35,
})

const InviteIntro = styled(MediumText)({
  marginTop: 15,
  marginBottom: 20,
  fontSize: 18,
  lineHeight: 21,
})
