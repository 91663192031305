import React, {useEffect, useRef, useState} from 'react'
import styled from '@emotion/native'
import {useCampaign} from '../GlobalContexts'
import {useProfile} from '../Auth/useProfile'
import {authClient} from '../Auth/authClient'
import {useHovered} from '@civitime/library/storybook/hooks/useHovered'
import {colors} from '@civitime/library/storybook/configs/colors'
import {DefaultModal} from '@civitime/library/storybook/stories/Modals'
import {
  ActionButton,
  DefaultSwitch,
  TextButton,
} from '@civitime/library/storybook/stories/Button'
import {
  MediumText,
  SmallText,
  Title1,
} from '@civitime/library/storybook/stories/Texts'
import {Input, InputRow} from '@civitime/library/storybook/stories/Inputs'
import {
  InfoIcon,
  MailIcon,
  PasswordIcon, VisibilityOffIcon, VisibilityOnIcon,
}                              from '@civitime/library/storybook/stories/Icons'
import {PressedInCard}         from '@civitime/library/storybook/stories/Cards'
import {Dialog}                from '@civitime/library/storybook/stories/Dialog'
import {useScreenDimensions}   from '@civitime/library/storybook/hooks/useScreenDimensions'
import {formatErrorMessage}    from '../Auth/screens/Signup'
import {ErrorAlert, ErrorText} from '@civitime/library/storybook/stories/Error'
import {T, useTranslator} from '../translations/translate'

export const Settings = () => {
  const root = useRef(null)
  const {mobile, orientation} = useScreenDimensions(root)
  const {campaignNavigate, campaignEnded} = useCampaign()
  const [hovered, hoverProps] = useHovered()
  const {t} = useTranslator()
  const {technicalProfile, emitCommand, userState} = useProfile()
  const [email, setEmail] = useState(null)
  const [acceptEmail, setAcceptEmail] = useState(null)
  const [emailNotifModalOpened, setEmailNotifModalOpened] = useState(false)
  const [passwordEditModalOpened, setPasswordEditModalOpened] = useState(false)
  const [deleteAccountAlert, setDeleteAccountAlert] = useState(false)

  useEffect(() => {
    if (!technicalProfile) return
    setAcceptEmail(technicalProfile?.acceptEmails)
    setEmail(technicalProfile?.email)
  }, [technicalProfile])

  const changeAcceptEmail = async () => {
    try {
      await authClient.changeAcceptEmails(!acceptEmail).finally()
    } catch (e) {
      console.error(e)
    }
  }

  const logout = async () => await authClient.logout()

  const deleteAccount = async () => {
    await emitCommand({
      type: 'DeleteUserInfos',
      payload: {
        teamId: userState.teamId,
      },
    })
    await authClient.deleteAccount()
  }

  return (
    <>
      <Dialog
        visible={deleteAccountAlert}
        closable={true}
        onRequestClose={() => setDeleteAccountAlert(false)}
      >
        <ModalText>
          <T path={"app.auth.alerts.areYouSureDeleteAccount"}/>
        </ModalText>
        <WrapperDeleteAccountButtons mobile={mobile}>
          <SettingsButton
            onPress={() => setDeleteAccountAlert(false)}
            mobile={mobile}
            portrait={orientation === 'portrait'}
          >
            <T path={"app.auth.alerts.cancel"}/>
          </SettingsButton>
          <SettingsButton
            color={colors.watermelon}
            onPress={deleteAccount}
            mobile={mobile}
            portrait={orientation === 'portrait'}
          >
            <T path={"app.auth.alerts.deleteAccount"}/>
          </SettingsButton>
        </WrapperDeleteAccountButtons>
      </Dialog>
      <DefaultModal
        title={t('app.settings.title')}
        onPress={() => campaignNavigate('')}
      >
        <ModalChildrenWrapper ref={root} {...{mobile, orientation}}>
          <InfosWrapper {...{mobile, orientation}}>
            <InputRow>
              <Input
                icon={<MailIcon width={17} height={17} color={colors.text}/>}
                value={email}
                disabled
              />
            </InputRow>
            <ChangePasswordWrapper {...{mobile, orientation}}>
              <InputRow>
                <Input
                  secureTextEntry
                  disabled
                  icon={
                    <PasswordIcon width={17} height={17} color={colors.text}/>
                  }
                  value={'**************'}
                />
              </InputRow>
              <SettingsButton
                mobile={mobile}
                portrait={orientation === 'portrait'}
                onClick={() => setPasswordEditModalOpened(true)}
              >
                <T path="app.settings.edit"/>
              </SettingsButton>
            </ChangePasswordWrapper>
            <EmailNotifWrapper {...{mobile, orientation}}>
              <EmailNotifInfos {...{mobile, orientation}}>
                <MediumText>
                  <T path="app.settings.notifications.noEmail"/>
                </MediumText>
                <HelperIcon
                  {...hoverProps}
                  color={hovered ? colors.vividBlue : colors.linkWater}
                  width={20}
                  height={20}
                  onClick={() => setEmailNotifModalOpened(true)}
                />
                {hovered && <EmailsNotificationInfos/>}
              </EmailNotifInfos>
              {technicalProfile !== null &&
              email !== null &&
              acceptEmail !== null && (
                <DefaultSwitch
                  defaultValue={acceptEmail}
                  onPress={changeAcceptEmail}
                />
              )}
            </EmailNotifWrapper>
          </InfosWrapper>
          {campaignEnded && <RestartGameCard {...{mobile, orientation}} />}
          <ActionButtonWrapper>
            <SettingsButton
              onPress={logout}
              mobile={mobile}
              portrait={orientation === 'portrait'}
            >
              <T path="app.settings.logout"/>
            </SettingsButton>
            <SettingsButton
              color={colors.watermelon}
              onPress={() => setDeleteAccountAlert(true)}
              mobile={mobile}
              portrait={orientation === 'portrait'}
            >
              <T path="app.settings.deleteAccount"/>
            </SettingsButton>
          </ActionButtonWrapper>
        </ModalChildrenWrapper>
      </DefaultModal>
      <EmailsNotificationModal
        onRequestClose={() => setEmailNotifModalOpened(false)}
        visible={emailNotifModalOpened}
      />
      <PasswordEditModal
        onRequestClose={() => setPasswordEditModalOpened(false)}
        visible={passwordEditModalOpened}
      />
    </>
  )
}

const EmailsNotificationInfos = () => {
  return (
    <EmailsNotificationInfosWrapper>
      <SmallText color={colors.lightText}>
        <T path="app.settings.notifications.tooltip"/>
      </SmallText>
    </EmailsNotificationInfosWrapper>
  )
}

const EmailsNotificationModal = ({onRequestClose, visible}) => {
  return (
    <Dialog closable {...{onRequestClose, visible}}>
      <Title1>
        <T path="app.settings.notifications.title"/>
      </Title1>
      <ModalText>
        <T path="app.settings.notifications.description"/>
      </ModalText>
    </Dialog>
  )
}

const PasswordEditModal = ({onRequestClose, visible}) => {
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [newPasswordConfirm, setNewPasswordConfirm] = useState()
  const [securedTextEntry, setSecuredTextEntry] = useState({
    old: true,
    new: true,
    confirmed: true
  })
  const [error, setError] = useState(null)
  const {t} = useTranslator()
  const canSubmit =
    oldPassword &&
    newPassword &&
    newPasswordConfirm

  const updateAccount = async () => {
    try {
      if (newPassword !== newPasswordConfirm) return setError('app.settings.errors.mismatchPasswords')
      setError(false)
      const res = await authClient.updateAccount({
        oldPassword,
        password: newPassword,
      })
      if (res && res.message) {
        setError(res.message)
      }
    } catch (e) {
      const error = await formatErrorMessage(e)
      setError(error)
    }
  }

  return (
    <Dialog closable {...{onRequestClose, visible}}>
      <Title1 style={{marginBottom: 10}}>
        <T path="app.settings.editPassword"/>
      </Title1>
      {error && (
        <ErrorAlert success={error === "app.auth.updatedAccount"}>
          <ErrorText>
            {
              <T path={error}/>
            }
          </ErrorText>
        </ErrorAlert>
      )}
      <InputRow>
        <Input
          secureTextEntry={securedTextEntry.old}
          icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
          value={oldPassword}
          onChangeText={setOldPassword}
          placeholder={t('app.settings.oldPassword')}
          secondIcon={securedTextEntry ? <VisibilityOnIcon width={20} height={20} style={{cursor: 'pointer'}}
                                                           onClick={() => setSecuredTextEntry(v => ({
                                                             ...v,
                                                             old: !v.old
                                                           }))
                                                           }/> :
            <VisibilityOffIcon width={20} height={20} style={{cursor: 'pointer'}}
                               onClick={() => setSecuredTextEntry(v => ({
                                 ...v,
                                 old: !v.old
                               }))
                               }/>}
        />
      </InputRow>
      <InputRow>
        <Input
          secureTextEntry={securedTextEntry.new}
          icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
          value={newPassword}
          onChangeText={setNewPassword}
          placeholder={t('app.settings.newPassword')}
          secondIcon={securedTextEntry ? <VisibilityOnIcon width={20} height={20} style={{cursor: 'pointer'}}
                                                           onClick={() => setSecuredTextEntry(v => ({
                                                             ...v,
                                                             new: !v.new
                                                           }))
                                                           }/> :
            <VisibilityOffIcon width={20} height={20} style={{cursor: 'pointer'}}
                               onClick={() => setSecuredTextEntry(v => ({
                                 ...v,
                                 new: !v.new
                               }))
                               }/>}
        />
      </InputRow>
      <InputRow>
        <Input
          secureTextEntry={securedTextEntry.confirmed}
          icon={<PasswordIcon width={17} height={17} color={colors.text}/>}
          value={newPasswordConfirm}
          onChangeText={setNewPasswordConfirm}
          placeholder={t('app.settings.confirmedPassword')}
          secondIcon={securedTextEntry ? <VisibilityOnIcon width={20} height={20} style={{cursor: 'pointer'}}
                                                           onClick={() => setSecuredTextEntry(v => ({
                                                             ...v,
                                                             confirmed: !v.confirmed
                                                           }))
                                                           }/> :
            <VisibilityOffIcon width={20} height={20} style={{cursor: 'pointer'}}
                               onClick={() => setSecuredTextEntry(v => ({
                                 ...v,
                                 confirmed: !v.confirmed
                               }))
                               }/>}
        />
      </InputRow>

      <ModalActionButtonWrapper>
        <ActionButton
          disabled={!canSubmit}
          onPress={() => canSubmit && updateAccount()}
        >
          <T path="app.settings.edit"/>
        </ActionButton>
      </ModalActionButtonWrapper>
    </Dialog>
  )
}

const RestartGameCard = ({mobile, orientation}) => {
  const {emitCommand} = useProfile()
  const replay = async () => {
    await emitCommand({
      type: 'ReInitializeGameAccount',
      payload: {}
    })
    await emitCommand({
      type: 'SeeCampaignEndedModal',
      payload: {}
    })
    return window.location.reload()
  }

  return (
    <RestartGameWrapper
      noHover
      customPadding={mobile && orientation === 'portrait' ? 10 : 20}
      {...{mobile, orientation}}
    >
      <Title1>
        <T path="app.settings.restart"/>
      </Title1>
      <ModalActionButtonWrapper>
        <ActionButton onPress={replay}>
          <T path="app.settings.reset"/>
        </ActionButton>
      </ModalActionButtonWrapper>
    </RestartGameWrapper>
  )
}

const WrapperDeleteAccountButtons = styled.View(({mobile}) => ({
  flexDirection: mobile ? 'column' : 'row',
  justifyContent: 'center',
  paddingTop: 15
}))

const RestartGameWrapper = styled(PressedInCard)(
  {marginBottom: 35},
  ({mobile, orientation}) =>
    !(mobile && orientation === 'portrait') && {
      marginHorizontal: '12%',
    }
)

const ModalActionButtonWrapper = styled.View({
  marginTop: 20,
})

const ModalChildrenWrapper = styled.View(({mobile, orientation}) => ({
  padding: mobile && orientation === 'landscape' ? 10 : 20,
}))

const InfosWrapper = styled.View(
  {
    justifyContent: 'center',
  },
  ({mobile, orientation}) =>
    mobile
      ? orientation === 'portrait'
      ? {
        marginBottom: 30,
        alignItems: 'center',
      }
      : {
        marginBottom: 30,
        marginHorizontal: '10%',
        alignItems: 'flex-start',
      }
      : {
        marginBottom: 35,
        marginHorizontal: '15%',
        alignItems: 'flex-start',
      }
)

const BaseWrapper = styled.View({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})

const ActionButtonWrapper = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
})

const ChangePasswordWrapper = styled(BaseWrapper)(
  ({mobile, orientation}) => ({
    justifyContent:
      mobile && orientation === 'portrait' ? 'center' : 'space-between',
  })
)

const EmailNotifWrapper = styled(BaseWrapper)(
  {
    marginTop: 30,
    position: 'relative',
  },
  ({mobile, orientation}) =>
    mobile && orientation === 'portrait'
      ? {
        justifyContent: 'center',
      }
      : {
        justifyContent: 'space-between',
        paddingRight: 23,
      }
)

const EmailNotifInfos = styled.View(
  {
    flexDirection: 'row',
    zIndex: 10,
  },
  ({mobile, orientation}) =>
    mobile &&
    orientation === 'portrait' && {
      width: '100%',
      justifyContent: 'center',
      marginBottom: 10,
    }
)

const EmailsNotificationInfosWrapper = styled.View({
  backgroundColor: colors.tooltip,
  borderRadius: 2,
  padding: 7,
  position: 'absolute',
  left: '98%',
  right: '-75%',
  top: '-195%',
  zIndex: 10,
})

const HelperIcon = styled(InfoIcon)({
  marginLeft: 10,
  zIndex: 10,
  cursor: 'pointer',
})

const ModalText = styled(MediumText)({
  marginTop: 15,
})

const SettingsButton = styled(TextButton)(({mobile, portrait}) => ({
  width: mobile && portrait && '100%',
}))
