import React, { useEffect, useRef, useState } from 'react'
import { useProfile }                         from '../../Auth/useProfile'

import { RankingWrapper }        from './Containers'
import { PlayerRankingListItem } from './PlayerRankingListItem'

export const PlayerRankingList = ({
  data,
  mobile,
  orientation,
  pressTeam,
}) => {
  const scrollRef = useRef(null)
  const { publicProfile, userId } = useProfile()
  const [listHeight, setListHeight] = useState(0)

  const myIndex = data.findIndex((v) => userId === v.playerId)

  const scrollToRow = (itemIndex) => {
    if (!scrollRef?.current) return
    scrollRef.current.scrollTo({ y: (itemIndex * 50), animated: true })
  }

  useEffect(() => {
    if (!scrollRef?.current || !myIndex) return
    scrollToRow(myIndex)
  }, [scrollRef?.current, myIndex, listHeight])

  return (
    <RankingWrapper
      ref={scrollRef}
      onLayout={(event) => setListHeight(event?.nativeEvent?.layout?.height)}
      contentContainerStyle={{ alignItems: "center" }}
      {...{ mobile, orientation }}
    >
      {data &&
        data.map((player, index) => {
          return (
            <PlayerRankingListItem
              key={index + "playerKey"}
              isMe={index === myIndex}
              {...{ index, player, mobile, pressTeam }}
            />
          )
        })}
    </RankingWrapper>
  )
}
