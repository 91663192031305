import React from "react"
import styled from "@emotion/native"

import { colors } from "@civitime/library/storybook/configs/colors"
import { Title2 } from "@civitime/library/storybook/stories/Texts"
import { Points } from "@civitime/library/storybook/stories/Leaderboard"

export const TopRankingWrapper = styled.View(({ orientation, mobile }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  marginTop: mobile && orientation === "portrait" ? 0 : 25,
}))

export const Line = styled.View(({ mobile, orientation }) => ({
  backgroundColor: colors.darkerBackground,
  borderRadius: 0,
  height: 8,
  width: mobile && orientation === "portrait" ? "95%" : "70%",
  margin: "auto",
  marginTop: 10,
  marginBottom: 10,
}))

export const RankingWrapper = styled.ScrollView(
  ({mobile, orientation }) => ({
    alignSelf: "center",
    width: mobile && orientation === "portrait" ? "95%" : "80%",
  })
)

export const RankingLineWrapper = styled.View(({ me, mobile }) => ({
  justifyContent: "space-around",
  flexDirection: "row",
  alignItems: "center",
  height: 50,
  width: mobile ? "99%" : "80%",
  ...(me && {
    backgroundColor: colors.darkerBackground,
    boxShadow: "-1px -1px 4px #FFFFFF, 1px 1px 3px rgba(94, 104, 121, 0.945)",
  }),
}))

export const WrapperRank = styled(Title2)(({ mobile }) => ({
  flex: mobile ? 0.1 : 0.25,
}))

export const Name = styled(Title2)({
  marginLeft: 5,
  textTransform: "uppercase",
})

export const WrapperPoints = styled(Points)(({ mobile }) => ({
  flex: mobile ? 0.2 : 0.25,
}))

export const TeamNameWrapper = styled.View(({ mobile }) => ({
  flex: mobile ? 0.5 : 0.25,
  flexDirection: "row",
  alignItems: "center",
  width: mobile ? 100 : 180,
}))
