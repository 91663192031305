import './Utils/customPolyfills'
import 'core-js'
import 'regenerator-runtime'
import 'whatwg-fetch'
import React, { useEffect, useRef }                            from 'react'
import { BackHandler, Platform }                               from 'react-native'
import { AppLoading, Linking, registerRootComponent }          from 'expo'
import { lockPlatformAsync, unlockAsync, WebOrientationLock, } from 'expo-screen-orientation'
import {
  Raleway_100Thin,
  Raleway_100Thin_Italic,
  Raleway_200ExtraLight,
  Raleway_200ExtraLight_Italic,
  Raleway_300Light,
  Raleway_300Light_Italic,
  Raleway_400Regular,
  Raleway_400Regular_Italic,
  Raleway_500Medium,
  Raleway_500Medium_Italic,
  Raleway_600SemiBold,
  Raleway_600SemiBold_Italic,
  Raleway_700Bold,
  Raleway_700Bold_Italic,
  Raleway_800ExtraBold,
  Raleway_800ExtraBold_Italic,
  Raleway_900Black,
  Raleway_900Black_Italic,
  useFonts,
}                                                              from '@expo-google-fonts/raleway'
import { LuckiestGuy_400Regular, useFonts as useLuckiestfonts } from '@expo-google-fonts/luckiest-guy'

import { history }                     from './Router/history'
import { GlobalContexts }              from './GlobalContexts'
import { AuthFilter }                  from './Auth/AuthFilter'
import { AppRouter }                   from './MainComponents/AppRouter'
import styled                          from '@emotion/native'
import { useScreenDimensions }         from '@civitime/library/storybook/hooks/useScreenDimensions'
import { TranslationCampaignProvider } from './translations/translate'

const App = () => {
  const [fontsLoaded] = useFonts({
    Raleway_100Thin,
    Raleway_100Thin_Italic,
    Raleway_200ExtraLight,
    Raleway_200ExtraLight_Italic,
    Raleway_300Light,
    Raleway_300Light_Italic,
    Raleway_400Regular,
    Raleway_400Regular_Italic,
    Raleway_500Medium,
    Raleway_500Medium_Italic,
    Raleway_600SemiBold,
    Raleway_600SemiBold_Italic,
    Raleway_700Bold,
    Raleway_700Bold_Italic,
    Raleway_800ExtraBold,
    Raleway_800ExtraBold_Italic,
    Raleway_900Black,
    Raleway_900Black_Italic
  })
  const [luckiestFontsLoaded] = useLuckiestfonts({ LuckiestGuy_400Regular });


  const root = useRef(null)
  const { width, height, mobile } = useScreenDimensions(root)

  useEffect(() => {
    if (Platform.OS === 'web') {
      lockPlatformAsync({
        screenOrientationLockWeb: WebOrientationLock.LANDSCAPE,
      }).catch((e) => console.warn('Unable to lock screen orientation', e))

      return
    } else {
      unlockAsync()
    }
    // TODO : prepared for universal deeplinks, but native configuration is missing.
    const listenToUrlChanges = ({ url }) => {
      const path = Linking.parse(url).path
      if (!path) return
      history.push(path)
    }
    Linking.getInitialURL().then((url) => {
      listenToUrlChanges({ url })
    })
    Linking.addEventListener('url', listenToUrlChanges)

    const handleBackButton = () => {
      history.goBack()
      return true
    }
    BackHandler.addEventListener('hardwareBackPress', handleBackButton)

    return () => {
      Linking.removeEventListener('url', listenToUrlChanges)
      BackHandler.removeEventListener('hardwareBackPress', handleBackButton)
    }
  }, [])
  if (!fontsLoaded || !luckiestFontsLoaded) {
    return <AppLoading ref={root}/>
  } else {
    return <MainAppWrapper ref={root} screenHeight={height} screenWidth={width}>
      <GlobalContexts>
        <TranslationCampaignProvider>
        <AuthFilter screenHeight={height} screenWidth={width} mobile={mobile}>
            <AppRouter/>
        </AuthFilter>
        </TranslationCampaignProvider>
      </GlobalContexts>
    </MainAppWrapper>
  }
}

const MainAppWrapper = styled.View(({ screenWidth, screenHeight }) => ({
  width: screenWidth,
  height: screenHeight,
  flex: 1,
}))

registerRootComponent(App)
