import React from 'react'
import styled from "@emotion/native"
import {Dialog} from "@civitime/library/storybook/stories/Dialog/index.web"
import {T} from "../../../translations/translate"
import {MediumText} from "@civitime/library/storybook/stories/Texts"
import {ActionButton, TextButton} from "@civitime/library/storybook/stories/Button"
import {WarningTitle} from "../../../Teams/components/JoinTeamAlert"
import {CloseButtonDefaultModal} from "@civitime/library/storybook/stories/Modals"
import {colors} from "@civitime/library/storybook/configs/colors"

export const CampaignEndedModal = ({onClose, visible, replay, navRanking, mobile}) => {

  return  <Dialog {...{ onClose, visible}}>
    <CloseButtonCampaignEndedModal
      color={colors.secondary}
      iconColor="white"
      iconSize={15}
      size={30}
      noShadow
      onPress={onClose}
    />
    <CampaignEndedTitle>
      <T path="phase_1.campaignEnded.modalEnded.title" />
    </CampaignEndedTitle>
    <MediumText>
      <T path="phase_1.campaignEnded.modalEnded.text" />
    </MediumText>
    <CampaignEndedButtonsWrapper mobile={mobile}>
      <ActionButton onPress={replay}>
        <T path="phase_1.campaignEnded.modalEnded.replay" />
      </ActionButton>
      <TextButton onPress={navRanking}>
        <T path="phase_1.campaignEnded.modalEnded.rankingButton" />
      </TextButton>
    </CampaignEndedButtonsWrapper>
  </Dialog>
}

const CampaignEndedTitle = styled(WarningTitle)({
  textTransform: 'none'
})

const CampaignEndedButtonsWrapper = styled.View(({mobile}) => ({
  flexDirection: mobile ? 'column' : 'row',
  justifyContent: 'center',
  marginTop: 15
}))


const CloseButtonCampaignEndedModal = styled(CloseButtonDefaultModal)({
  top: 10,
  right: 10
})
